import React, { Component, Fragment } from 'react';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import GlobalImagingBanner from '../components/GlobalImaging/GlobalImagingBanner';
import GlobalImagingSec1 from '../components/GlobalImaging/GlobalImagingSec1';
import Notification from '../components/TopNotification/TopNotification';


class GlobalImagingPage extends Component {
    render() {
        return (
            <Fragment>
                <Notification message="Try out our " duration={800000} subscription="Free payroll for one month & a free tablet!" />
                <TopNavigation title="Global Imaging | V-TAC Payroll Management" />
                <GlobalImagingBanner />
                <GlobalImagingSec1 />
                <TradePart />
                <Footer />
            </Fragment>
        );
    }
}

export default GlobalImagingPage;