import React from 'react';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import IndustryExpertise from '../components/Banner/IndustryExpertise';
import AnualSummary from '../components/AnualSummary/AnualSummary';

// import banner from '../../src/asset/images/solution/uni-hr.png';
import banner from '../../src/asset/images/industry/Lawyer.png';
import serve1 from '../../src/asset/images/industry/serve1.png';
import SliderComp from '../components/Projects/Slider';
import OfferQuote from '../components/OfferQuote/OfferQuote';
import Notification from '../components/TopNotification/TopNotification';
import Reception from '../components/IndustryServe/Reception';
import CommonFaq from '../components/FAQ/CommonFaq';
import ContractCommitment2 from '../components/IndustryServe/ContractCommitment2';
import IndustryServe2 from '../components/IndustryServe/IndustryServe2';
import reception from '../../src/asset/images/industry/Industry_004.webp';
import lawBook from '../../src/asset/images/industry/Industry_001.webp';

import { MdOutlineSecurity } from "react-icons/md";
import { FaHandsHelping } from "react-icons/fa";
import { GoLaw } from "react-icons/go";
import { TbMessageDots } from "react-icons/tb";
import { MdAutoMode } from "react-icons/md";

const LawFirmsPage = () => {
    const imageData = {
        imageUrl: banner,
        // caption: 'Industry Expertise / Law Firm',
        title: 'Experience seamless payroll solutions designed for legal experts',
        paragraph: 'With VPM, you get hassle-free, automated payroll processing that lets you focus more on your clients and less on paperwork.'

    };
    const DataInfo = {
        imageUrl: serve1,
        caption: '100% Canadian',
        title: 'We work with the law-firms industry',

        icon1: <GoLaw size={35} color='#FF135A' />,
        subTitle1: 'Customized Payroll Expertise for Legal Practices',
        p1: "VPM specializes in accommodating the diverse payroll needs of law firms. From handling complex partner payment structures to managing support staff salaries, we offer tailor-made solutions that align perfectly with your firm's unique requirements. Our system is designed for quick setup and easy operation, allowing you to process payroll effortlessly.",

        icon2: <FaHandsHelping size={35} color='#FF135A' />,
        subTitle2: 'Streamlined Payments for Partners, Contractors, and Staff',
        p2: "VPM adeptly manages the intricate payment structures unique to law firms, from equity partner draws and profit distributions to regular salaries for partners, associates, and staff. This includes navigating the complexities of contractor payments and integrating bonus payments seamlessly into your payroll cycle. Our approach ensures accuracy, timeliness, and alignment with your firm's specific needs, simplifying your payroll process from top to bottom.",

        icon3: <MdOutlineSecurity size={35} color='#FF135A' />,
        subTitle3: 'Uncompromised Data Security and Confidentiality',
        p3: "Confidentiality is paramount in legal practices, and the same applies to your payroll. VPM employs advanced security measures to ensure your payroll information remains protected, giving you peace of mind.",

        icon4: <MdAutoMode size={35} color='#FF135A' />,
        subTitle4: 'Automate and simplify your payroll workflow',
        p4: "At VPM, we understand the value of your time. Our automated and recurring payroll setups ensure a smooth, uninterrupted flow, liberating you from the routine tasks of payroll management and allowing you to focus more on your legal practice. Set up your payroll once, and let it run smoothly in the background with minimal need for intervention, perfect for the busy schedules of legal practices.",

        icon5: <TbMessageDots size={35} color='#FF135A' />,
        subTitle5: "Support at Your Fingertips",
        p5: "Our dedicated support team is always a call or click away, ready to assist with any payroll queries. Our support model is designed with busy legal professionals in mind, ensuring you have rapid, easy access to assistance whenever you need it.",

        picture: lawBook
    };

    const Receptiondata = {
        heading: "Begin Your Journey with VPM",
        text: "Starting with VPM is a breeze. Our team will guide you every step of the way, ensuring a seamless integration of our payroll services into your hospitality business.",
        picture: reception,
    };

    return (
        <>
            <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
            <TopNavigation title="Law Firms | V-TAC Payroll Management" />
            <IndustryExpertise data={imageData} />
            <AnualSummary marginTop="-3rem" />
            <IndustryServe2 data={DataInfo} />
            <ContractCommitment2 />
            <SliderComp />
            <OfferQuote />
            <CommonFaq />
            <Reception data={Receptiondata} />
            <TradePart />
            <Footer />
        </>
    );
};

export default LawFirmsPage;