import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class GlobalImagingBanner extends Component {
    render() {
        return (
            <Fragment>
            <Container fluid={true} className="nexasStaffingBanner  p-0">
                <Row>
                    <Col sm={12} md={12} lg={12}>
                        <div>
                            <h2 className='nexastopTitle text-center mt-5'>
                            GLOBAL IMAGING STAYS UP-TO-DATE<br></br> WITH LEGISLATION<br></br> WITH <span className='VPMredTitle'>VPM</span>
                            </h2>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Fragment>
        );
    }
}

export default GlobalImagingBanner;