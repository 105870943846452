import React from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MdChevronRight } from "react-icons/md";

const Reception = (props) => {
    const { heading, text, picture } = props.data;
    return (
        <>
            <Container fluid style={{ backgroundColor: "#FFF5F1" }}>
                <Container>
                    <Row>
                        <Col lg={7} md={6} xs={12}>
                            <h2 className='common-title section-gap'>{heading}</h2>
                            <p className='common-p8 mt-4'>{text}</p>
                            <Link to="/request-payroll-quote"><Button className='any-device-btn mt-5 mb-3' style={{ backgroundColor: "#779EFF", color: "#FFF" }}><span>Transform my payroll</span> <MdChevronRight /></Button>{' '}</Link>
                        </Col>
                        <Col lg={5} md={6} xs={12} className='text-center' >
                            <img
                                src={picture}
                                alt="custom report"
                                className="report-img img-fluid section-gap"
                                // style={{ display: "inline-block", maxWidth: "100%", height: "auto" }}
                            />
                        </Col>
                        <div style={{ marginBottom: "6rem" }}></div>
                    </Row>
                </Container>
            </Container>


        </>
    );
};

export default Reception;