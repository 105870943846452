import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Form, Button, Card } from 'react-bootstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faFacebook, faLinkedin,faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import join3 from '../../asset/images/contact/Ellipse 840.svg';
import { BiSolidPhoneCall } from 'react-icons/bi';
import { AiOutlineMail } from 'react-icons/ai';
import { FaLocationDot } from 'react-icons/fa6';
import { FaYoutube, FaEnvelope } from "react-icons/fa";
import { BiLogoLinkedin, BiLogoFacebook } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";

class ContactSection extends Component {
    constructor() {
        super();
        this.state = {
            fullName: "",
            company: "",
            phone: "",
            email: "",
            message: "",
            errors: {},
        }
    }
    // top page load
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    clearForm = () => {
        this.setState({
            fullName: "",
            company: "",
            phone: "",
            email: "",
            message: "",
        })
    }
    //success sms shows
    Successnotify = () => {
        toast.success('Message has been sent Successfully!', {
            position: "bottom-right",
            autoClose: 5000,
            theme: "colored",
        })
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }
    submitHandaler = (event) => {
        event.preventDefault();
        axios.post("https://api.vtacpayroll.ca/contact", this.state).then(Response => {
            // axios.post("http://localhost:5000/contact", this.state).then(Response => {

        }).catch(error => {
            console.log(error);
        })
        this.clearForm();
        this.Successnotify();
    }
    render() {
        const { fullName, company, email, phone, message } = this.state;
        return (
            <Fragment>
                <Container className='SectionBetween '>
                    <Row>
                        <Col lg={5} md={6} sm={12} className='contact-sec' >
                            <div style={{ marginTop: "7rem", marginLeft: "1rem" }}>
                                <img src={join3} className='join-img ' alt="quote banner" title='banner' fluid
                                />
                                <h3 className="common-title m-0" style={{ color: "#fff", fontSize: "26px" }}>Contact us</h3>
                                <p className='common-p4 ' style={{ color: "#C9C9C9" }}>feel free to contact us feel free to contact us</p>
                            </div>
                            <div className='m-4'>
                                <p className='common-p4'>
                                    <a href="tel:+1-866-315-8822" >
                                        <span style={{ color: "#FFF" }}><BiSolidPhoneCall size={20} /></span>
                                        <span style={{ color: "#FFF" }}> 1-866-315-8822 (Free call)</span>
                                    </a>
                                </p>

                                {/* <p className='common-p4' style={{ color: "#FFF" }}><span><BiSolidPhoneCall /></span> 1-866-315-8822 Free call</p> */}
                                <p className='common-p4' style={{ color: "#FFF" }}><span><AiOutlineMail /></span> info@vtacpayroll.ca</p>
                                <p className='common-p4' style={{ color: "#FFF" }}><span><FaLocationDot /></span> 401 Bay Street, Suite 1600,Toronto, ON M5H 2Y4</p>
                            </div>
                            <Row>
                                <Col lg={6} md={6} sm={6} xs={6} className="mb-5 ml-4">

                                    <div className="footerLinkDiv mt-3">
                                        <a href="https://www.facebook.com/vtacpayroll/" target='_blank' className='' > <BiLogoFacebook className="social-icon2" size={35}  /></a>
                                        <a href="https://twitter.com/vtacpayroll" target='_blank' ><FaXTwitter className="social-icon2" size={35} /></a>
                                        <a href="https://www.linkedin.com/company/v-tac-payroll-management/" target='_blank' className=""><BiLogoLinkedin className="social-icon2" size={35} /></a>
                                        <a href="https://www.youtube.com/@v-tacpayrollmanagement3092/videos" target='_blank' className='' ><FaYoutube className="social-icon2" size={35} /></a>

                                    </div>
                                </Col>
                            </Row>

                        </Col>
                        <Col lg={7} md={6} sm={12} style={{ backgroundColor: "#252E44" }}>
                            <div className=" contactCard">
                                <h2 className="common-title m-0" style={{ color: "#fff", fontSize: "28px" }}>Email Form</h2><br></br>
                                <p className='common-p4' style={{ color: "#DEDEDE" }}>For regular support please contact us through your dedicated payroll point-of-contact consultant by phone or email so we can get back to you with an answer as quickly as possible.</p>

                                <Form onSubmit={this.submitHandaler} >
                                    <Row className="mb-3">
                                        {/* <Form.Group as={Col} className='border-bottom border-secondary'>
                                            <Form.Label >Full name<span style={{ color: "red" }}>*</span></Form.Label>
                                            <Form.Control className=' border-bottom border-secondary custom-form-control' type="text" title='First Name' name='fstName' value={fstName} onChange={this.handleChange} required />
                                        </Form.Group> */}
                                        <Form.Group as={Col} >
                                            <Form.Label >Full name<span style={{ color: "red" }}>*</span></Form.Label>
                                            <Form.Control type="text" title='First Name' name='fullName' value={fullName} onChange={this.handleChange} required />
                                        </Form.Group>
                                        <Form.Group as={Col} >
                                            <Form.Label >Company name<span style={{ color: "red" }}>*</span></Form.Label>
                                            <Form.Control type="text" title='Company' name='company' value={company} onChange={this.handleChange} required />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} >
                                            <Form.Label >Email<span style={{ color: "red" }}>*</span></Form.Label>
                                            <Form.Control type="email" title='Email Address' name='email' value={email} onChange={this.handleChange} required />
                                        </Form.Group>
                                        <Form.Group as={Col} >
                                            <Form.Label>Phone Number<span style={{ color: "red" }}>*</span></Form.Label>
                                            <Form.Control type="number" title='Phone' name='phone' value={phone} onChange={this.handleChange} required />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} >
                                            <Form.Label>Message<span style={{ color: "red" }}>*</span></Form.Label>
                                            <Form.Control className='form-textarea' as="textarea" rows={3} name='message' value={message} onChange={this.handleChange} required />
                                        </Form.Group>
                                    </Row>
                                    <Button name='submitBtn' className="RequestQuoteBtn" variant="primary" type="submit">
                                        <span>SEND</span>
                                    </Button>
                                </Form>
                                <div>
                                    <ToastContainer
                                        toastStyle={{ backgroundColor: "green", color: "white" }}
                                    />
                                </div>

                            </div>

                        </Col>

                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default ContactSection;