import React from 'react';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import IndustryExpertise from '../components/Banner/IndustryExpertise';
import AnualSummary from '../components/AnualSummary/AnualSummary';
import IndustryServe from '../components/IndustryServe/IndustryServe';

// import banner from '../../src/asset/images/solution/uni-hr.png';
import banner from '../../src/asset/images/industry/employment-banner.png';
import SliderComp from '../components/Projects/Slider';
import OfferQuote from '../components/OfferQuote/OfferQuote';
import Notification from '../components/TopNotification/TopNotification';

import { TbMessageStar } from "react-icons/tb";
import { TbMessageDots } from "react-icons/tb";
import { MdOutlineRateReview } from "react-icons/md";
import { IoMdContacts } from "react-icons/io";
import { MdPayment } from "react-icons/md";
import { RiContactsBook2Line } from "react-icons/ri";
import ContractCommitment2 from '../components/IndustryServe/ContractCommitment2';
import CommonFaq from '../components/FAQ/CommonFaq';
import Reception from '../components/IndustryServe/Reception';
import reception from '../../src/asset/images/industry/Industry_006.webp';

const EmploymentAgenciesPage = () => {
    const imageData = {
        imageUrl: banner,
        // caption: 'Industry Expertise / Employment Agency',
        title: 'Streamline operations and unlock new levels of efficiency.',
        paragraph: 'Efficient payroll solutions, exceptional service, uniquely tailored for staffing agencies.'

    };
    const DataInfo = {
        caption: '100% Canadian',
        title: 'We work with the Employment industry',

        icon1: <TbMessageStar size={35} color='#FF135A' />,
        subTitle1: 'Quick and Simple Payroll Processing',
        p1: "Efficiently manage payroll for all staff types, with varied pay rates, with minimal effort.",

        icon2: <TbMessageDots size={35} color='#FF135A' />,
        subTitle2: 'Support When You Need It',
        p2: "Easy access to our support team for any payroll queries.",

        icon3: <MdOutlineRateReview size={35} color='#FF135A' />,
        subTitle3: 'Flexible Pay Rates',
        p3: "Seamlessly manage different pay rates within your practice, ensuring each role from hygienist to admin staff is compensated accurately.",

        icon4: <MdPayment size={35} color='#FF135A' />,
        subTitle4: 'Efficient Irregular Worker Payment',
        p4: "Pay your on-call staff and contractors, including associate dentists, accurately and on time.",

        icon5: <IoMdContacts size={35} color='#FF135A' />,
        subTitle5: "Employee Self-Service Portal",
        p5: "Your team can easily access their pay stubs and T4s, ensuring transparency and ease of access.",

        icon6: <RiContactsBook2Line size={35} color='#FF135A' />,
        subTitle6: "Contractor Payment Management",
        p6: "Simplify and streamline payments, especially for contracted dentists, ensuring compliance and accuracy.",
    };

    const Receptiondata = {
        heading: "Embark on a smoother payroll path with VPM.",
        text: "Partner with VPM for a more efficient payroll experience. Our team is dedicated to a smooth onboarding process, ensuring a seamless integration of our expert payroll services into your construction business.",
        picture: reception,
    };

    return (
        <>
            <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
            <TopNavigation title="Employment Agencies | V-TAC Payroll Management" />
            <IndustryExpertise data={imageData} />
            <AnualSummary marginTop="-.1rem" />
            <IndustryServe data={DataInfo} />
            <ContractCommitment2 />
            <SliderComp />
            <OfferQuote />
            <CommonFaq />
            <Reception data={Receptiondata} />
            <TradePart />
            <Footer />
        </>
    );
};

export default EmploymentAgenciesPage;