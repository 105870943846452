import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import payrollcalogo from '../../asset/images/NPI-Logo-Black.png';
import tbotlogo from '../../asset/images/tbot-logo.png';
import upcity from '../../asset/images/upcity.png';

const TradePart = () => {
  return (
    <>
      <Container className='mt-4'>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <div className="text-center" data-aos="fade-down" data-aos-duration="1000"
              data-aos-once="true">
              <a href='https://payroll.ca/' target='_blank'>
                <img className=" tradeImg" src={payrollcalogo} alt="CPA-logo" title='National Payroll Institute' loading="lazy" />
              </a>
              <a href="https://upcity.com/profiles/vtac-payroll/toronto" target='_blank'>
                <img src={upcity} className=" tradeImg" style={{width:"160px"}} alt="TOP PAYROLL PROVIDER" />
              </a>

              <a href='https://bot.com' target='_blank'>
                <img className="tradeImg" src={tbotlogo} alt='Toronto Region Board of Trade' title='Toronto Region Board of Trade' loading="lazy" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TradePart;