import React, { useRef } from 'react'
import { Row, Col } from 'react-bootstrap';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Project from './Project';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import styled from 'styled-components';

let data = [
  {

    disc: "Dealing with V-Tac over the past 10 years the customer support that we have received has been fantastic. They work hard to offer the best performance payroll service and insure that all our needs are met without any hesitation. In the end, it comes down to knowing the business and what they need to help us to be successful and achieve our goals",
    clientName: "Mike DiMaso (CEO)",
    clientCompany: "Solution Contracting Ltd"

  },
  {

    disc: "We've used VPM for almost 3 years and are extremely happy with the service, cost and responsiveness of the team.  We will continue to support VPM for the foreseeable future and encourage others to check them out.    ",
    clientName: "George Adamidis(President)",
    clientCompany: "ROSEDALE DIGITAL INC."
  },

  {

    disc: "VPM has been serving as our Payroll Management Team over the last two years. To date, they have been efficient, professional, and productive in their service to us here at Eclipse Security Services Inc. The team is reliable, resourceful and, very accommodating.",
    clientName: "Sonia Vargas(CEO)",
    clientCompany: "Eclipse Security Services Inc."
  },

  {

    disc: "VPM has been our payroll provider for many years.  They are prompt and always keep us informed of anything we need to know especially with this pandemic.  I would highly recommend them!",
    clientName: "Kim Kendall (VP)",
    clientCompany: "REW EARTHWORKS INC"
  },

  {

    disc: "Lost Craft has had a great experience. Great company. Excellent customer service. Highly recommended, Nabil is a great rep.",
    clientName: "Shehan De Silva (Founder)",
    clientCompany: "Lost Craft"
  },

  {

    disc: "Their team is polite, processing changes is quick and easy. They check in frequently and assist with respectand courteous.",
    clientName: "Melissa Palleschi(Real Estate Agent/Salesperson)",
    clientCompany: "Royal LePage Signature"
  },
  {

    disc: "Quality Driving & Motorcycle School is extremely happy with VTAC Payroll Management and their high level of service they provide. Nabil is always helpful and available to answer any questions.  We appreciate their live support and payroll reminders.",
    clientName: "John Smith",
    clientCompany: "Quality Living"
  },

  {
    disc: "VTAC has easy processes and helpful staff. Their reporting is complete and pricing very reasonable for our small organization. And they send submission reminders!! ",
    clientName: "Audrey Arisman (Executive Director)",
    clientCompany: "Western Canada Water"
  },
  {

    disc: "VTAC has simplified one of the more frustrating parts of runnning a business. Easy to work with and great rates",
    clientName: "Evan Zaretsky (Pediatric Dentist)",
    clientCompany: "Durham Kids Dentistry"
  },
  {

    disc: "Excellent company. VPM cares about their clients and provides industry leading service. I recommend VPM.",
    clientName: "Asif Khan",
    clientCompany: "Agent at Asif Khan Insurance Agency Inc."
  },
  {

    disc: "Excellent customer service, my direct deposit is always on time. Their online system is very user friendly. Fantastic service!!",
    clientName: "Jordon Hansen ",
    clientCompany: "Project Manager-Suncor"
  },
  {

    disc: "Flawless and efficient service so far with VPM. Our payroll for 6 people is flowing smooth.",
    clientName: "Andrew Yegorovykh",
    clientCompany: "Dentist-Pure North Dental"
  },
  {

    disc: "Great working with VTAC. They are very fast to answer request. Thanks",
    clientName: "Funmi Foluke Ola",
    clientCompany: "Director at Toks Christian Daycare"
  },
  {

    disc: "Very simple and easy to set up with pleasant customer service.  It is more cost efficient to use VPM rather than the time required to do your own payroll!  I would recommend.",
    clientName: "Lucy Chiappetta",
    clientCompany: "Law Clerk ay Kappor Barristers"
  },
  {

    disc: "quick response time.always co operative staff.",
    clientName: "Sarigat M Oves",
    clientCompany: "Treasurer at Cambridge Muslim Society"
  },
  {

    disc: "VTAC is very informative, friendly, quick and efficient. I really appreciate the bi-weekly reminders. ",
    clientName: "Glenda Gibbons-Reid",
    clientCompany: "Business Operation Manager at Dentistry at Arnprior"
  },
  {
    disc: "Vtac payroll has helped my company navigate through these tough times regarding the CERB benefits and programs.    ",
    clientName: "Enver Coovadia(Owner)",
    clientCompany: "K.A CAR CARE"
  },

];

var settings = {
  className: "center",
  centerMode: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: false,
  responsive: [
    {
      breakpoint: 990,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        centerMode: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        centerMode: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false
      }
    }
  ]
};
const SliderComp = () => {
  const arrowRef = useRef(null);
  let sliderProject = "";
  sliderProject = data.map((item, i) => (
    <Project item={item} key={i} />
  ))
  return (
    <Container className='mb-5'>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div data-aos="fade-up" data-aos-duration="800" data-aos-once="true">
            <h2 className='common-title text-center section-gap'>Client voices: Our impact in the industry</h2>
            <p className='common-p text-center' style={{ color: "#757575" }}>Our Clients Speak Out: Captivating Stories, Inspiring Successes, and the Unveiling of Their Unvarnished Satisfaction</p>
          </div>
        </Col>
      </Row>
      <Slider ref={arrowRef} {...settings}>
        {sliderProject}
      </Slider>
      <Buttons>
        <button
          onClick={() => arrowRef.current.slickPrev()}
          className='back'><IoIosArrowBack /></button>
        <button
          onClick={() => arrowRef.current.slickNext()}
          className='next'><IoIosArrowForward /></button>
      </Buttons>
    </Container>
  )
}

export default SliderComp;

const Container = styled.div`
  position: relative;
`

const Buttons = styled.div`
  button{
    width: 2rem;
    height: 2rem;
    background-color: rgb(0,0,0,.5);
    cursor: pointer;
    color: #fff;
    border: none;
    position: absolute;
    top: 60%;
    right: 1rem;
  }

  .back{
    left: 1rem;
  }
`