import React, { Fragment } from 'react';
import TopNavigation from '../../components/TopNavigation/TopNavigation';
import TradePart from '../../components/TradePart/TradePart';
import Footer from '../../components/Footer/Footer';
import { Container } from 'react-bootstrap';
import PayrollDeductions from '../../components/Blogs/PayrollDeductions';
import GetInTouch from '../../components/GetInTouch/GetInTouch';
import Notification from '../../components/TopNotification/TopNotification';

const PayrollDeductionsPage = () => {
    return (
        <Fragment>
            <Container fluid="true" className='accContainer'>
                <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
                <TopNavigation title=" Payroll Deductions in Canada: A Deep Dive into Statutory Deductions
           | V-TAC Payroll Management" />
                <PayrollDeductions />
                <GetInTouch />
                <TradePart />
                <Footer />
            </Container>
        </Fragment>
    );
};

export default PayrollDeductionsPage;