import React, { Fragment } from 'react';
import TopNavigation from '../../components/TopNavigation/TopNavigation';
import TradePart from '../../components/TradePart/TradePart';
import Footer from '../../components/Footer/Footer';
import { Container } from 'react-bootstrap';
import GetInTouch from '../../components/GetInTouch/GetInTouch';
import Notification from '../../components/TopNotification/TopNotification';
import EfficientPayrollSolution from '../../components/Blogs/1_EfficientPayrollSolution';

const EfficientPayrollSolutionPage = () => {
    return (
        <Fragment>
            <Container fluid="true" className='accContainer'>
                <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
                <TopNavigation title="Efficient Payroll Solutions for Your Business Success | V-TAC Payroll Management" />
                <EfficientPayrollSolution />
                <GetInTouch />
                <TradePart />
                <Footer />
            </Container>
        </Fragment>
    );
};

export default EfficientPayrollSolutionPage;