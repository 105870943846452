import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const MountRoyalDentalBanner = () => {
    return (
        <>
            <Container fluid={true} className="nexasStaffingBanner  p-0">
                <Row>
                    <Col sm={12} md={12} lg={12}>
                        <h1 className='common-title section-gap text-center' style={{ color: "#fff" }}>
                            MOUNT ROYAL SAVES<br></br> TIME & MONEY WITH VPM
                        </h1>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default MountRoyalDentalBanner;

