import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import customreport from '../../asset/images/home/006.webp';
import { Link } from 'react-router-dom';
import { MdChevronRight } from "react-icons/md";

const ContractCommitment2 = () => {
   
    return (
        <>
            <Container fluid style={{ backgroundColor: "#283146" }}>
                <Container >
                    <Row>
                        <Col lg={4} md={6} xs={12} className='section-gap'>
                            <img
                                src={customreport}
                                alt="custom report"
                                className="report-img img-fluid mb-5 "
                            />
                        </Col>
                        <Col lg={8} md={6} xs={12} className='section-gap'>
                            <h2 className='common-title'><span style={{color:"#FFF"}}>No Contracts, No Commitments With VPM, </span><span style={{ color: "#779EFF" }}>you always have freedom and flexibility</span></h2>
                            <p className='common-p8 mt-5' style={{ color: "#F4F4F4" }}>👉🏻  At VPM, we believe in earning your trust and business every day, which is why we don’t lock you into contracts. Unlike many other payroll providers, we offer a unique approach to service – one without the constraints of long-term commitments. </p>
                            <p className='common-p8 mb-5' style={{ color: "#F4F4F4" }}>👉🏻  That’s our way of ensuring that we are constantly motivated to provide you with the best payroll management service in the industry.</p>
                            <Link to="/request-payroll-quote"><Button className='any-device-btn mb-5' style={{ backgroundColor: "#779EFF", color: "#FFF" }}><span>Transform my payroll</span> <MdChevronRight /></Button>{' '}</Link>
                        </Col>
                    </Row>

                </Container>
            </Container>


        </>
    );
};

export default ContractCommitment2;

