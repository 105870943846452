import React from 'react';
import { Col, Container, Row,Button } from 'react-bootstrap';
import { FaShieldAlt } from 'react-icons/fa';
import { FcMultipleDevices } from 'react-icons/fc';
import { SiEasyeda } from 'react-icons/si';
import { MdChevronRight } from "react-icons/md";
import { Link } from 'react-router-dom';

const SolutionBanner = (props) => {
    const { imageUrl, title, caption, paragraph } = props.data;
    
    const iconSize = 20;
    const iconClassName = 'mr-2';
    const textStyle = {
        // whiteSpace: 'nowrap',
        color: "#FF135A",
        fontSize: ".9rem",
        fontFamily: "Onest",
        fontWeight: ' 400',
        lineHeight: '1.5rem',
        marginTop: '10px',
        // marginRight: '2rem'
    };
    return (
        <>
            <Container fluid style={{ backgroundColor: "#FFEFEF" }}>
                <Container className=''>
                    <Row className=''>
                        <Col lg={7} md={6} xs={12}>
                            <p className='common-p3 section-gap' style={{ color: "#E6001F" }}>
                                {caption}
                            </p>
                            <h1 className='common-title'>
                                {title}
                            </h1>
                            <p className='common-p4 mb-4'>
                                {paragraph}
                            </p>
                            <Link to="/request-payroll-quote"><Button className='quote-btn-banner mt-3 mb-4'><span>Free Quote</span> <MdChevronRight /></Button>{' '}</Link>
                            <Row className='mt-3'>
                                {/* <Col lg={4} md={4} sm={4} xs={6} className="mb-3">
                                    <FaLaptop size={iconSize} className={iconClassName} style={{ color: "#B951DD" }} />
                                    <span className='common-p' style={{ color: "#B951DD" }} >Multi-Business Access</span>
                                </Col> */}
                                <Col lg={4} md={4} sm={4} xs={6} className="mb-5">
                                    <SiEasyeda size={iconSize} className={iconClassName} style={{ color: "#9D87F6" }} />
                                    <span className='common-p' style={{ color: "#9D87F6" }}><strong>Easy to access</strong></span>
                                </Col>

                                <Col lg={4} md={4} sm={4} xs={6} className="mb-3">
                                    <FaShieldAlt size={iconSize} className={iconClassName} style={{ color: "#05C27F" }} />
                                    <span className='common-p' style={{ color: "#05C27F" }}><strong>Highly Secured</strong></span>
                                </Col>

                                <Col lg={4} md={4} sm={4} xs={6} className="mb-3">
                                    <FcMultipleDevices size={iconSize} className={iconClassName} style={{ color: "#F4C884" }} />
                                    <span className='common-p' style={{ color: "#DE7079" }} ><strong>Any Device Access</strong></span>
                                </Col>

                            </Row>
                        </Col>
                        <Col lg={5} md={6} xs={12}>
                            <img src={imageUrl} className='sub-banner-img float-end section-gap'  alt="quote banner"  fluid
                            />
                        </Col>
                        <div style={{ marginBottom: "6rem" }}></div>
                    </Row>

                </Container>
            </Container>
        </>
    );
};

export default SolutionBanner;