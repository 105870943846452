import React, { Fragment } from 'react';
import TopNavigation from '../../components/TopNavigation/TopNavigation';
import TradePart from '../../components/TradePart/TradePart';
import Footer from '../../components/Footer/Footer';
import { Container } from 'react-bootstrap';
import UnderstandingEmployment from '../../components/Blogs/UnderstandingEmployment';
import GetInTouch from '../../components/GetInTouch/GetInTouch';
import Notification from '../../components/TopNotification/TopNotification';

const UnderstandingEmploymentPage = () => {

    return (
        <Fragment>
            <Container fluid="true" className='accContainer'>
                <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
                <TopNavigation title="Understanding Employment Insurance Benefits for Business Owners in Canada
                     | V-TAC Payroll Management" />
                <UnderstandingEmployment />
                <GetInTouch />
                <TradePart />
                <Footer />
            </Container>
        </Fragment>
    );
};

export default UnderstandingEmploymentPage;