import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';


class InsAgentFeedback2 extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="nexasClientSayCard  p-0">
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <div className='container'>
                                <h3 className='nexasCardTitle section-gap'>LESS TIME ON PAPERWORK AND<br></br> <span className='spancolor'>MORE TIME WITH CLIENTS</span></h3>
                                <p className='nexasCardDesc mt-3'>
                                    “With a dedicated account manager and an all-in-one payroll solution that handles their needs, Asif and his team can now spend less time on micromanaging documents, leaving them with more energy and time to foster relationships with their clients.”
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default InsAgentFeedback2;