import { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { FaArrowRightLong } from "react-icons/fa6";
import axios from 'axios';
import ca from '../../asset/images/accountant/ca.png';

const PartnerProgramForm = () => {
    const [validated, setValidated] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
    });



    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    // clear form data
    const clearForm = () => {
        setFormData({
            name: '',
            email: '',
            phone: '',
        })
    }

    // Scroll to top on page load
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const accountantFormSubmit = async (event) => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return;
        }

        try {
            const response = await axios.post('https://api.vtacpayroll.ca/createPartner', formData);
            // const response = await axios.post('http://localhost:5000/createPartner', formData);

            if (response.status === 200) {
                clearForm();
                window.location.href = "https://www.vtacpayroll.ca/partner-program-request-success";
                // window.location.href = "http://localhost:3000/partner-program-request-success";
                console.log('Form data submitted successfully!');
            }
        } catch (error) {
            console.error('Error submitting form data:', error.message);
        }
    };
    return (
        <>
            <Container fluid style={{ backgroundColor: "#FFEFEF" }}>
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className="partnarCard section-gap" id="RQForm" style={{ backgroundColor: "#252E44" }}>
                                <h2 className="common-title text-center mt-5" style={{ color: "#fff" }}>Join our Partner program</h2>
                                <p className='common-p7 text-center' style={{ color: "#1CA9AB", fontSize: "1.125rem" }}>Once you fill up the bellow form, one of our representatives will get back to<br></br> you within 48 hours</p>
                                <div className="form-container">
                                    <Form noValidate validated={validated} onSubmit={accountantFormSubmit}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="validationCustom01">
                                                <Form.Label className='common-p'>Contact Person Name<span style={{ color: "red" }}>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Your Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Provide Your Name
                                                </Form.Control.Feedback>

                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="validationCustomUsername">
                                                <Form.Label className='common-p'>Your Email<span style={{ color: "red" }}>*</span></Form.Label>
                                                <InputGroup hasValidation>
                                                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Your Email Address"
                                                        aria-describedby="inputGroupPrepend"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Provide Your Email Address
                                                    </Form.Control.Feedback>
                                                    {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                                </InputGroup>
                                            </Form.Group>

                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="validationCustomUsername">
                                                <Form.Label className='common-p'>Phone Number<span style={{ color: "red" }}>*</span></Form.Label>
                                                <InputGroup hasValidation>
                                                    <InputGroup.Text id="inputGroupPrepend"><img src={ca} /></InputGroup.Text>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder=" +1"
                                                        aria-describedby="inputGroupPrepend"
                                                        name="phone"
                                                        value={formData.phone}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Provide Your Phone Number
                                                    </Form.Control.Feedback>
                                                    {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                                </InputGroup>
                                            </Form.Group>
                                        </Row>
                                        <Button type="submit" className='t4-btn mt-3 mb-5'>I’m Interested <FaArrowRightLong /></Button>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
};

export default PartnerProgramForm;