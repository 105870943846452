import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Logo from '../../asset/images/home/Logo.png';
import phoneimg from '../../asset/images/home/call-phone.png';
import { FaYoutube, FaEnvelope } from "react-icons/fa";
import { BiLogoLinkedin, BiLogoFacebook } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import { Link as ScrollLink } from 'react-scroll';
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";


const Footer = (props) => {

    return (
        <Fragment>
            <Container fluid={true} className="footerSection" style={{ marginTop: props.marginTop }} >
                <div>
                    <Container>
                        <Row>
                            <Col lg={4} md={6} sm={12} className="">
                                <Link to="/"><span class="FooterTitle"><img className="logo-footer" title="VPM" src={Logo} data-aos="zoom-in-up" data-aos-once="true" /></span></Link>
                                <div>
                                    <p className='common-p' style={{ color: "#DEDEDE" }}>VPM’s technology is simple and efficient. New clients are provided with a specialist so that there is a smooth transition.</p>
                                    <strong className='common-p' style={{ color: "#DEDEDE" }}>Follow Us</strong>
                                    <div className="footerLinkDiv mt-3 ">
                                        <a href="https://www.facebook.com/vtacpayroll/" target='_blank' className='' > <BiLogoFacebook className="fa fa-facebook" /></a>
                                        <a href="https://twitter.com/vtacpayroll" target='_blank' ><FaXTwitter className='fa fa-twitter' /></a>
                                        <a href="https://www.linkedin.com/company/v-tac-payroll-management/" target='_blank' className=""><BiLogoLinkedin className='fa fa-linkedin' /></a>
                                        <a href="https://www.youtube.com/@v-tacpayrollmanagement3092/videos" target='_blank' className='' ><FaYoutube className='fa fa-youtube' /></a>

                                    </div>

                                    <p className='common-p mt-3' style={{ color: "#DEDEDE" }}><FaEnvelope size={18} style={{ color: '#fff' }} /><strong> info@vtacpayroll.ca</strong> </p>
                                    <p className='common-p'><a href="tel:+1-866-315-8822" ><span style={{ color: "#fff" }}><FaPhoneAlt size={18} /></span>
                                        <span style={{ color: "#fff" }}> 1-866-315-8822</span></a>
                                    </p>
                                    <p className='common-p mt-3 footer-top' style={{ color: "#DEDEDE" }}><span><FaLocationDot size={22} color='red' /></span><span> 401 Bay Street, Suite 1600,<br></br>&nbsp;&nbsp;&nbsp;&nbsp; Toronto, ON M5H 2Y4</span></p>

                                </div>
                            </Col>
                            <Col lg={8} md={6} sm={12} className='mt-5'>
                                <Container style={{ backgroundColor: "#252E44", borderRadius: '3px', border: "1px solid #6CA2E1" }}>
                                    <Row className="justify-content-center align-items-center">
                                        <Col lg={2} md={6}>
                                            <img src={phoneimg} style={{ padding: '25px' }} />
                                        </Col>
                                        <Col lg={7} md={6}>
                                            <h2 className='call-us'>Call us now to switch your payroll...</h2>
                                            {/* <p className='call-us-p'><strong  style={{color:"#00BCD4"}}>Any Queries ? Why not contact us today !</strong></p> */}

                                        </Col>
                                        <Col md={3} className="text-md-right">
                                            <div>
                                                <Link to="/contact"><Button className='Storybutton' style={{ backgroundColor: "#6CA2E1" }}><span>Contact us</span></Button></Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>

                                <Row>
                                    <Col lg={3} md={6} sm={12} className="mt-5">
                                        <h2 class="FooterTitle"><span>Know Us</span></h2>
                                        <div>
                                            <ul className='footerUl'>
                                                <Link to="/faq"><li><a className='aColor'>FAQ</a></li></Link>
                                                <Link to="/careers"><li><a className='aColor'>Careers</a></li></Link>
                                                <Link to="/request-payroll-quote"><li><a className='aColor'>Request Quote</a></li></Link>
                                                <Link to="/contact"><li><a className='aColor' >Contact us</a></li></Link>
                                                <Link to="/privacy-policy"><li><a className='aColor' >Privacy Policy</a></li></Link>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6} sm={12} className="mt-5">
                                        <h3 class="FooterTitle"><span>Industries we serve</span></h3>
                                        <div>
                                            <ul className='footerUl'>
                                                <Link to="/payroll-service-dentists"><li><a className='aColor'> Dentistry</a></li></Link>
                                                <Link to="/hospitality-payroll-software"><li><a className='aColor'> Hospitality</a></li></Link>
                                                <Link to="/payroll-for-law-firms"><li><a className='aColor'> Law Offices</a></li></Link>
                                                <Link to="/payroll-service-employment-agency"><li><a className='aColor' > Employment Agencies</a></li></Link>
                                                <Link to="/payroll-company-construction"><li><a className='aColor'> Construction</a></li></Link>
                                            </ul>
                                        </div>
                                    </Col>

                                    <Col lg={3} md={6} sm={12} className="mt-5 text-justify">
                                        <h3 class="FooterTitle"><span>Resources</span></h3>
                                        <div>
                                            <ul className='footerUl'>
                                                <Link to="/industry-news"> <li><a className='aColor'>Industry News</a></li></Link>
                                                <Link to="/about-us"><li><a className='aColor'>About Us</a></li></Link>
                                                <Link to="/"><li>
                                                    <ScrollLink
                                                        activeClass="active"
                                                        to="whyShitch"
                                                        spy={true}
                                                        smooth={true}
                                                        offset={-70}
                                                        duration={500}

                                                    > <Link to="/" className='aColor' >Why we are better</Link></ScrollLink>
                                                </li></Link>
                                                <Link to="/client-success-stories" className='aColor' ><li><a className='aColor'>Success Storie</a></li></Link>
                                                {/* <Link to="/"><li>
                                                        <ScrollLink
                                                            activeClass="active"
                                                            to="successStory"
                                                            spy={true}
                                                            smooth={true}
                                                            offset={-70}
                                                            duration={500}

                                                        > <Link to="/" className='aColor' >Success Stories</Link></ScrollLink>
                                                    </li></Link> */}
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6} sm={12} className="mt-5 text-justify">
                                        <h3 class="FooterTitle"><span>Service</span></h3>
                                        <div>
                                            <ul className='footerUl'>
                                                <Link to="/business-payroll-services"> <li><a className='aColor'> Payroll Services</a></li></Link>
                                                {/* <Link to="/who-we-are"><li><a className='aColor'> Who We Are</a></li></Link> */}

                                            </ul>
                                        </div>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Container >

            <Container fluid={true} className="text-center copyrightSec">
                <Row>
                    <Col lg={12} md={12} sm={12}>
                        <p className="copyrightLink" >
                            Copyright &copy; 2024 V-TAC Payroll Management. All rights reserved.
                        </p>
                    </Col>
                </Row>
            </Container>
        </Fragment >
    )
}
export default Footer;
