import React from 'react';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import SuccessMsg from '../components/T4/SuccessMsg';

const AccountantSucessPage = () => {
    const Data = {
        // imageUrl: banner,
        title: 'Thank you for submitting your VPM accounting partnership request. We will get back to you shortly.',
    };
    return (
        <>
            <TopNavigation title="VPM accounting partnership Request Success | V-TAC Payroll Management" />
            <SuccessMsg data={Data}/>
            <TradePart />
            <Footer />
        </>
    );
};

export default AccountantSucessPage;