import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaRegCheckCircle } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import Vpm1 from '../../asset/images/quote/success.jpg';
import AOS from 'aos';


class QuotationSend extends Component {
    componentDidMount() {
        AOS.init();
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <Fragment>
                <Container className='SectionBetween'>
                    <Row>
                        <Col lg={6} md={12} sm={12}>
                            <div className=" quoteCardSuccess" data-aos="fade-right"
                                data-aos-anchor="#example-anchor"
                                data-aos-offset="500"
                                data-aos-duration="1000">
                                <div id='SuccessMSgDiv' className='text-center'>
                                    <FaRegCheckCircle size={40} color='green' />
                                    <h2 className='SuccessMsg mt-5'>Thank you for submitting your request for a quote. We will get back to you shortly.</h2>
                                    <p className='SuccessMsgDesc'>if you would like to speak with someone right away, feel free to call us  on our toll number at:</p>
                                    <p className='SuccessMsgDesc mb-5' ><a href="tel:+1-866-315-8822" style={{ color: "#E6001F" }}><FaPhone size={25} /> 1-866-315-8822</a></p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>

                            <div className='success-right-div'>
                                <img data-aos="zoom-in"
                                    data-aos-anchor="#example-anchor"
                                    data-aos-offset="500"
                                    data-aos-duration="2000" className="quotationRequestImg" src={Vpm1} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default QuotationSend;