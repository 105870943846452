import React, { Fragment } from 'react';
import TopNavigation from '../../components/TopNavigation/TopNavigation';
import TradePart from '../../components/TradePart/TradePart';
import Footer from '../../components/Footer/Footer';
import { Container } from 'react-bootstrap';
import EffectiveWays from '../../components/Blogs/EffectiveWays';
import GetInTouch from '../../components/GetInTouch/GetInTouch';
import Notification from '../../components/TopNotification/TopNotification';

const Blog3Page = () => {

    return (
        <Fragment>
            <Container fluid="true" className='accContainer'>
                <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
                <TopNavigation title="7 Effective Ways to Retain Top Talent in Your Organization | V-TAC Payroll Management" />
                <EffectiveWays />
                <GetInTouch />
                <TradePart />
                <Footer />
            </Container>
        </Fragment>
    );
};

export default Blog3Page;