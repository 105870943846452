import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const NexasStaffingBanner = () => {
    return (
        <>
            <Container fluid={true} className="nexasStaffingBanner  p-0">
                <Row>
                    <Col sm={12} md={12} lg={12}>
                        <h1 className='common-title section-gap text-center' style={{color:"#fff"}}>
                            HOW NEXUS STAFFING FOUND<br></br> GROWTH WITH VPM
                        </h1>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default NexasStaffingBanner;
