import { useState,useEffect } from 'react';
import { Col, Row, Container, Modal, Button } from 'react-bootstrap';
import Slider from "react-slick";
import ReactPlayer from 'react-player';

import poster1 from '../../asset/images/home/video-image/01.png';
import poster2 from '../../asset/images/home/video-image/02.png';
import poster3 from '../../asset/images/home/video-image/03.png';
import poster4 from '../../asset/images/home/video-image/04.png';
import poster5 from '../../asset/images/home/video-image/05.png';
import poster6 from '../../asset/images/home/video-image/06.png';
import poster7 from '../../asset/images/home/video-image/07.png';
import poster8 from '../../asset/images/home/video-image/08.png';
import poster9 from '../../asset/images/home/video-image/09.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

const VpmClientVideo = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        AOS.init();
    }, []);
    // Create separate state for each modal

    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [showModal4, setShowModal4] = useState(false);
    const [showModal5, setShowModal5] = useState(false);
    const [showModal6, setShowModal6] = useState(false);
    const [showModal7, setShowModal7] = useState(false);
    const [showModal8, setShowModal8] = useState(false);
    const [showModal9, setShowModal9] = useState(false);

    const handleClose = () => {
        setShowModal1(false);
        setShowModal2(false);
        setShowModal3(false);
        setShowModal4(false);
        setShowModal5(false);
        setShowModal6(false);
        setShowModal7(false);
        setShowModal8(false);
        setShowModal9(false);

    };

    const handleShow = (modalNumber) => {
        // Open the corresponding modal
        if (modalNumber === 1) {
            setShowModal1(true);
        } else if (modalNumber === 2) {
            setShowModal2(true);
        }
        else if (modalNumber === 3) {
            setShowModal3(true);
        }
        else if (modalNumber === 4) {
            setShowModal4(true);
        }
        else if (modalNumber === 5) {
            setShowModal5(true);
        }
        else if (modalNumber === 6) {
            setShowModal6(true);
        }
        else if (modalNumber === 7) {
            setShowModal7(true);
        }
        else if (modalNumber === 8) {
            setShowModal8(true);
        }
        else if (modalNumber === 9) {
            setShowModal9(true);
        }

    };

    const youtubeUrl1 = "https://youtu.be/jpJ3d093EZE";
    const youtubeUrl2 = "https://youtu.be/2aRnjeG4uVM ";
    const youtubeUrl3 = "https://youtu.be/1jAkV1qJGBk";
    const youtubeUrl4 = "https://youtu.be/W1f3MinobvI";
    const youtubeUrl5 = "https://youtu.be/tmKPQMVfCSk";
    const youtubeUrl6 = "https://youtu.be/RpdWMCMr5Oo";
    const youtubeUrl7 = "https://youtu.be/CNO9jqHFL64";
    const youtubeUrl8 = "https://youtu.be/jc6idXtx7tI";
    const youtubeUrl9 = "https://www.youtube.com/watch?v=xUZ07bryA-0";


    var settings = {
        dots: true,
        arrows: true,
        speed: 1000,          // Transition speed (1 second)
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        autoplaySpeed: 3000,
        initialSlide: 0,
        infinite: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <Container fluid className='all-one-container' style={{ backgroundColor: "#FFF4F0" }}>
                <Container className='mt-5 mb-5'>
                    <Row className='text-center'>
                        <Col xs={12} lg={12} md={12}>
                            <div data-aos="fade-up" data-aos-duration="800" data-aos-once="true">
                                <h2 className='common-title text-center'>Voices of success: Hear from our satisfied clients</h2>
                                <p className='common-p'>Unveiling Remarkable Client Success: Inspiring Journeys of Achievement and Excellence in Our Client's Own Words</p>
                            </div>
                        </Col>
                        <Col>
                            <Slider {...settings}>
                                <div>
                                    <img
                                        src={poster1}
                                        alt="client Image"
                                        onClick={() => handleShow(1)}
                                        className="img-fluid d-block mx-auto"
                                        style={{ cursor: "pointer" }}
                                    />
                                    <Modal size="lg" show={showModal1} onHide={handleClose} centered backdrop="static" keyboard={false}>
                                        <Modal.Header closeButton />
                                        <Modal.Body className="video-modal-body">
                                            <ReactPlayer url={youtubeUrl1} controls={true} width="100%" height="100%" />
                                            <div className='mt-4 mb-5'>
                                                <p className='common-title2' style={{ fontSize: "1.2rem" }}>What MBA Staffing Says About Us || VPM</p>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleClose}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                                <div>
                                    <img
                                        src={poster2}
                                        alt="client Image"
                                        onClick={() => handleShow(2)}
                                        className="img-fluid d-block mx-auto"
                                        style={{ cursor: "pointer" }}
                                    />
                                    <Modal size="lg" show={showModal2} onHide={handleClose} centered backdrop="static" keyboard={false}>
                                        <Modal.Header closeButton />
                                        <Modal.Body className="video-modal-body">

                                            <ReactPlayer url={youtubeUrl2} controls={true} width="100%" height="100%" />
                                            <div className='mt-4 mb-5'>
                                                <p className='common-title2' style={{ fontSize: "1.2rem" }}>What Royal Pioneer Says About Us || VPM</p>
                                            </div>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleClose}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                                <div>
                                    <img
                                        src={poster3}
                                        alt="client Image"
                                        onClick={() => handleShow(3)}
                                        className="img-fluid d-block mx-auto"
                                        style={{ cursor: "pointer" }}
                                    />
                                    <Modal size="lg" show={showModal3} onHide={handleClose} centered backdrop="static" keyboard={false}>
                                        <Modal.Header closeButton />
                                        <Modal.Body className="video-modal-body">

                                            <ReactPlayer url={youtubeUrl3} controls={true} width="100%" height="100%" />
                                            <div className='mt-4 mb-5'>
                                                <p className='common-title2' style={{ fontSize: "1.2rem" }}>What Source Momentum Says About Us || VPM</p>
                                            </div>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleClose}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                                <div>
                                    <img
                                        src={poster4}
                                        alt="client Image"
                                        onClick={() => handleShow(4)}
                                        className="img-fluid d-block mx-auto"
                                        style={{ cursor: "pointer" }}
                                    />
                                    <Modal size="lg" show={showModal4} onHide={handleClose} centered backdrop="static" keyboard={false}>
                                        <Modal.Header closeButton />
                                        <Modal.Body className="video-modal-body">

                                            <ReactPlayer url={youtubeUrl4} controls={true} width="100%" height="100%" />
                                            <div className='mt-4 mb-5'>
                                                <p className='common-title2' style={{ fontSize: "1.2rem" }}>What Pizza Hut Says About US || VPM</p>
                                            </div>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleClose}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                                <div>
                                    <img
                                        src={poster5}
                                        alt="client Image"
                                        onClick={() => handleShow(5)}
                                        className="img-fluid d-block mx-auto"
                                        style={{ cursor: "pointer" }}
                                    />
                                    <Modal size="lg" show={showModal5} onHide={handleClose} centered backdrop="static" keyboard={false}>
                                        <Modal.Header closeButton />
                                        <Modal.Body className="video-modal-body">

                                            <ReactPlayer url={youtubeUrl5} controls={true} width="100%" height="100%" />
                                            <div className='mt-4 mb-5'>
                                                <p className='common-title2' style={{ fontSize: "1.2rem" }}>What Molly Maid Says About Us || VPM</p>
                                            </div>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleClose}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                                <div>
                                    <img
                                        src={poster6}
                                        alt="client Image"
                                        onClick={() => handleShow(6)}
                                        className="img-fluid d-block mx-auto"
                                        style={{ cursor: "pointer" }}
                                    />
                                    <Modal size="lg" show={showModal6} onHide={handleClose} centered backdrop="static" keyboard={false}>
                                        <Modal.Header closeButton />
                                        <Modal.Body className="video-modal-body">

                                            <ReactPlayer url={youtubeUrl6} controls={true} width="100%" height="100%" />
                                            <div className='mt-4 mb-5'>
                                                <p className='common-title2' style={{ fontSize: "1.2rem" }}>What Altamira Maintenance Says About Us || VPM</p>
                                            </div>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleClose}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                                <div>
                                    <img
                                        src={poster7}
                                        alt="client Image"
                                        onClick={() => handleShow(7)}
                                        className="img-fluid d-block mx-auto"
                                        style={{ cursor: "pointer" }}
                                    />
                                    <Modal size="lg" show={showModal7} onHide={handleClose} centered backdrop="static" keyboard={false}>
                                        <Modal.Header closeButton />
                                        <Modal.Body className="video-modal-body">

                                            <ReactPlayer url={youtubeUrl7} controls={true} width="100%" height="100%" />
                                            <div className='mt-4 mb-5'>
                                                <p className='common-title2' style={{ fontSize: "1.2rem" }}>What Space Age Closets Says About Us || VPM</p>
                                            </div>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleClose}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                                <div>
                                    <img
                                        src={poster8}
                                        alt="client Image"
                                        onClick={() => handleShow(8)}
                                        className="img-fluid d-block mx-auto"
                                        style={{ cursor: "pointer" }}
                                    />
                                    <Modal size="lg" show={showModal8} onHide={handleClose} centered backdrop="static" keyboard={false}>
                                        <Modal.Header closeButton />
                                        <Modal.Body className="video-modal-body">

                                            <ReactPlayer url={youtubeUrl8} controls={true} width="100%" height="100%" />
                                            <div className='mt-4 mb-5'>
                                                <p className='common-title2' style={{ fontSize: "1.2rem" }}>What Dairy Dell Bar & Grill Says About Us || VPM</p>
                                            </div>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleClose}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                                <div>
                                    <img
                                        src={poster9}
                                        alt="client Image"
                                        onClick={() => handleShow(9)}
                                        className="img-fluid d-block mx-auto"
                                        style={{ cursor: "pointer" }}
                                    />
                                    <Modal size="lg" show={showModal9} onHide={handleClose} centered backdrop="static" keyboard={false}>
                                        <Modal.Header closeButton />
                                        <Modal.Body className="video-modal-body">

                                            <ReactPlayer url={youtubeUrl9} controls={true} width="100%" height="100%" />
                                            <div className='mt-4 mb-5'>
                                                <p className='common-title2' style={{ fontSize: "1.2rem" }}>What Ozzy's Burgers Says About Us || VPM</p>
                                            </div>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleClose}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </Slider>

                        </Col>
                    </Row>
                </Container>
            </Container >
        </>
    );
};

export default VpmClientVideo;