import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import acc1 from '../../asset/images/accountant/004.webp';
import { BsFillPeopleFill } from "react-icons/bs";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { LiaIndustrySolid } from "react-icons/lia";
import { LiaFileContractSolid } from "react-icons/lia";
import { TbCloudPlus } from "react-icons/tb";
import { BiLike } from "react-icons/bi";
const LeavePayrollToUs = () => {
    return (
        <>
            <Container fluid style={{ backgroundColor: "#fff" }}>
                <Container >
                    <Row>
                        <Col lg={6} md={6} sm={12} className='section-gap'>
                            <img className="payroll-Image" style={{ width: "120%" }} alt="t4-details" src={acc1} />
                        </Col>
                        <Col lg={6} md={6} sm={12} className='section-gap'>
                            <div>
                                <h2 className='common-title'>Leave Payroll to Us - Free up time for what matters most</h2>
                                <p className='common-title7 mt-4' style={{ color: "#6B6B6B" }}>As an accountant, your expertise is invaluable for strategic financial advising and core accounting functions. Let VPM take over the complexities of payroll processing. Our comprehensive payroll solutions handle everything, enabling you to dedicate your resources to areas that truly matter.</p>
                                <p className='common-p6 section-gap' ><BsFillPeopleFill size={25} color='#FF135A'/> &nbsp;&nbsp; Personalized Service</p>
                                <p className='common-p6'><TfiHeadphoneAlt size={25} color='#FF135A' /> &nbsp;&nbsp;Dedicated Support</p>
                                <p className='common-p6' ><LiaIndustrySolid size={25} color='#FF135A'/> &nbsp;&nbsp;Specialized Industry Expertise</p>
                                <p className='common-p6'><TbCloudPlus size={25} color='#FF135A'/> &nbsp;&nbsp;Cost-Effective Solutions</p>
                                <p className='common-p6'><BiLike size={25} color='#FF135A'/> &nbsp;&nbsp;A Proven Track Record of Excellence</p>
                                <p className='common-p6'><LiaFileContractSolid size={25} color='#FF135A'/> &nbsp;&nbsp;No Contracts or Commitments</p>
                            </div>
                        </Col>
                    </Row>
                </Container >
            </Container >
        </>
    );
};

export default LeavePayrollToUs;