import React, { Fragment } from 'react';
import Footer from '../components/Footer/Footer';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import PayrollCalculator from '../components/PayrollCalculator/PayrollCalculator';
import Notification from '../components/TopNotification/TopNotification';
import bannerImage from '../../src/asset/images/accountant/calculator2x.png';
import Banner from '../components/Banner/Banner';
import AnualSummary from '../components/AnualSummary/AnualSummary';
import VpmClientVideo from '../components/VpmClientVideo/VpmClientVideo';
import CanadianTrust from '../components/PayrollCalculator/CanadianTrust';

const PayrollCalculatorPage = () => {
    const imageData = {
        imageUrl: bannerImage,
        caption: 'Payroll Calculator',
        title: 'Effortlessly calculate your payroll in seconds',
        // paragraph: 'advanced algorithms meet top-priority data confidentiality for seamless, error-free payroll processing.'
        paragraph: 'Input your details into the VPM Payroll Calculator for instant net and gross pay insights.'
    };
    return (
        <Fragment>
            <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
            <TopNavigation title="Payroll Calculator | V-TAC Payroll Management " />
            <Banner data={imageData} />
            <PayrollCalculator />
            <div className='mt-5'></div>
            <CanadianTrust />
            <AnualSummary marginTop=""/>
            <VpmClientVideo />
            <TradePart />
            <Footer />
        </Fragment>
    );
};

export default PayrollCalculatorPage;