import React, { Fragment } from 'react';
import ContactSection from '../components/ContactSection/ContactSection';
import Footer from '../components/Footer/Footer';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import ContactBanner from '../components/Banner/ContactBanner';
import Notification from '../components/TopNotification/TopNotification';
import CommonFaq from '../components/FAQ/CommonFaq';

const ContactPage = () => {
 
    return (
        <Fragment>
            <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
            <TopNavigation title="Contact | V-TAC Payroll Management" />
            {/* <ContactBanner image={bannerImage} bannerTitle={headerData} /> */}
            <ContactBanner />
            <ContactSection />
            <CommonFaq />
            <TradePart />
            <Footer />
        </Fragment>
    );
}


export default ContactPage;