import React, { Component, Fragment } from 'react';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import InsuranceAgentBanner from '../components/InsuranceAgent/InsuranceAgentBanner';
import InsAgentSec1 from '../components/InsuranceAgent/InsAgentSec1';
import InsAgentFeedback1 from '../components/InsuranceAgent/InsAgentFeedback1';
import InsAgentSec2 from '../components/InsuranceAgent/InsAgentSec2';
import InsAgentFeedback2 from '../components/InsuranceAgent/InsAgentFeedback2';
import Notification from '../components/TopNotification/TopNotification';

class InsuranceAgentPage extends Component {
    render() {
        return (
            <Fragment>
                <Notification message="Try out our " duration={800000} subscription="Free payroll for one month & a free tablet!" />
                <TopNavigation title="Asif Khan Insurance | V-TAC Payroll Management" />
                <InsuranceAgentBanner />
                <InsAgentSec1 />
                <InsAgentFeedback1 />
                <InsAgentSec2 />
                <InsAgentFeedback2 />
                <TradePart />
                <Footer />
            </Fragment>
        );
    }
}

export default InsuranceAgentPage;