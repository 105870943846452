import React from 'react';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import SolutionBanner from '../components/Banner/SolutionBanner';
import AnualSummay from '../components/AnualSummary/AnualSummary';
import SolutionSec3 from '../components/SolutionSec3/SolutionSec3';

import banner from '../../src/asset/images/solution/unified-hr.png';
import uniHr from '../../src/asset/images/solution/uni-hr.png';
import OfferQuote from '../components/OfferQuote/OfferQuote';
import PayrollServicesSeventhPart from '../components/PayrollServicesSeventhPart/PayrollServicesSeventhPart';
import GetInTouch from '../components/GetInTouch/GetInTouch';
import Notification from '../components/TopNotification/TopNotification';

const UnifiedHRAndBenefitsPage = () => {
    const imageData = {
        imageUrl: banner,
        topPara: 'Solutions/ Unified HR',
        title: 'Reduce Processing time & errors by integrating HR',
        paragraph: 'Manage your workforce & setup benefits/deductions based on your unique payroll process your unique payroll '

    };
    const DataInfo = {
        imageUrl: uniHr,
        title: 'Helping you Manage your Business more Efficient & Effective',
        p1: '✅ Having HR, benefits and payroll information accessible through a single platform, meaning there is no need to switch between systems to find information.',
        p2: '✅  Easily automated payroll deductions that are fully compliant with Canadian payroll regulations.',
        p3: '✅  Efficient management of human capital from recruitment & onboarding, to performance evaluation and feedback, to exit interviews & offboarding.'
    };

    return (
        <>
            <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
            <TopNavigation title="Unified HR & Benefits | V-TAC Payroll Management" />
            <SolutionBanner data={imageData} />
            <AnualSummay marginTop="5rem" />
            <SolutionSec3 data={DataInfo} />
            <OfferQuote />
            <PayrollServicesSeventhPart />
            <GetInTouch />
            <TradePart />
            <Footer />

        </>
    );
};
export default UnifiedHRAndBenefitsPage;