import React, { Fragment } from 'react';
import TopNavigation from '../../components/TopNavigation/TopNavigation';
import TradePart from '../../components/TradePart/TradePart';
import Footer from '../../components/Footer/Footer';
import { Container } from 'react-bootstrap';
import PensionPlan from '../../components/Blogs/PensionPlan';
import GetInTouch from '../../components/GetInTouch/GetInTouch';
import Notification from '../../components/TopNotification/TopNotification';

const Blog4Page = () => {
    return (
        <Fragment>
            <Container fluid="true" className='accContainer'>
                <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
                <TopNavigation title="4 Understanding the Canadian Pension Plan: A Complete Guide
                   | V-TAC Payroll Management" />
                <PensionPlan />
                <GetInTouch />
                <TradePart />
                <Footer />
            </Container>
        </Fragment>
    );
};

export default Blog4Page;