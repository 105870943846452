import React, { useState } from 'react';
import { AiOutlineArrowUp } from 'react-icons/ai';
 // Import your custom CSS file

function TopUpButton() {
  const [isScrolling, setIsScrolling] = useState(false);

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling animation
  
    });
  };

  // Function to show/hide the button based on scroll position
  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  };

  // Add a scroll event listener to track scroll position
  window.addEventListener('scroll', handleScroll);

  return (
    <button
      className={`top-up-button ${isScrolling ? 'show' : ''}`}
      onClick={scrollToTop}
    >
      <AiOutlineArrowUp className="arrow-icon" />
    </button>
  );
}

export default TopUpButton;
