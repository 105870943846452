import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const IndustryBanner = (props) => {
    const { imageUrl, title,caption, paragraph } = props.data;
    return (
        <>
            <Container fluid style={{ backgroundColor: "#FFEFEF" }}>
                <Container className=''>
                    <Row className=''>
                        <Col lg={7} md={6} xs={12}>
                            <p className='common-p3 mt-5' style={{ color: "#E6001F" }}>
                                {caption}
                            </p>
                            <h1 className='common-title'>
                               {title}
                            </h1>
                            <p className='common-p4 mb-5'>
                                {paragraph}
                            </p>
                        </Col>
                        <Col lg={5} md={6} xs={12}>
                            <img src={imageUrl} className='sub-banner-img float-end mt-4 mb-3'  alt="quote banner" title=''  fluid
                            />
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
};

export default IndustryBanner;