import React from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { FaYoutube, FaEnvelope } from "react-icons/fa";
import { BiLogoLinkedin, BiLogoFacebook } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import Map from '../Map/Map';


const ContactBanner = () => {

    return (
        <>

            <Container className='mt-5'>
                <Row>
                    <Col lg={8} md={12} sm={12} xs={12}>
                        <div className=''>
                            <h1 className='common-title'>
                                Contact Us
                            </h1>

                            <p className='common-p4 mb-4'>
                                Do you have any questions or comments? Contact us by giving us a call, sending us an email, or using the contact form below and we’ll get back to you within 24 hours.
                            </p>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} >

                        <strong className='common-p' style={{ color: "#505050" }}>Follow Us</strong>
                        <div className="mt-3 mb-5">
                            <a href="https://www.facebook.com/vtacpayroll/" target='_blank' className='' > <BiLogoFacebook className="social-icon" size={35} /></a>
                            <a href="https://twitter.com/vtacpayroll" target='_blank' ><FaXTwitter className='social-icon' size={35} /></a>
                            <a href="https://www.linkedin.com/company/v-tac-payroll-management/" target='_blank' className=""><BiLogoLinkedin className='social-icon' size={35} /></a>
                            <a href="https://www.youtube.com/@v-tacpayrollmanagement3092/videos" target='_blank' className='' ><FaYoutube className='social-icon' size={35} /></a>

                        </div>
                    </Col>


                    <Col lg={12} md={12} xs={12}>
                        <div>
                            <Map />
                        </div>
                    </Col>
                </Row>

            </Container>

        </>
    );
};

export default ContactBanner;