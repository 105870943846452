import React from 'react';
import AboutUsBanner from '../components/AboutUs/AboutUsBanner';
import Notification from '../components/TopNotification/TopNotification';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import AnualSummary from '../components/AnualSummary/AnualSummary';
import AllClient from '../components/AllClient/AllClient';
import CaTrustedPayroll from '../components/AboutUs/CaTrustedPayroll';
import Footer from '../components/Footer/Footer';
import PayrollSolution from '../components/AboutUs/PayrollSolution';
import VpmPromise from '../components/AboutUs/VpmPromise';
import VpmDifference from '../components/AboutUs/VpmDifference';
import ReputableProfessionals from '../components/AboutUs/ReputableProfessionals';
import WhyCooseUs from '../components/AboutUs/WhyCooseUs';


const AboutUsPage = () => {
    const backgroundColor = '#252E44';
    const color = '#fff';

    return (
        <>
            <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
            <TopNavigation title="About VPM | V-TAC Payroll Management" />
            <AboutUsBanner  />
            <AllClient backgroundColor={backgroundColor} color={color} />
            {/* <PayrollSolution /> */}
            <CaTrustedPayroll />
            <ReputableProfessionals />
            <WhyCooseUs />
            {/* <VpmPromise /> */}
            {/* <AnualSummary /> */}
            {/* <VpmDifference /> */}
            <Footer marginTop="-5rem"/>
        </>
    );
};

export default AboutUsPage;