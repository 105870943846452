import React from 'react';
import ReactDOM from 'react-dom/client';
//import "../src/asset/css/bootstrap.min.css";
import "../src/asset/css/bootstrap.css";
import "../src/asset/css/animate.min.css";
// import "../src/asset/css/calculator.css";
import "../src/asset/css/style.css";
import "../src/asset/css/progress.css";
import '../src/asset/css/map.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


