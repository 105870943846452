import React, { Component, Fragment } from 'react';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import SchindlerBanner from '../components/DrSchindler/SchindlerBanner';
import SchindlerSec1 from '../components/DrSchindler/SchindlerSec1';
import SchindlerFeedback1 from '../components/DrSchindler/SchindlerFeedback1';
import SchindlerFeedback2 from '../components/DrSchindler/SchindlerFeedback2';
import SchindlerSec2 from '../components/DrSchindler/SchindlerSec2';
import Notification from '../components/TopNotification/TopNotification';

class SchindlerAssociatesPage extends Component {
    render() {
        return (
            <Fragment>
                <Notification message="Try out our " duration={800000} subscription="Free payroll for one month & a free tablet!" />
                <TopNavigation title="Dr. Schindler & Associates | V-TAC Payroll Management" />
                <SchindlerBanner />
                <SchindlerSec1 />
                <SchindlerFeedback1 />
                <SchindlerSec2 />
                <SchindlerFeedback2 />
                <TradePart />
                <Footer />
            </Fragment>
        );
    }
}

export default SchindlerAssociatesPage;