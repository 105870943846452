import React from 'react';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import ClientSuccessStories from '../components/ClientSuccessStories/ClientSuccessStories';
import ClientSuccessStoriesBanner from '../components/ClientSuccessStories/ClientSuccessStoriesBanner';
import Client from '../components/Client/Client';
import Notification from '../components/TopNotification/TopNotification';

const ClientSuccessStoriesPage = () => {
    return (
        <>
            <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
            <TopNavigation title="Client Success Stories | V-TAC Payroll Management" />
            <ClientSuccessStoriesBanner />
            <ClientSuccessStories />
            <Client />
            <TradePart />
            <Footer />
        </>
    );
};

export default ClientSuccessStoriesPage;
