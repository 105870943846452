import React, { Component, Fragment } from 'react';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import ConditionBanner from '../components/ConditionBanner/ConditionBanner';
import ConditionSection from '../components/ConditionSection/ConditionSection';
import Notification from '../components/TopNotification/TopNotification';
import GetInTouch from '../components/GetInTouch/GetInTouch';
import OfferQuote from '../components/OfferQuote/OfferQuote';
import AllClient from '../components/AllClient/AllClient';
import SocialMedia from '../components/SocialMedia/SocialMedia';

const ConditionPage = () => {
    return (
        <Fragment>
            <TopNavigation />
            <ConditionBanner />
            <ConditionSection />
            <div className='section-gap'></div>
            <SocialMedia />
            {/* <OfferQuote /> */}
            <AllClient />
            {/* <GetInTouch /> */}
            <TradePart />
            <Footer />
        </Fragment>
    );
}


export default ConditionPage;