import React from 'react';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import Notification from '../components/TopNotification/TopNotification';
import SolutionBanner from '../components/Banner/SolutionBanner';
import AnualSummay from '../components/AnualSummary/AnualSummary';
import OfferQuote from '../components/OfferQuote/OfferQuote';
import GetInTouch from '../components/GetInTouch/GetInTouch';
import SolutionSec3 from '../components/SolutionSec3/SolutionSec3';
import banner from '../../src/asset/images/solution/payroll-service.png';
import SliderComp from '../components/Projects/Slider';
import CommonFaq from '../components/FAQ/CommonFaq';
import VpmClientVideo from '../components/VpmClientVideo/VpmClientVideo';

const PayrollServicesPage = () => {
    const imageData = {
        imageUrl: banner,
        caption: 'Solutions/ Payroll Services',
        title: 'VPM transforms your payroll from a challenge to a strategic advantage',
        paragraph: 'Experience payroll management that perfectly blends simplicity, accuracy, and customization based on your unique needs.'

    };


    return (
        <>
            <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
            <TopNavigation title="Payroll Services | V-TAC Payroll Management" />
            <SolutionBanner data={imageData} />
            <AnualSummay marginTop="" />
            <SolutionSec3 />
            <OfferQuote />
            <SliderComp />
            <VpmClientVideo />
            <GetInTouch />
            <CommonFaq />
            <TradePart />
            <Footer />
        </>
    );
};

export default PayrollServicesPage;