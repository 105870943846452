import React, { Component, Fragment } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import mollymaidlogo from '../../asset/images/mollymaid-logo.png';
import client1 from '../../asset/images/client/1.png';
// import client2 from '../../asset/images/client/2.jfif';
import client3 from '../../asset/images/client/3.png';
import client4 from '../../asset/images/client/4.png';
// import client5 from '../../asset/images/client/5.jpg';
// import client6 from '../../asset/images/client/6.jpg';
import client7 from '../../asset/images/client/7.png';
// import client8 from '../../asset/images/client/8.png';
import client9 from '../../asset/images/client/9.png';
import client10 from '../../asset/images/client/10.png';
// import client11 from '../../asset/images/client/11.WEBP';
import client12 from '../../asset/images/client/12.png';
import client13 from '../../asset/images/client/13.png';
import client14 from '../../asset/images/client/14.png';
import client15 from '../../asset/images/client/15.png';
import client16 from '../../asset/images/client/16.png';
// import client17 from '../../asset/images/client/17.webp';
import client18 from '../../asset/images/client/18.png';
import client19 from '../../asset/images/client/19.png';
// import client20 from '../../asset/images/client/20.png';
// import client21 from '../../asset/images/client/21.png';
import client22 from '../../asset/images/client/22.png';
import client23 from '../../asset/images/client/23.png';
// import client24 from '../../asset/images/client/24.png';
import client25 from '../../asset/images/client/25.png';
import clientA from '../../asset/images/client/A.png';
import clientB from '../../asset/images/client/B.png';
import clientC from '../../asset/images/client/C.png';
import clientD from '../../asset/images/client/D.png';
import clientE from '../../asset/images/client/E.png';
import clientF from '../../asset/images/client/F.png';
import clientG from '../../asset/images/client/G.png';
import clientH from '../../asset/images/client/H.png';
import clientI from '../../asset/images/client/I.png';

class Client extends Component {
  render() {
    var settings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 1000,          // Transition speed (1 second)
      slidesToShow: 6,
      slidesToScroll: 6,
      autoplay: true,
      autoplaySpeed: 3000,  // Delay between slide transitions (5 seconds)
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <Fragment>

        <Container className='section-bottom' >
          {/* <h1 className='mainTitle text-center mb-5'><strong className='spancolor'>Trusted</strong> By:</h1> */}
          <Slider {...settings} style={{ backgroundColor: "#fff" }}>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={clientA} loading="lazy" /></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={clientB} loading="lazy" /></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={clientC} loading="lazy" /></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={clientD} loading="lazy" /></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={clientE} loading="lazy" /></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={clientF} loading="lazy" /></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={clientG} loading="lazy" /></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={clientH} loading="lazy" /></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={clientI} loading="lazy" /></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={client1} loading="lazy" /></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={client3} loading="lazy" /></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={client4} loading="lazy" /></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={client7} loading="lazy" /></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={client9} loading="lazy" /></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={client10} loading="lazy" /></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={client12} loading="lazy" /></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={client13} loading="lazy" /></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={client14} loading="lazy" /></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={client15} loading="lazy" /></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={client16} loading="lazy" /></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={client18} loading="lazy" /></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={client22} loading="lazy" /></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={client23} loading="lazy" /></a>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <a> <img className="clientImage" src={client25} loading="lazy" /></a>
                </Col>
              </Row>
            </div>
          </Slider>


        </Container>
      </Fragment>
    );
  }
}

export default Client;