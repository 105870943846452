import { useState } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
// import banner from '../../asset/images/quote/campain-mobile-design.webp';
import banner from '../../asset/images/quote/offer-payroll-mobile.jpg';
import Modal from 'react-bootstrap/Modal';

const Campain = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Container fluid className='campain mt-2'>
                <Row>
                    <Col lg={12} md={12} sm={12} >
                        <img className="img-fluid" src={banner} />
                    </Col>
                    <Col lg={12} md={12} sm={12} >
                        {/* <div className='text-center'>
                            <Button className='terms-conditions mt-2' onClick={handleShow}>
                                *<span style={{ textDecoration: "underline" }}>Some Conditions May Apply</span>
                            </Button>

                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton style={{ backgroundColor: "#fff" }}>

                                </Modal.Header>
                                <Modal.Body>
                                    <div className='condition-card2'>
                                        <p className='common-p' style={{ color: "#292929" }}>Complete the form or dial 1-866-315-8822 to discover the expertise of VPM payroll professionals.</p>
                                        <h2 className='common-title2'>
                                            Free Tablet Offer<span style={{ color: "red" }}>*</span>
                                        </h2>
                                        <p className='ConditionDesc' >
                                            <b>Eligibility requirements to apply for the promotion include:</b> </p>
                                        <ol className='ConditionDesc pl-3'>
                                            <li>Must be a Canadian business with a minimum of 5 employees per payroll.</li>
                                            <li>Agrees to work with VPM for a minimum 1 year.</li>
                                            <li>Must request a quote online and switch/start to/with VPM for payroll.</li>
                                            <li>Have to start before the campaign deadline; which is <span style={{ color: "#1CA9AB" }}><b>February 29th, 2024.</b></span>
                                            </li>
                                        </ol>

                                        <p className='ConditionDesc'>After 3 months of continuous service, a tablet will be sent to the business address provided. The brand of tablet is dependent upon supply available, businesses can expect to receive a brand-new Lenovo, Samsung or Acer tablet.</p>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div> */}
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Campain;