import React from 'react';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import BookDemosucessMsg from '../components/QuotationSend/BookDemosucessMsg';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';

const BookDemoSendPage = () => {
    return (
        <>
           <TopNavigation title="Free Book Demo Request Success | V-TAC Payroll Management"/>
           <BookDemosucessMsg />
           <TradePart />
           <Footer />
            
        </>
    );
};

export default BookDemoSendPage;