import React from 'react';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import IndustryExpertise from '../components/Banner/IndustryExpertise';
import AnualSummary from '../components/AnualSummary/AnualSummary';
import IndustryServe from '../components/IndustryServe/IndustryServe';

import banner from '../../src/asset/images/industry/hospitality-banner.png';
import SliderComp from '../components/Projects/Slider';
import OfferQuote from '../components/OfferQuote/OfferQuote';
import Notification from '../components/TopNotification/TopNotification';
import CommonFaq from '../components/FAQ/CommonFaq';
import ContractCommitment2 from '../components/IndustryServe/ContractCommitment2';
import Reception from '../components/IndustryServe/Reception';

import { BsWallet } from "react-icons/bs";
import { LuAlarmClock } from "react-icons/lu";
import { IoMdContacts } from "react-icons/io";
import { BsGraphUpArrow } from "react-icons/bs";
import { FiCheckSquare } from "react-icons/fi";
import { IoCalculator } from "react-icons/io5";

import reception from '../../src/asset/images/industry/Industry_003.webp';

const HospitalityIndustryPage = () => {
    const imageData = {
        imageUrl: banner,
        caption: '',
        title: 'Simplify your payroll. Amplify your service.',
        paragraph: 'Experience effortless payroll management that lets you skip the complexities, giving you more time to focus on your customers.'

    };
    const DataInfo = {
        caption: '100% Canadian',
        title: 'We work with the Hospitality industry',
        subTitle1: 'Effortless payroll is in the heart of hospitality',

        icon1: <BsWallet size={35} color='#FF135A' />,
        p1: "In the bustling world of hospitality, speed and efficiency are paramount. VPM's quick and straightforward payroll processing means you can keep up with the high turnover and fast-paced demands of your industry. Experience a payroll system that moves as swiftly as your business does.",

        icon2: <LuAlarmClock size={35} color='#FF135A' />,
        subTitle2: 'Round-the-clock support',
        p2: "In hospitality, every minute counts. That's why VPM offers easy and immediate access to support. Our expert team is just a call or click away, ready to assist you with any payroll queries, ensuring you're never left in the lurch during those crucial business moments.",

        icon3: <IoMdContacts size={35} color='#FF135A' />,
        subTitle3: 'Empower your staff with necessary self-service tools',
        p3: "Reduce the load on your management and HR teams with our Employee Self-Service (ESS) portal. Employees can view their pay stubs, T4s, and manage their personal information, enhancing efficiency and reducing the need for time-consuming interactions.",

        icon4: <BsGraphUpArrow size={35} color='#FF135A' />,
        subTitle4: 'Grow your team without any added costs',
        p4: "Expanding your team shouldn't mean expanding your expenses. With VPM, there's no fee for adding new employees to your payroll system. This feature is ideal for the high-turnover nature of the hospitality industry, ensuring scalability and cost-effectiveness.",

        icon5: <FiCheckSquare size={35} color='#FF135A' />,
        subTitle5: "On-time ROEs for seamless and timely employee transitions",
        p5: "VPM ensures your Record of Employment (ROE) documents are completed accurately and uploaded directly to Service Canada on time. This reliability is vital for maintaining compliance and smooth transitions in an industry known for its dynamic workforce.",

        icon6: <IoCalculator size={35} color='#FF135A' />,
        subTitle6: "Automate statutory pay calculations with ease",
        p6: "Statutory pay calculations can be complex, but not with VPM. Our system automatically calculates stat pay, simplifying one of the most intricate aspects of payroll. It's just another way we tailor our services to the unique rhythm of your business.",
    };

    const Receptiondata = {
        heading: "Ready to begin your hassle-free payroll journey?",
        text: "Starting with VPM is a breeze. Our team will guide you every step of the way, ensuring a seamless integration of our payroll services into your hospitality business.",
        picture: reception,
    };

    return (
        <>
            <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
            <TopNavigation title="Dental Offices | V-TAC Payroll Management" />
            <IndustryExpertise data={imageData} />
            <AnualSummary marginTop="-.1rem" />
            <IndustryServe data={DataInfo} />
            <ContractCommitment2 />
            <SliderComp />
            <OfferQuote />
            <CommonFaq />
            <Reception data={Receptiondata} />
            <TradePart />
            <Footer />
        </>
    );
};

export default HospitalityIndustryPage;