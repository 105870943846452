import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import mtroyal4 from '../../asset/images/ClientSuccessStory/mt-royal-4.jpg';
import mtroyal5 from '../../asset/images/ClientSuccessStory/mt-royal-5.jpg';


class MountRoyalDentalsec2 extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='section-gap nexasStaffingContainer ' >
                    <div>
                        <Container>
                            <Row>
                                <Col lg={6} md={6} sm={12}>

                                    <h3 className='commonTitle mt-3'>It was time for a <span className='spancolor'>change</span></h3>
                                    <p className='desc'>In 2010, Dr. Sam decided to act on an advertisement he received from VPM and arranged to meet with the sales team to learn more. Not long after, VPM was selected to replace ADP.</p>
                                    <p className='desc'><Link to="/"><span className='faqLink'>​VPM</span></Link> offered free onboarding to Mount Royal Dental, and onboarding was said to be “very easy”.</p>
                                    <p className='desc'>Dr. Sam and his team started using VPM in no time at all.</p>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="nexus1Img" src={mtroyal4} alt='Mount Royal Dental' title='Mount Royal Dental' />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="nexus1Img mt-5" alt='Mount Royal Dental' title='Mount Royal Dental' src={mtroyal5} />
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <h3 className='commonTitle mt-5'>Finding a reliable partner in <span className='spancolor'>VPM</span></h3>
                                    <p className='desc'>As he looks back, Dr. Sam is pleased with his ongoing partnership with VPM.</p>
                                    <p className='desc'>“On the business side, fees are reasonable compared to other providers, services are outstanding, and the relationship has been strong. What else can anyone else ask for?”</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default MountRoyalDentalsec2;