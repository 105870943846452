import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import customreport from '../../asset/images/industry/Dental.webp';

const Payrollsolutions = () => {
    return (
        <>
            <Container className='section-gap'>
                <Row>
                    <Col lg={5} md={6} xs={12} >
                        <img
                            src={customreport}
                            alt="custom report"
                            className="report-img img-fluid pr-5 mb-3"
                        />
                    </Col>
                    <Col lg={7} md={6} xs={12}>
                        <h2 className='common-title'>Payroll solutions tailored to your unique needs</h2>
                        <p className='common-p mt-3' style={{ color: "#686B72", lineHeight: " 312.5% " }}>In the dynamic world of dental care, managing payroll can be as intricate as the procedures you perform. From accommodating varied pay rates for hygienists, administrative staff, and dental associates to managing irregular and on-call workers, your payroll demands flexibility and precision. At VPM, we recognize these unique challenges and are dedicated to offering solutions that let you focus on what you do best - caring for your patients.
                        </p>
                    </Col>
                </Row>

            </Container>

        </>
    );
};

export default Payrollsolutions;