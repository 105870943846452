import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import ReCAPTCHA from "react-google-recaptcha";
// import banner from '../../asset/images/quote/offer.webp';
import banner from '../../asset/images/quote/offer-payroll.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';

class FreeQuote extends Component {

    constructor() {
        super();
        this.state = {
            fullName: "",
            company: "",
            phone: "",
            email: "",
            numberOfEmployees: "",
            postalCode: "",
            message: "",
            industry: "",
            hearFrom: "",
            errors: {},
            isVarified: false,
            recapvalue: "",
            show: false
        }

    }

    //  modal/ pop up for terns and condition
    handleClose = () => {
        this.setState({ show: false });
    };

    handleShow = () => {
        this.setState({ show: true });
    };

    //racapcha function
    recapchaValue = (value) => {
        const recapvalue = value;
        console.log("Captcha value:", recapvalue);
        this.setState({ recapvalue: value });
        this.setState({ isVarified: true });
    }
    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        //this.setState({isVarified:true});

    }
    clearForm = () => {
        this.setState({
            fullName: "",
            company: "",
            phone: "",
            email: "",
            numberOfEmployees: "",
            payperperiod: "",
            province: "",
            postalCode: "",
            hearFrom: "",
            industry: "",
            errors: {},
            isVarified: false,
        })
    }

    componentDidMount() {
        AOS.init();
        document.getElementById("div1").style.display = "block";
        // document.getElementById("RQForm").style.display = "block";
        // document.getElementById("SuccessMSgDiv").style.display = "none";
    }

    submitHandaler = (event) => {
        event.preventDefault();
        //console.log(this.state)

        const isValid = this.Validate();
        if (isValid == true) {

            axios.post("https://api.vtacpayroll.ca/create", this.state).then(Response => {
                // axios.post("http://localhost:5000/create", this.state).then(Response => {
                this.clearForm();
                window.location.href = "https://www.vtacpayroll.ca/request-payroll-success";
                // window.location.href = "http://localhost:3000/request-payroll-success";
                // document.getElementById("RQForm").style.display = "none";
                // document.getElementById("SuccessMSgDiv").style.display = "block";

            }).catch(error => {
                console.log(error);
            })
        }
    }

    Validate = () => {
        const errors = {};
        //let regexp = /(?!.*[DFIOQU-])[A-VXY][0-9][A-Z][' '] ?[0-9][A-Z][0-9]/;
        let isValid = true;
        const { fullName, company, phone, email, numberOfEmployees, payperperiod, province, postalCode, hearFrom } = this.state;
        if (fullName == "") {
            errors.fullNameEmpty = "Please provide your Name.";
            isValid = false;
        }
        if (company == "") {
            errors.companyEmpty = "Please provide your company Name.";
            isValid = false;
        }
        if (phone == "" || phone.length != 10) {
            errors.phoneEmpty = "Please provide your valid Phone Number";
            isValid = false;
        }
        if (email == "") {
            errors.emailEmpty = "Please provide your Email Address.";
            isValid = false;
        }
        if (numberOfEmployees == "" || parseInt(numberOfEmployees, 10) > 999 || parseInt(numberOfEmployees, 10) < 1) {
            errors.numberOfEmployeesEmpty = "Please provide employees (1-999)";
            isValid = false;
        }
        if (payperperiod == undefined || payperperiod == "") {
            errors.payperperiodEmpty = "Please provide your Pay Period Frequency.";
            isValid = false;
        }

        if (province == undefined || province == "") {
            errors.provinceEmpty = "Please provide your Province.";
            isValid = false;
        }
        let trimmedPostalCode = postalCode.trim();
        var code = trimmedPostalCode.replace(/\s/g, '')
        var regex = /^[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d$/;
        if (!regex.test(code)) {
            errors.postalCodevarify = "Please provide your valid Postal Code.";
            isValid = false;
        }
        if (hearFrom == "" || hearFrom == undefined) {
            errors.hearFromEmpty = "Please provide How'd you hear about us ?.";
            isValid = false;
        }
        // if (industry == "") {
        //     errors.industryEmpty = "Please provide your industry name.";
        //     isValid = false;
        // }
        this.setState({ errors });
        return isValid;
    }



    render() {
        const { fullName, company, phone, email, numberOfEmployees, payperperiod, province, postalCode, hearFrom, industry, errors } = this.state;
        return (
            <Fragment>
                <Container fluid className='quote-container'>
                    <Container>
                        <Row>
                            <Col sm={12} md={8} lg={8}>
                                {/* <div data-aos="fade-down"
                                    data-aos-easing="linear"
                                    data-aos-duration="1000"
                                    data-aos-once="true">
                                    <h1 className='quote-title'>
                                        Free quote
                                    </h1>

                                </div> */}
                                <div className="homeQuoteCard mt-5 ">

                                    <div className='mt-3' id='div1'>
                                        <div id="RQForm" data-aos="fade-left"
                                            data-aos-anchor="#example-anchor"
                                            data-aos-offset="500"
                                            data-aos-duration="2000">
                                            <h2 className='quote-title text-center'>
                                                Free Quote
                                            </h2>
                                            <div className='quote-heading-div'>
                                                <h2 className='quote-heading'>Get your customized payroll quote</h2>
                                                <p > Experience how VPM can streamline your payroll process effectively and affordably.
                                                    Fill in a few details and receive a no-obligation quote that aligns with your specific requirements.</p>
                                            </div>
                                            <Container style={{ backgroundColor: "#252E44" }}>
                                                <h3 className="quoteCardTitle pt-5 mb-5">Free Quote request form</h3>

                                                <Form onSubmit={this.submitHandaler}>

                                                    <Row>
                                                        <Col lg={6} className="mb-3">

                                                            <Form.Group>
                                                                <Form.Label>Your Name<span style={{ color: "red" }}>*</span></Form.Label>
                                                                <Form.Control type="text" title='Full Name' name='fullName' value={fullName} onChange={this.handleChange} />
                                                                <span style={{ color: 'red' }}>{errors.fullNameEmpty}</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={6} className="mb-3">
                                                            <Form.Group>
                                                                <Form.Label>Email<span style={{ color: "red" }}>*</span></Form.Label>
                                                                <Form.Control type="email" title='Email Address' name='email' value={email} onChange={this.handleChange} />
                                                                <span style={{ color: 'red' }}>{errors.emailEmpty}</span>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={6} className="mb-3">

                                                            <Form.Group>
                                                                <Form.Label>Company Name<span style={{ color: "red" }}>*</span></Form.Label>
                                                                <Form.Control type="text" title='Company' name='company' value={company} onChange={this.handleChange} />
                                                                <span style={{ color: 'red' }}>{errors.companyEmpty}</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <Form.Group>
                                                                <Form.Label>Postal Code<span style={{ color: "red" }}>*</span></Form.Label>
                                                                <Form.Control type="text" name='postalCode' value={postalCode} onChange={this.handleChange} isInvalid={!!errors.state} />
                                                                <span style={{ color: 'red' }}>{errors.postalCodevarify}</span>
                                                            </Form.Group>
                                                        </Col>

                                                    </Row>
                                                    <Row >
                                                        <Col lg={6} className="mb-3">
                                                            <Form.Group>
                                                                <Form.Label>Province<span style={{ color: "red" }}>*</span></Form.Label>
                                                                <select className='quoteDropdown' name='province' value={province} onChange={this.handleChange}>
                                                                    <option value={""}>select</option>
                                                                    <option value={'Alberta'}>Alberta</option>
                                                                    <option value={'British Columbia'}>British Columbia</option>
                                                                    <option value={'Manitoba'}>Manitoba</option>
                                                                    <option value={'New Brunswick'}>New Brunswick</option>
                                                                    <option value={'Newfoundland and Labrador'}>Newfoundland and Labrador</option>
                                                                    <option value={'Nova Scotia'}>Nova Scotia</option>
                                                                    <option value={'Northwest Territories'}>Northwest Territories</option>
                                                                    <option value={'Nunavut'}>Nunavut</option>
                                                                    <option value={'Ontario'}>Ontario</option>
                                                                    <option value={'Prince Edward Island'}>Prince Edward Island</option>
                                                                    <option value={'Quebec'}>Quebec</option>
                                                                    <option value={'Saskatchewan'}>Saskatchewan</option>
                                                                    <option value={'Yukon'}>Yukon</option>
                                                                </select>
                                                                <span style={{ color: 'red' }}>{errors.provinceEmpty}</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={6} className="mb-3">
                                                            <Form.Group>
                                                                <Form.Label>Phone<span style={{ color: "red" }}>*</span></Form.Label>
                                                                <Form.Control className='formLebel' type="number" name='phone' value={phone} onChange={this.handleChange} />
                                                                <span style={{ color: 'red' }}>{errors.phoneEmpty}</span>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col lg={6} className="mb-3">
                                                            <Form.Group>
                                                                <Form.Label>Number of Employees<span style={{ color: "red" }}>*</span></Form.Label>
                                                                <Form.Control type="number" min={0} name='numberOfEmployees' value={numberOfEmployees} onChange={this.handleChange} />
                                                                <span style={{ color: 'red' }}>{errors.numberOfEmployeesEmpty}</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={6} className="mb-3">
                                                            <Form.Group >
                                                                <Form.Label>Pay Period Frequency<span style={{ color: "red" }}>*</span></Form.Label>
                                                                <select className='quoteDropdown' name='payperperiod' value={payperperiod} onChange={this.handleChange} >
                                                                    <option value={""}>select</option>
                                                                    <option value={'WEEKLY'}>WEEKLY</option>
                                                                    <option value={'SEMI-MONTHLY'}>SEMI-MONTHLY</option>
                                                                    <option value={'BI-WEEKLY'}>BI-WEEKLY</option>
                                                                    <option value={'MONTHLY'}>MONTHLY</option>
                                                                </select>
                                                                <span style={{ color: 'red' }}>{errors.payperperiodEmpty}</span>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={6} className="mb-3">
                                                            <Form.Group>
                                                                <Form.Label>How'd you hear about us ?<span style={{ color: "red" }}>*</span></Form.Label>
                                                                <select className='quoteDropdown' name='hearFrom' value={hearFrom} onChange={this.handleChange}>
                                                                    <option value={""}>select</option>
                                                                    <option value={"Google Search"}>Google Search</option>
                                                                    <option value={"Social Media"}>Social Media</option>
                                                                    <option value={"Magazine Ad"}>Magazine Ad</option>
                                                                    <option value={"Word Of Mouth"}>Word Of Mouth</option>
                                                                    <option value={"Promotional Calender"}>Promotional Calender</option>
                                                                    <option value={"Others"}>Others</option>
                                                                </select>
                                                                <span style={{ color: 'red' }}>{errors.hearFromEmpty}</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={6} className="mb-3">
                                                            <Form.Group >
                                                                <Form.Label>Industry</Form.Label>
                                                                <Form.Control className='formLebel' type="text" name='industry' value={industry} onChange={this.handleChange} />
                                                                <span style={{ color: 'red' }}>{errors.industryEmpty}</span>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                        <Form.Check type="checkbox" defaultChecked={true} className='checkboxForm' label="Yes, I want to receive VPM info, industry news and promotions from V-TAC Payroll Management by email. You can change your preferences or unsubscribe from our contact list at any time." />
                                                    </Form.Group>
                                                    <div className='m-2' style={{ width: "50%" }}>
                                                        <ReCAPTCHA
                                                            // vtacpayroll.ca
                                                            sitekey="6LdW8FcjAAAAABvBHj1pzxWTOu3vqp4Hd5UIr9jn"
                                                            // vtacpayrol.com
                                                            //sitekey="6LewqFUjAAAAADXJfTyYT40ysEYNHl6eX3fZbf9L"
                                                            onChange={this.recapchaValue}
                                                            className="g-recaptcha"
                                                        />
                                                    </div>
                                                    {/* <div>
                                                        <p className='offer-text mt-3'> <span className='imoj'>👉</span><span>VPM brings you a special offer this season! You get a free Tablet when you switch to VPM !*</span></p>
                                                    </div> */}
                                                    <Button name='submitBtn' className="RequestQuoteBtn mt-3 mb-5" variant="primary" type="submit" disabled={!this.state.isVarified}>
                                                        <span>GET QUOTE</span>
                                                    </Button>
                                                </Form>
                                            </Container>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4}>
                                <div data-aos="fade-up"
                                    data-aos-anchor="#example-anchor"
                                    data-aos-offset="500"
                                    data-aos-duration="2000">
                                    <img className="img-fluid com-side mt-5" style={{ boxShadow: "3px 6px 21px 0px rgba(0, 0, 0, 0.15)", borderRadius: "5px" }} src={banner} />
                                </div>

                                {/* <a className='text-center common-p2 mt-2' style={{ color: '#D1242A', textDecoration: "underline" }}><strong>*Terms and Conditions</strong></a> */}
                                {/* <div className='terms ml-4' >
                                    <Button className='terms-conditions mt-2' onClick={this.handleShow}>
                                        *<span style={{ textDecoration: "underline" }}>Some Conditions May Apply</span>
                                    </Button>

                                    <Modal show={this.state.show} onHide={this.handleClose}>
                                        <Modal.Header closeButton style={{ backgroundColor: "#fff" }}>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className='condition-card2'>
                                                <p className='common-p' style={{ color: "#292929" }}>Complete the form or dial <strong>1-866-315-8822</strong> to discover the expertise of VPM payroll professionals.</p>
                                                <h2 className='common-title2'>
                                                    Free Tablet Offer<span style={{ color: "red" }}>*</span>
                                                </h2>
                                                <p className='ConditionDesc' >
                                                    <b>Eligibility requirements to apply for the promotion include:</b> </p>
                                                <ol className='ConditionDesc pl-3'>
                                                    <li>Must be a Canadian business with a minimum of 5 employees per payroll.</li>
                                                    <li>Agrees to work with VPM for a minimum 1 year.</li>
                                                    <li>Must request a quote online and switch/start to/with VPM for payroll.</li>
                                                    <li>Have to start before the campaign deadline; which is <span style={{ color: "#1CA9AB" }}><b> 31st March, 2024.</b></span>
                                                    </li>
                                                </ol>

                                                <p className='ConditionDesc'>After 3 months of continuous service, a tablet will be sent to the business address provided. The brand of tablet is dependent upon supply available, businesses can expect to receive a brand-new Lenovo, Samsung or Acer tablet.</p>
                                            </div>

                                        </Modal.Body>
                                    </Modal>
                                </div> */}
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Fragment>
        );
    }
}

export default FreeQuote;