import React from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MdChevronRight } from "react-icons/md";
const GetStartedVPM = () => {
    return (
        <>
            <Container fluid style={{ backgroundColor: "#D5EAFF" }} >
                <Container className='text-center'>
                    <Row>
                        <Col lg={12} md={12} xs={12} className='mt-5'>
                            <h2 className='common-title2'>Get started with VPM</h2>
                            <div className='container' style={{width:"85%"}}>
                                Embarking on your journey with VPM is straightforward. Our team is here to guide you through each step, ensuring a smooth transition to a more efficient payroll system. Let us handle the complexities of payroll while you dedicate your time to your patients and practice.
                            </div>
                            <p className='common-p mt-3'>

                            </p>
                            <div>
                                <Link to="/request-payroll-quote"><Button className='quote-btn-banner mt-3 mb-5'><span>Free Quote</span> <MdChevronRight /></Button>{' '}</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
};

export default GetStartedVPM;