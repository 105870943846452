import React, { Fragment } from 'react';
// import { Helmet } from 'react-helmet-async';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import QuotationSend from '../components/QuotationSend/QuotationSend';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';

const QuotationSendPage = () => {

    return (
        <Fragment>
            <TopNavigation title="Request Success | V-TAC Payroll Management" />
            <QuotationSend />
            <TradePart />
            <Footer />
            {/* Tracking Image */}
            {/* <img
                src="https://ct.capterra.com/capterra_tracker.gif?vid=2238296&vkey=b28ff7399e557fe5f04e9fb99c245582"
                alt="Tracking Image"
                style={{ display: 'none' }}
            /> */}
        </Fragment>
    );
}

export default QuotationSendPage;