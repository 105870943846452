import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const IndustryServe2 = (props) => {
    const { caption, title, subTitle1, subTitle2, subTitle3, subTitle4, subTitle5, p1, p2, p3, p4, p5, icon1, icon2, icon3, icon4, icon5, picture } = props.data
    return (
        <>
            <Container className='section-gap'>
                <p className='common-p3 text-center'>
                    <b>{caption}</b>
                </p>
                <h2 className='common-title2 text-center'>
                    {title}
                </h2>
                <Row className='section-gap'>
                    <Col lg={4} md={6} xs={12}>
                        <div className='serve-img text-center'>{icon1}</div>
                        <p>
                            <span className='count-line' style={{ color: "#FF1F3B" }}>01</span >
                            <span className='count-line' style={{ color: "#BFBFBF" }}>/05</span>
                        </p>
                        <p className='common-p5'>
                            {subTitle1}
                        </p>

                        <p className='common-p4 mt-3' style={{ textAlign: "justify", paddingRight: "1rem" }}>
                            {p1}
                        </p>
                    </Col>
                    <Col lg={4} md={6} xs={12}>
                        <div className='serve-img text-center'>{icon2}</div>
                        <p>
                            <span className='count-line' style={{ color: "#FF1F3B" }}>02</span >
                            <span className='count-line' style={{ color: "#BFBFBF" }}>/05</span>
                        </p>
                        <p className='common-p5'>
                            {subTitle2}
                        </p>

                        <p className='common-p4 mt-3' style={{ textAlign: "justify", paddingRight: "1rem" }}>
                            {p2}
                        </p>
                    </Col>
                    <Col lg={4} md={6} xs={12}>
                        <div className='serve-img text-center'>{icon3}</div>
                        <p>
                            <span className='count-line' style={{ color: "#FF1F3B" }}>03</span >
                            <span className='count-line' style={{ color: "#BFBFBF" }}>/05</span>
                        </p>
                        <p className='common-p5'>
                            {subTitle3}
                        </p>

                        <p className='common-p4 mt-3' style={{ textAlign: "justify", paddingRight: "1rem" }}>
                            {p3}
                        </p>
                    </Col>

                    <Col lg={4} md={6} xs={12}>
                        <div className='serve-img text-center mt-5'>{icon4}</div>
                        <p>
                            <span className='count-line' style={{ color: "#FF1F3B" }}>04</span >
                            <span className='count-line' style={{ color: "#BFBFBF" }}>/05</span>
                        </p>
                        <p className='common-p5'>
                            {subTitle4}
                        </p>

                        <p className='common-p4 mt-3' style={{ textAlign: "justify", paddingRight: "1rem" }}>
                            {p4}
                        </p>
                    </Col>

                    <Col lg={4} md={6} xs={12}>
                        <div className='serve-img text-center mt-5 '>{icon5}</div>
                        <p>
                            <span className='count-line' style={{ color: "#FF1F3B" }}>05</span >
                            <span className='count-line' style={{ color: "#BFBFBF" }}>/05</span>
                        </p>
                        <p className='common-p5'>
                            {subTitle5}
                        </p>

                        <p className='common-p4 mt-3' style={{ textAlign: "justify", paddingRight: "1rem" }}>
                            {p5}
                        </p>
                    </Col>
                    <Col lg={4} md={6} xs={6} style={{ marginBottom: "5rem" }}>
                        <div className='work-with-img p-2 section-gap'>
                            <img src={picture} style={{ width: "100%",height:"auto"}} />
                        </div>

                    </Col>
                    <div style={{ marginBottom: "6rem" }}></div>
                </Row>
            </Container>
        </>
    );
};

export default IndustryServe2;