import React from 'react';
import Notification from '../components/TopNotification/TopNotification';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import PartnerProgramForm from '../components/Accountants/PartnerProgramForm';
import AllClient from '../components/AllClient/AllClient';
import Footer from '../components/Footer/Footer';
import AccBanner from '../components/Accountants/AccBanner';
import AnualSummary from '../components/AnualSummary/AnualSummary';
import LeavePayrollToUs from '../components/Accountants/LeavePayrollToUs';
import PertnershipEnhance from '../components/Accountants/PertnershipEnhance';
import { Element } from 'react-scroll';
import DetailsBenifit from '../components/Accountants/DetailsBenifit';

const AccountantsPage = () => {

    return (
        <>
            <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
            <TopNavigation title="Accountants | V-TAC Payroll Management " />
            <AccBanner />
            <AnualSummary />
            <LeavePayrollToUs />
            <DetailsBenifit />
            <PertnershipEnhance />
            <Element name="accountantForm">
                <PartnerProgramForm />
            </Element>
            <AllClient />
            <div className='mt-5'></div>
            <Footer />

        </>
    );
};

export default AccountantsPage;