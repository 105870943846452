import React from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import banner from '../../asset/images/accountant/001.jpg';
import { MdChevronRight } from "react-icons/md";
import { Link as ScrollLink } from 'react-scroll';

const PertnershipEnhance = () => {
    return (
        <>
            <Container fluid style={{ backgroundColor: "#EDF5FF" }}>
                <Container className=''>
                    <Row className=''>
                        <Col lg={12} md={12} xs={12}>
                            <p className='common-p7 section-gap text-center' style={{ color: "#FF135A" }}>
                                Join VPM’s Network of Trusted Accountants
                            </p>
                            <h2 className='common-title text-center' style={{ fontSize: "2.8rem" }}>
                                A Partnership that enhances your practice
                            </h2>
                            <p className='common-p7 text-center mt-3' style={{color:"#6B6B6B"}}>
                                Joining forces with VPM is the first step to enhancing your accounting services. Our team<br></br> is ready to guide you through the process, ensuring a smooth partnership that benefits<br></br> both you and your clients.
                            </p>
                        </Col>

                    </Row>

                </Container>
                <Row>
                    <Col>
                        <Col lg={12} md={12} xs={12}>
                            <img src={banner} className='float-end section-gap' style={{ width: "100%", height: "auto" }} alt="quote banner" title='' fluid
                            />
                            <div className='text-center '>
                                <Button className='t4-btn-banner mt-5 mb-5'>
                                    <ScrollLink
                                        activeClass="active"
                                        to="accountantForm"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={500}
                                        className='t4-btn-banner'
                                    >
                                        Start today <MdChevronRight />
                                    </ScrollLink>
                                </Button>{' '}
                            </div>
                        </Col>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default PertnershipEnhance;