import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import global1 from '../../asset/images/ClientSuccessStory/global-1.jpg';
import global2 from '../../asset/images/ClientSuccessStory/global-2.jpg';
import global3 from '../../asset/images/ClientSuccessStory/global-3.jpg';
import { FcLeft } from "react-icons/fc";

class GlobalImagingSec1 extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Link to='/client-success-stories'>
                        <p className='common-p2 mt-3' style={{ textDecoration: "underline" }}><FcLeft size={27} /> Success Stories</p>
                    </Link>
                </Container>
                <Container fluid={true} className='nexasStaffingContainer' >
                    <div className='pt-5'>
                        <Container>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="nexus3Img" alt='Global Imaging' title='Global Imaging' src={global1} />
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <h3 className='commonTitle'>A bit about <span className='spancolor'>Global Imaging</span></h3>
                                    <p className='desc'>Established in 2002, <span><a className='faqLink' href='https://globalimaging.ca/' target='_blank'>Global Imaging</a></span> brings 120 years of collective experience and modern technology to the table for custom large-format graphics. They do everything from design, production, assembly and distribution. They have also been called one of the best in the business.</p>
                                    <p className='desc'>Global Imaging wasn’t sure they had a problem so much as an opportunity to explore outsourcing payroll in order to save time or money and to get help keeping up with changing legislation.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12}>

                                    <h3 className='commonTitle mt-5'>Deciding to <span className='spancolor'>outsource payroll</span></h3>
                                    <p className='desc'>“We saw an ad and decided to meet with VPM. They made a great case that we would be better off if we outsourced our payroll. The fees looked good and we decided to give them a chance,” said Gerald Maister, Administration, Sales & Marketing.</p>
                                    <p className='desc'>​Global Imaging switched and joined <Link to="/"><span className='faqLink'>VPM</span></Link> in 2010. </p>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="nexus1Img mt-5" alt='Global Imaging' title='Global Imaging' src={global2} />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="nexus3Img mt-5" alt='Global Imaging' title='Global Imaging' src={global3} />
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <h3 className='commonTitle section-gap'>A happy customer for  <span className='spancolor'>12 years</span></h3>
                                    <p className='desc'>“VPM is very reliable and does an outstanding job. Payroll is always done on time.</p>
                                    <p className='desc'>VPM also gets back right away with answers to any questions we have, such as any changes to statutory requirements.</p>
                                    <p className='desc'>​Our point of contact payroll consultant does a good job for us, she’s extremely good.</p>
                                    <p className='desc'>Overall we’ve been very happy,” said Gerald. </p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default GlobalImagingSec1;