import React, { useEffect } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import AppRoute from './router/AppRoute';
import TopUpButton from './components/TopNotification/TopUpButton';

const App = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <div>
      <Router>
        <TopUpButton />
        <AppRoute />
      </Router>
    </div>
  );
};

export default App;
