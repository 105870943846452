import React from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import bannerImg from '../../asset/images/T4/0011.webp';
import { Link } from 'react-router-dom';
import { MdChevronRight } from "react-icons/md";
import { Link as ScrollLink } from 'react-scroll';
const T4Banner = () => {
    return (
        <>
            <Container fluid style={{ backgroundColor: "#FFEFEF" }}>
                <Container >
                    <Row >
                        <Col lg={7} md={6} xs={12} className='section-gap' >

                            <h1 className='common-title' style={{fontSize:"2.1rem"}}>
                                Need year-end tax forms to get done?
                                T4/T4A or else! We’ve got you.

                            </h1>
                            <p className='common-p4 mt-4 mb-4'>
                            Whether you are processing your payroll in house or getting it outsourced, VPM offers comprehensive year end tax form processing service including T4 and T4A. Get in touch with our consultant today, and we will take you through the journey.
                            </p>
                            <Link to="/year-end-payroll"><Button className='t4-btn-banner mt-4 mb-4'>
                                <ScrollLink
                                    activeClass="active"
                                    to="t4Form"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                    className='t4-btn-banner'
                                >
                                   Request T4 Quotation <MdChevronRight />
                                </ScrollLink>
                            </Button>{' '}</Link>
                        </Col>
                        <Col lg={5} md={6} xs={12}>
                            <img src={bannerImg} className='sub-banner-img3 float-end ' alt="quote banner" fluid
                            />
                        </Col>
                        <div style={{ marginBottom: "6rem" }}></div>
                    </Row>
                </Container>
            </Container>
        </>
    );
};

export default T4Banner;