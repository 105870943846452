import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import banner from '../../asset/images/career/career-banner.png';

const CareerBanner = () => {

    // componentDidMount = () => {
    //     window.scrollTo({ top: 0, behavior: 'smooth' });
    // }
    return (
        <>
            <Container fluid={true} style={{ backgroundColor: "#FFF8F8" }}>
                <Container>
                    <Row>
                        <Col lg={7} md={6} sm={6}>
                            <p className='common-p3 mt-5' style={{ color: "#E6001F" }}>
                                Career
                            </p>
                            <p className='common-title'>
                                Join our growing Team
                            </p>
                            <p className='common-p4 mb-4'>
                                At VPM we foster a culture of integrity, accountability, passion, simplicity, results, diversity, and humility in order to better serve our clients.
                            </p>
                            <p className='common-p4 mb-4'>
                                We hire talented and ambitious individuals who share our values and who thrive in growing entrepreneurial environments with lots of opportunities to learn.
                            </p>
                            <Link to="/request-payroll-quote"><Button className="nav-button mb-5" onClick={() => {
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}><span><strong>Free Quote</strong></span></Button>{' '}</Link>
                        </Col>
                        <Col lg={5} md={6} xs={6} className="text-center">
                            <img src={banner} className='career-banner-img  mt-5 mb-2' alt="quote banner" title='banner' fluid
                            />
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
};

export default CareerBanner;