import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { BiSolidQuoteAltLeft } from 'react-icons/bi';
import { TbPlayerPlayFilled } from 'react-icons/tb';
import { AiFillStar } from 'react-icons/ai';
import { GiStarShuriken } from 'react-icons/gi';
import styled from 'styled-components';
import star from '../../asset/images/home/client-star.svg';
import star2 from '../../asset/images/home/image 37.png';
const Project = (props) => {
    const { img, disc,clientName,clientCompany } = props.item;
    return (
        <Container className='project'>
            <Row>
                <Col>
                    <div className='icon'> <BiSolidQuoteAltLeft /></div>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col lg={12} md={12} xs={12}>
                    <Row>
                        <Col lg={3} md={12} xs={6} className='star ml-3'>
                            <AiFillStar style={{ color: "#04DA8D" }} size={15}/>
                            <AiFillStar style={{ color: "#04DA8D" }} size={15} />
                            <AiFillStar style={{ color: "#04DA8D" }} size={15} />
                            <AiFillStar style={{ color: "#04DA8D" }} size={15} />
                            <AiFillStar style={{ color: "#04DA8D" }} size={15} />
                        </Col>
                        {/* <Col lg={3} md={12} xs={6}  className='mr-4'>
                            <p className='sub-h'>
                                <GiStarShuriken style={{ color: "#000" }} size={12} /> Better Choice
                            </p>
                        </Col>
                        <Col lg={3} md={12} xs={6}  className='mr-5'>
                            <p className='sub-h'>
                                <GiStarShuriken style={{ color: "#000" }} size={12} /> Impressive service
                            </p>
                        </Col> */}
                    </Row>

                    <div className="disc">
                        <p>{disc}</p>
                        <h2 className='m-0'>{clientName} </h2>
                        <p className='designation'>{clientCompany}</p>
                    </div>
                </Col>
                {/* <Col lg={4} md={12} xs={6}>
                    <img src={img} alt="Testimonial" fluid />
                    <div className='client'>
                        <span><TbPlayerPlayFilled size={11} /></span> <span style={{ fontSize: "12px", color: "#008395" }}> See clients story</span>
                    </div>

                </Col> */}
            </Row>


        </Container>
    )
}

export default Project;

const Container = styled.div`
    height: 22rem;
    background-color: #C2F4FF;
    margin: 1rem 0.5rem;
    margin-bottom:5rem;
    padding: 0.5rem;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 1px 20px 40px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    img{
       // margin-top:2rem;
        width: 70%;
        height: auto;
        object-fit: cover;
        transition: transform 400ms ease-in-out;
    }
    .client{
        margin-top:.5rem;
        width: 8.5rem;
        height: auto;
        border-radius: 4px;
        padding:.3rem;
        border: 1px dashed #007686;
        background: #96EEFF;
    }
    .icon{
        font-size: 3rem;
       margin-top:-1.2rem;
        z-index:20000;
      
    }
    .sub-h{
        color: #008395;
        font-family: Onest;
        font-size: .5rem;
        font-style: normal;
        font-weight: 400;
    }
    .disc{
        // position: absolute;
           right: 0;
           left: 0;
          // bottom: -10rem;
           text-align: left;
           font-family: Onest;
           padding: 1rem;
        // background: linear-gradient(rgba(0,0,0, 0.100), rgba(0,0,0, 0.80));
        // transition: all 400ms ease-in-out;
        .designation{
            color: #6F878A;
            font-family: Onest;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }
        
        h2{
            font-size: 1rem;
            color:#008395;
            font-weight: 700;
            font-family:Onest;
        }
    
        p{
            width: 90%;
            font-size: 0.8rem;
        }
    }

    :hover > img{
        //transform: scale(1.3);
    }

    :hover > .disc{
        bottom: 0;
    }

`