import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import mtroyal1 from '../../asset/images/ClientSuccessStory/mt-royal-1.jpg';
import mtroyal2 from '../../asset/images/ClientSuccessStory/mt-royal-2.jpg';
import mtroyal3 from '../../asset/images/ClientSuccessStory/mt-royal-3.jpg';
import { Link } from 'react-router-dom';
import { FcLeft } from "react-icons/fc";

class MountRoyalDentalSec1 extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Link to='/client-success-stories'>
                        <p className='common-p2 mt-3' style={{ textDecoration: "underline" }}><FcLeft size={27} /> Success Stories</p>
                    </Link>
                </Container>
                <Container fluid={true} className='nexasStaffingContainer ' >
                    <div className='pt-5'>
                        <Container>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="nexus1Img mb-5" alt='Mount Royal Dental' title='Mount Royal Dental' src={mtroyal1} />
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <h3 className='commonTitle'>A bit about <span className='spancolor'>Mount Royal Dental</span></h3>
                                    <p className='desc'>For more than 13 years, <span><a className='faqLink' href='https://www.mountroyaldental.com/' target='_blank'>Mount Royal Dental</a></span> has been serving Burlington and its surrounding areas with admiration and distinction.</p>
                                    <p className='desc'>In recent years, Burlington Post’s Reader’s Choice Awards recognized their dental office in the best ‘best dentist’ category in 2016 (platinum), 2017 (platinum), and 2018 (diamond, topping the category of dentists). Mount Royal also supports local charities.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12}>

                                    <h3 className='commonTitle mt-5'><span className='spancolor'>Dr. Sam Gupta</span> shared:</h3>
                                    <p className='desc'>“When you meet our team, you will feel our commitment to customer service. Whether filling out forms or having even the most complex dental surgery, we strive to create a pleasant experience for all our patients.</p>
                                    <p className='desc'>Our goal is for each patient to walk out the door feeling that his or her expectations have been met and exceeded.”</p>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="nexus1Img mt-5" alt='Dr. Sam Gupta' title='Dr. Sam Gupta' src={mtroyal2} />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="nexus1Img mt-5" alt='Mount Royal Dental' title='Mount Royal Dental' src={mtroyal3} />
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <h3 className='commonTitle mt-5'>A <span className='spancolor'>complicated payroll system</span> was giving Dr. Sam headaches</h3>
                                    <p className='desc'>Mount Royal was using ADP as its payroll service provider but found their payroll services for dental offices a bit too tedious.</p>
                                    <p className='desc'>Dr. Sam had hopes of finding a payroll service provider that could both ‘make it happen’ and ‘keep it simple’.</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default MountRoyalDentalSec1;