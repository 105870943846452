import React from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import banner from '../../asset/images/accountant/005.webp';
import { Link } from 'react-router-dom';
import { MdChevronRight } from "react-icons/md";
import { Link as ScrollLink } from 'react-scroll';
const AccBanner = () => {
    return (
        <>
            <Container fluid style={{ backgroundColor: "#FFEFEF" }}>
                <Container className=''>
                    <Row className=''>
                        <Col lg={7} md={6} xs={12}>
                            <p className='common-p3 section-gap' style={{ color: "#FF135A" }}>
                                Payroll transformation starts here
                            </p>
                            <h1 className='banner-title' style={{fontSize:"2.8rem"}}>
                                Accountants trust VPM to elevate their clients’ payroll
                            </h1>
                            <p className='common-p4'>
                                Tackling payroll complexities can be daunting for accountants. It's not just about the time invested; it's the intricacies and compliance that come with it.
                            </p>
                            <p className='common-p4 mb-5'>
                                VPM expertly manages these complexities so you can focus on strategic financial advising and core accounting functions.
                            </p>
                            <Button className='t4-btn-banner'>
                                <ScrollLink
                                    activeClass="active"
                                    to="accountantForm"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                    className='t4-btn-banner'
                                >
                                    Get started  <MdChevronRight />
                                </ScrollLink>
                            </Button>{' '}
                            <p className='common-p4 mb-5 mt-3' style={{textDecoration:"underline"}}>Referral commissions available</p>
                        </Col>
                        <Col lg={5} md={6} xs={12}>
                            <img src={banner} className=' float-end section-gap' style={{width:"100%"}} alt="quote banner" title='' fluid
                            />
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
};

export default AccBanner;