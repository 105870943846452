import React from 'react';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import Banner from '../components/Banner/Banner';
import banner from '../../src/asset/images/quote/quote-banner2.png';
import FreeQuote from '../components/HomeQuoteCard/FreeQuote';
import ClientFeedBack from '../components/ClientFeedBack/ClientFeedBack';
import AllClient from '../components/AllClient/AllClient';
import Notification from '../components/TopNotification/TopNotification';
import Campain from '../components/Campain/Campain';

const QuotePage = () => {
    const imageData = {
        imageUrl: banner,
        caption: 'Free Quote',
        title: 'Payroll Revolution: Building Relationships',
        paragraph: 'Request your quote today and count on our commitment to delivering your free quote within 24 hours, providing you with timely, essential information for your business needs.'
    };

    return (
        <>
            <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
            <TopNavigation title="Request Quote | V-TAC Payroll Management" />
            <Banner data={imageData} />
            <Campain />
            <FreeQuote />
            <ClientFeedBack />
            <AllClient />
            <TradePart />
            <Footer />
        </>
    );
};

export default QuotePage;