import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class SchindlerFeedback2 extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="nexasClientSayCard section-gap  p-0">
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <div className='container'>
                                <p className='nexasCardDesc section-gap'>
                                “I need simplicity and reliability. VPM frees me from a stressful but necessary part of my work. Payroll used to mushroom out of control time-wise, and more time spent on that means less time to focus on my own work. VPM has made my life and my work easier. And if it continues, we will continue to do business. I am good with that.”
                                </p>
                                <p className='nexasCardName mt-4 mb-5'>Dr. Schindler</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default SchindlerFeedback2;