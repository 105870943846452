import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Button, Form, InputGroup } from 'react-bootstrap';
//axios for xml request
import axios from 'axios';
//xml file reader
// import { withRouter } from "react-router";
import { useParams } from 'react-router-dom';
import { HttpClient } from '../../lib/HttpClient';
//import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../../asset/images/vpmlogo.png';


// Higher-order component to extract route parameters and pass them as props
const withRouteParams = (WrappedComponent) => {
  return function WithRouteParams(props) {
    const params = useParams();
    return <WrappedComponent {...props} {...params} />;
  };
};

class QuoteDetailsInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {

      id: null,
      company: "",
      numberOfEmployees: 0,
      costPerEmployee: 0,
      baseFee: 0,
      totalCostofEmployee: 0,
      peyrollRunCost: 0,
      onlineCharges: 0,
      totalCost: 0,
      successMessage: "",
      payperperiod: ""
    }
  }


  componentDidMount() {
    document.getElementById("RQForm").style.display = "block";
    document.getElementById("SuccessMSgDiv").style.display = "none";
    const { id } = this.props;
    // let { id } = this.props.match.params;

    // console.log(this.props.match.params);
    HttpClient.get("/details/" + id)
      .then(result => {
        console.log(result);
        this.setState({
          id: result.data[0].id, fullName: result.data[0].fullName, company: result.data[0].company, phone: result.data[0].phone, email: result.data[0].email, numberOfEmployees: result.data[0].numberOfEmployees, payperperiod: result.data[0].payperperiod
        })
      }).catch(error => {
        console.log(error)
      })

  }

  handleChange = event => {

    if (event.target.name == "totalCost") {
      this.state.totalCost = this.state.baseFee + this.state.costPerEmployee * this.state.numberOfEmployees + this.state.peyrollRunCost + this.state.onlineCharges;
    }
    else {
      this.setState({ [event.target.name]: event.target.value })
    }
  }


  submitHandaler = (event) => {
    event.preventDefault();
    axios.post("https://api.vtacpayroll.ca/sendQuation", this.state).then(Response => {
    // axios.post("http://localhost:5000/sendQuation", this.state).then(Response => {
      document.getElementById("RQForm").style.display = "none";
      document.getElementById("SuccessMSgDiv").style.display = "block";

    }).catch(error => {
      console.log(error.response.headers)
    })

  }



  render() {
    //const { fullName, company, phone, email, numberOfEmployees, postalCode, message } = this.state;
    const labelStyles = {
      fontSize: '1.1rem',
      color: '#292929'
    };

    const inputStyles = {
      border: '1px solid #ccc',
      borderRadius: '4px',
      padding: '5px'
    };
    return (
      <Fragment>
        <Container fluid={true} className="">
          <div >
            <Container>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <div className="ReplylogoImage  "> 
                  <img  alt="Paris"  style ={{width:"30%", height:"30%"}}src={Logo} /></div>
                  <div className='quoteDetailsCard'>
                    <div id="RQForm">
                      <div className="quoteReplyHeader">
                        <h2 className='formTitle'>Quotation Reply</h2>
                      </div>
                      <Form onSubmit={this.submitHandaler} className="getinTouchform" >

                        <Form.Group as={Row} className="mb-3" >
                          <Col sm="10">
                            <Form.Control type="hidden" name='id' value={this.state.id} />
                            <Form.Control type="hidden" name='payperperiod' value={this.state.payperperiod} />
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" >
                          <Col sm="10">
                            <Form.Control type="hidden" name='fullName' value={this.state.fullName} disabled />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column sm="3" className='fromLebel' style={labelStyles}>
                            Name of Company*
                          </Form.Label>
                          <Col sm="9">
                            <Form.Control className='disablefromControl' style={{ 'width': '95%' }} type="text" name='company' value={this.state.company} disabled />
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" >

                          <Col sm="10">
                            <Form.Control type="hidden" name='phone' value={this.state.phone} disabled />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">

                          <Col sm="10">
                            <Form.Control type="hidden" name='email' value={this.state.email} disabled />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column sm="3" style={labelStyles}>
                            Total Employees*
                          </Form.Label>
                          <Col sm="9">
                            <Form.Control className='disablefromControl' type="number" style={{ 'width': '30%' }} name='numberOfEmployees' value={this.state.numberOfEmployees} disabled />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column sm="3" style={labelStyles}>
                            One Time Set-up Fee*
                          </Form.Label>
                          <Col sm="9">
                            <Form.Control min={0} style={{ 'width': '30%' }} name='baseFee' value={this.state.baseFee} onChange={this.handleChange} />
                          </Col>
                        </Form.Group>
                        <Form.Text className="text-muted formtext">
                          <strong>One Time Set-up fee includes the following</strong>
                        </Form.Text>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox" >
                          <Form.Check type="checkbox" className='checkboxForm' label="Set-up of payroll details" checked style={labelStyles} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                          <Form.Check type="checkbox" className='checkboxForm' label="Checking calculations" checked style={labelStyles} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                          <Form.Check type="checkbox" className='checkboxForm' label="Administering first payroll" checked style={labelStyles} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                          <Form.Check type="checkbox" className='checkboxForm' label="Reviewing payroll output" checked style={labelStyles} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                          <Form.Check type="checkbox" className='checkboxForm' label="Training to the concerned employees" style={labelStyles} />
                        </Form.Group>
                        <Form.Text className="text-muted formtext">
                          <strong>Direct Cost</strong>
                        </Form.Text>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column sm="3" style={labelStyles}>
                            Per Employee Cost $
                          </Form.Label>
                          <Col sm="2">
                            <Form.Control name='costPerEmployee' title='Cost per Employee' value={this.state.costPerEmployee} onChange={this.handleChange} />

                          </Col>
                          <Col sm="1" className="d-flex justify-content-center align-items-center">
                            <span>x</span>
                          </Col>
                          <Col sm="2">
                            <Form.Control className='disablefromControl' type="number" name='numberOfEmployees' title='Total employee' value={this.state.numberOfEmployees} onChange={this.handleChange} disabled />
                          </Col>
                          <Col md="1" className="d-flex justify-content-center align-items-center">
                            <span>=</span>
                          </Col>
                          <Col sm="2">
                            <Form.Control className='disablefromControl' type="number" name='totalCostofEmployee' value={parseFloat(this.state.costPerEmployee) * parseFloat(this.state.numberOfEmployees)} title="Total cost of Employee" onChange={this.handleChange} disabled />
                          </Col>
                        </Form.Group>
                        <Form.Text className="text-muted formtext">
                          <strong>Fixed Cost</strong>
                        </Form.Text>
                        <Form.Group as={Row} className="mb-3" >
                          <Form.Label column sm="3" style={labelStyles}>

                            Peyroll Run Cost $
                          </Form.Label>
                          <Col sm="9">
                            <Form.Control style={{ 'width': '95%' }} min={0} name='peyrollRunCost' onChange={this.handleChange} value={this.state.peyrollRunCost} />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" >
                          <Form.Label column sm="3" style={labelStyles}>
                            Online Charges $
                          </Form.Label>
                          <Col sm="9">
                            <Form.Control style={{ 'width': '95%' }} min={0} name='onlineCharges' onChange={this.handleChange} value={this.state.onlineCharges} />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" >
                          <Form.Label column sm="3" style={labelStyles}>
                            Total Cost $
                          </Form.Label>
                          <Col sm="9">
                            <Form.Control className='disablefromControl' style={{ 'width': '95%' }} type="number" min={0} name='totalCost' onChange={this.handleChange} value={parseFloat(this.state.baseFee) + (parseFloat(this.state.costPerEmployee) * parseFloat(this.state.numberOfEmployees)) + parseFloat(this.state.peyrollRunCost) + parseFloat(this.state.onlineCharges)} disabled />
                          </Col>
                        </Form.Group>
                        <div className='text-center'><Button className="replyBtn mt -5 mb-3" type="submit">
                          <span>Reply Quotation</span>
                        </Button></div>
                      </Form>
                    </div>
                    <div id='SuccessMSgDiv'>
                      <h2 className='SuccessMsg'>Quotation has been sent Successfully</h2>
                    </div>
                  </div>
                </Col>

              </Row>

            </Container>
          </div>
        </Container>
      </Fragment>
    );
  }
}

export default withRouteParams(QuoteDetailsInfo);



