import React, { Component, Fragment } from 'react';
import QuoteDetailsInfo from '../components/QuoteDetailsInfo/QuoteDetailsInfo';
import TopNavigation from '../components/TopNavigation/TopNavigation';

class QuoteDetaisPage extends Component {
    render() {
        return (
            <Fragment>
                <TopNavigation />
                <QuoteDetailsInfo />
            </Fragment>
        );
    }
}

export default QuoteDetaisPage;