import React from 'react';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import IndustryExpertise from '../components/Banner/IndustryExpertise';
import AnualSummary from '../components/AnualSummary/AnualSummary';
// import banner from '../../src/asset/images/solution/uni-hr.png';
import banner from '../../src/asset/images/industry/construction.png';
import serve1 from '../../src/asset/images/industry/serve1.png';
import SliderComp from '../components/Projects/Slider';
import OfferQuote from '../components/OfferQuote/OfferQuote';
import Notification from '../components/TopNotification/TopNotification';
import CommonFaq from '../components/FAQ/CommonFaq';
import ContractCommitment2 from '../components/IndustryServe/ContractCommitment2';
import Reception from '../components/IndustryServe/Reception';
import construction from '../../src/asset/images/industry/Industry_005.webp';
import construct from '../../src/asset/images/industry/Industry_002.webp';
import IndustryServe2 from '../components/IndustryServe/IndustryServe2';
import { BsUiChecksGrid } from "react-icons/bs";
import { MdGroups } from "react-icons/md";
import { AiOutlineSolution } from "react-icons/ai";
import { LuMonitorCheck } from "react-icons/lu";
import { MdDonutLarge } from "react-icons/md";


const ConstructionOrConstructorPage = () => {
    const imageData = {
        imageUrl: banner,
        // caption: 'Industry Expertise / Construction and Constructor',
        title: 'Build a stronger business with hassle-free payroll',
        paragraph: 'Effortlessly navigate the complexities of union dues and varied rates with VPM’s expertly tailored, time-saving payroll services.'

    };
    const DataInfo = {
        imageUrl: serve1,
        caption: '100% Canadian',
        title: 'We work with the Construction  industry',

        icon1: <AiOutlineSolution size={35} color='#FF135A' />,
        subTitle1: "Solutions that are as unique as your business",
        p1: "VPM excels in meeting the dynamic payroll needs of construction companies. We adeptly manage everything from multiple pay rates per employee to detailed union dues and benefits calculations. Our system is specifically designed to ensure your payroll is as robust and adaptable as your workforce, including specialized handling of WSIB and Worksafe requirements.",

        icon2: <MdDonutLarge size={35} color='#FF135A' />,
        subTitle2: "Streamlined. Effortless. Simple.",
        p2: "VPM offers an intuitive platform that simplifies and expedites payroll management. Experience the convenience of quick, straightforward payroll processing, allowing you to focus on your construction projects rather than getting bogged down with paperwork.",

        icon3: <MdGroups size={35} color='#FF135A' />,
        subTitle3: "Leverage expert union payroll support",
        p3: "VPM specializes in meeting the intricate needs of unionized labor, from calculating and submitting dues and benefits to accommodating complex payment structures. Rely on our precision and expertise for complete compliance and accuracy.",

        icon4: <BsUiChecksGrid size={35} color='#FF135A' />,
        subTitle4: "Optimize with advanced, precise tracking and reporting",
        p4: "VPM’s advanced tracking and reporting features enable you to effectively monitor and manage your construction team. Get detailed insights into work hours, skill levels, seniority, and more with our easy-to-use reporting tools.",

        icon5: <LuMonitorCheck size={35} color='#FF135A' />,
        subTitle5: "Adaptable features, as dynamic as your construction projects.",
        p5: "VPM’s payroll services are designed to cater to the versatile nature of the construction industry. We handle multiple premium and overtime rates seamlessly and facilitate direct deposits, ensuring every aspect of your payroll is managed with precision and ease.",

        picture: construct
    };

    const Receptiondata = {
        heading: "Embark on a smoother payroll path with VPM.",
        text: "Partner with VPM for a more efficient payroll experience. Our team is dedicated to a smooth onboarding process, ensuring a seamless integration of our expert payroll services into your construction business.",
        picture: construction,
    };

    return (
        <>
            <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
            <TopNavigation title="Construction/Constructors | V-TAC Payroll Management" />
            <IndustryExpertise data={imageData} />
            <AnualSummary marginTop="-6rem" />
            <IndustryServe2 data={DataInfo} />
            <ContractCommitment2 />
            <SliderComp />
            <OfferQuote />
            <CommonFaq />
            <Reception data={Receptiondata} />
            <TradePart />
            <Footer />
        </>
    );
};

export default ConstructionOrConstructorPage;