import React from 'react';
import Footer from '../components/Footer/Footer';
import NotFound from '../components/NotFound/NotFound';
import TopNavigation from '../components/TopNavigation/TopNavigation';

const NotFoundPage = () => {
    return (
        <div>
            <TopNavigation title="404-page not found! V-Tac Accounting & Tax | The Professionals' Professional" />
            <NotFound />
            <Footer />
        </div>
    );
};

export default NotFoundPage;