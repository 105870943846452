import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class SchindlerFeedback1 extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="nexasClientSayCard section-gap  p-0">
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <div className='container'>
                                <p className='nexasCardDesc section-gap'>
                                “We wanted more simplicity and a service provider that was reliable, not one that works one week and not the next. The large service provider also does not deal with anyone other than employees and they do not deal with payroll liabilities, so I ended up doing a large portion of the payroll work myself. Where’s the advantage in that? We wanted to be able to easily read the numbers and follow them. This all seemed common sense to me.”
                                </p>
                                <p className='nexasCardName mt-4 mb-5'>Dr. Schindler</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default SchindlerFeedback1;