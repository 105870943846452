import axios from "axios";
const BaseURL = " https://vtacpayroll.cloud:444"


export const fetchData = async (customUrl) => {
    try {
        let URL = BaseURL + customUrl;
        const response = await axios.post(URL);
        return response.data.Response;
    } catch (error) {
        console.log(error);
        return false;
    }
};
export const fetchDatabYiD = async (customUrl, ID) => {
    try {
        let URL = BaseURL + customUrl + ID;
        const response = await axios.post(URL);
        return response.data.Response;
    } catch (error) {
        console.log(error);
        return false;
    }
};

