import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import meeting1 from '../../asset/images/solution/Ellipse 836.png';

const PayrollServicesSeventhPart = () => {
    return (
        <>
            <Container fluid={true} className='payrollSerContainer' >
                <Container>
                    <Row>
                        <Col lg={7} md={6} sm={12} className='mt-5 mb-5'>
                            <h2 className='common-title'>Your benefit is our success, we care about our clients</h2>
                            <p className='common-p'>There are an estimated 100+ Businesses across the entire Canada, with more and more being </p>
                            <Row className='mt-5'>
                                <Col lg={6} md={6} xs={6}>
                                    <p className='common-p5'>Benifit one</p>
                                    <p className='common-p3' style={{ color: "#656363" }}>There are an estimated 100+ Businesses across the entire Canada, with more and more being created every single day. </p>

                                </Col>
                                <Col lg={6} md={6} xs={6}>
                                    <p className='common-p5'>Benifit one</p>
                                    <p className='common-p3' style={{ color: "#656363" }}>There are an estimated 100+ Businesses across the entire Canada, with more and more being created every single day. </p>
                                </Col>
                                <Col lg={6} md={6} xs={6}>
                                    <p className='common-p5'>Benifit one</p>
                                    <p className='common-p3' style={{ color: "#656363" }}>There are an estimated 100+ Businesses across the entire Canada, with more and more being created every single day. </p>
                                </Col>
                                <Col lg={6} md={6} xs={6}>
                                    <p className='common-p5'>Benifit one</p>
                                    <p className='common-p3' style={{ color: "#656363" }}>There are an estimated 100+ Businesses across the entire Canada, with more and more being created every single day. </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={5} md={6} sm={12} className='mt-5'>
                            <img className="payroll-Image2" src={meeting1} alt="Three people meeting" title='Three people meeting' />
                        </Col>
                    </Row>
                </Container>
                <div className=''></div>
            </Container>
        </>
    );
};

export default PayrollServicesSeventhPart;