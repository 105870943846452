import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import asif1 from '../../asset/images/ClientSuccessStory/asif-1.jpg';
import asif2 from '../../asset/images/ClientSuccessStory/asif-2.jpg';
import asif3 from '../../asset/images/ClientSuccessStory/asif-3.jpg';
import { Link } from 'react-router-dom';
import { FcLeft } from "react-icons/fc";

class InsAgentSec1 extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Link to='/client-success-stories'>
                        <p className='common-p2 mt-3' style={{ textDecoration: "underline" }}><FcLeft size={27} /> Success Stories</p>
                    </Link>
                </Container>
                <Container fluid={true} className='nexasStaffingContainer ' >
                    <div className='pt-5'>
                        <Container>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="nexus1Img" alt='Asif Khan Insurance' title='Asif Khan Insurance' src={asif1} />
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <h3 className='commonTitle'>A bit about  <span className='spancolor'>Asif Khan, Desjardins Insurance Agent</span></h3>
                                    <p className='desc'>Established in 2009, <span><a className='faqLink' href='https://asifkhaninsurance.com/' target='_blank'>Asif Khan Insurance Inc</a></span>. has grown into an established diversified financial and insurance agent that just about “does it all” – all types of insurance, car financing, retirement planning, and more.</p>
                                    <p className='desc'>About the only thing this one-stop-shop does not currently offer is mortgages to its current and prospective clients.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12}>

                                    <h3 className='commonTitle mt-5'><span className='spancolor'>Secrets</span> to his success</h3>
                                    <p className='desc'>“Relationships matter to us.”</p>
                                    <p className='desc'>And we always want to make sure we’re growing, catering to our clients’ changing needs in finance and insurance, such as driver-less electric cars that are coming and here, and being ready for those sorts of challenges,” said Asif.</p>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="nexus1Img mt-5" alt='Asif Khan Insurance' title='Asif Khan Insurance' src={asif2} />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <img className="nexus1Img mt-5 " src={asif3} alt='Asif Khan Insurance' title='Asif Khan Insurance' />
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <h3 className='commonTitle mt-5'>Looking for <span className='spancolor'>long-term commitment</span></h3>
                                    <p className='desc'>When Asif was opening his business, he knew he wanted to focus on growing his business.</p>
                                    <p className='desc'>He needed a payroll service provider that not only had amazing customer service and fair pricing but would also work with Asif on building a long-term business partnership.</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default InsAgentSec1;