import React from 'react';
import T4Banner from '../components/T4/T4Banner';
import Footer from '../components/Footer/Footer';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import Notification from '../components/TopNotification/TopNotification';
import T4Form from '../components/T4/T4Form';
import T4Details from '../components/T4/T4Details';
import PayrollExpert from '../components/T4/PayrollExpert';
import { Element } from 'react-scroll';
import T4Faq from '../components/T4/T4Faq';

const T4Page = () => {
    return (
        <>
            <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
            <TopNavigation title="Year end payroll | V-TAC Payroll Management" />
            <T4Banner />
            <T4Details />
            <Element name="t4Form">
                <T4Form />
            </Element>
            <PayrollExpert />
            <T4Faq />
            <Footer />
        </>
    );
};

export default T4Page;