import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import sol1 from '../../asset/images/solution/Solution-01.webp';
import sol2 from '../../asset/images/solution/Solution-02.webp';
import sol3 from '../../asset/images/solution/Solution-03.webp';
import sol4 from '../../asset/images/solution/Solution-04.webp';
import sol5 from '../../asset/images/solution/Solution-05.webp';

const SolutionSec3 = () => {

    return (
        <>
            <Container fluid style={{ backgroundColor: "#EFFFE5" }}>
                <Container >
                    <Row>
                        <Col lg={6} md={6} sm={12} className='section-gap'>
                            <div>
                                <p className='common-title'>Tailored to your business and industry</p>
                                <p className='payroll-text'>No matter what your industry—dental, construction, hospitality, legal, staffing—VPM has the expertise to simplify your payroll process. </p>
                                <p className='payroll-text'>Our industry-specific solutions ensure compliance, accuracy, and efficiency tailored to your unique business requirements.</p>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} className='section-gap'>
                            <img className="payroll-Image text-center " alt="vpm-payroll-reports"  src={sol1} />
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <img className="payroll-Image  text-center" alt="vpm-payroll-reports" src={sol2} />
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div>
                                <p className='common-title section-gap' >Effortless integration with easy, remote access</p>
                                <p className='payroll-text'>Our user-friendly platform integrates seamlessly into your existing systems, supported by a team dedicated to making your switch smooth and hassle-free. Access your payroll anytime, anywhere, with our mobile-friendly solutions, empowering you with real-time data and analytics at your fingertips.</p>
                            </div>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                            <div>
                                <p className='common-title section-gap'>Cost-effective and efficient</p>
                                <p className='payroll-text'>Our transparent, competitive pricing, with no hidden fees, ensures you get the best value in the industry. VPM can significantly reduce your administrative costs and headaches while increasing efficiency and accuracy.</p>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <img className="payroll-Image  text-center section-gap" alt="vpm-payroll-reports" src={sol3} />
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <img className="payroll-Image  text-center section-gap " alt="vpm-payroll-reports" src={sol4} />
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div>
                                <p className='common-title section-gap'>Advanced security & compliance</p>
                                <p className='payroll-text'>Your data security and privacy are never compromised. Our robust security measures protect your sensitive data and ensure you stay compliant with ever-changing payroll regulations.</p>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div>
                                <p className='common-title section-gap'>Unparalleled support, every step of the way</p>
                                <p className='payroll-text'>With VPM, you always have peace of mind knowing that a dedicated account manager is just a phone call away, to ensure every aspect of your payroll operation is running smoothly.</p>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <img className="payroll-Image text-center section-gap" alt="vpm-payroll-reports" src={sol5} />
                        </Col>


                        <div style={{ marginBottom: "6rem" }}></div>
                    </Row>
                  
                </Container>
            </Container>

        </>
    );
};

export default SolutionSec3;