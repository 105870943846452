import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import bell from '../../asset/images/home/bell-icon.png';
import { FaXTwitter } from 'react-icons/fa6';
import { FaYoutube } from "react-icons/fa";
import { BiLogoLinkedin, BiLogoFacebook } from "react-icons/bi";
import { Link } from 'react-router-dom';

function Notification({ message, duration, subscription }) {

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(false);
    }, duration);

    return () => {
      clearTimeout(timeout);
    };
  }, [duration]);

  return (
    isVisible && (
      <Container fluid className="notification">
        {/* <Container> */}
        <Row className="align-items-center text-center d-flex">
          <Col md={3}></Col>
          <Col xs={6} md={5}>
            {/* <div>
              <img alt="star" src={bell} />
              <span className='message'><strong> {message}</strong></span>
              <Link to="/promotional-conditions" ><span className='sub-description'>{subscription}</span></Link>
            </div> */}
          </Col>
          <Col xs={6} md={2} >
            <a href="https://www.facebook.com/vtacpayroll/" target='_blank' className='' > <BiLogoFacebook className="fa fa-facebook" /></a>
            <a href="https://twitter.com/vtacpayroll" target='_blank' ><FaXTwitter className='fa fa-twitter' /></a>
            <a href="https://www.linkedin.com/company/v-tac-payroll-management/" target='_blank' className=""><BiLogoLinkedin className='fa fa-linkedin' /></a>
            <a href="https://www.youtube.com/@v-tacpayrollmanagement3092/videos" target='_blank' className='' ><FaYoutube className='fa fa-youtube' /></a>

          </Col>
          <Col xs={8} md={2} className='text-right'>
            <Button
              variant="link"
              className="close-button"
              onClick={() => setIsVisible(false)}
            >
              <AiOutlineCloseCircle className="close-icon" />
            </Button>
          </Col>

        </Row>
      </Container>
      // </Container>

    )
  );
}

export default Notification;
