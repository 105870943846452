import React from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaPhoneAlt } from "react-icons/fa";

const GetInTouch = () => {
    return (
        <>
            <Container fluid className='get-in-touch'>
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12} className='mt-5'>
                            <h2 className='get-in-touch-title' data-aos="fade-up" data-aos-duration="800" data-aos-once="true">We won't abandon you in the dark.<br></br> contact to reach...</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12} className='text-center m-3'>
                            <Link to="/request-payroll-quote" >
                                <Button className="free-quote-btn">
                                    <span>Free Quote</span>
                                </Button>
                            </Link> &nbsp;
                            <Link to="/book-demo">
                                <Button className='book-demo-btn'>
                                    <span>Book Demo</span>
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                    <Row className='section-gap'>
                        <Col lg={4} md={4} sm={12} className=''>
                            <div data-aos="fade-zoom-in"
                                data-aos-easing="ease-in-back"
                                data-aos-delay="300"
                                data-aos-offset="0"
                                data-aos-once="true">
                                <p className='get-in-touch-p'>&nbsp; Like it ? Lets get started to shift here at VPM, Welcome !</p>
                                <Link to="/request-payroll-quote">
                                    <p className='get-in-touch-p2'>Get Started</p>
                                </Link>
                            </div>
                        </Col>
                        <Col lg={4} md={4} sm={12} className='line'>
                            <div data-aos="fade-zoom-in"
                                data-aos-easing="ease-in-back"
                                data-aos-delay="300"
                                data-aos-offset="0"
                                data-aos-once="true">
                                <p className='get-in-touch-p'>&nbsp; Call us anytime for any kind of issue you are in our soft</p>
                                <p className='get-in-touch-p2 text-center'>
                                    <a href="tel:+1-866-315-8822" >
                                        <span style={{ color: "#FF135A" }}><FaPhoneAlt size={20} /></span>
                                        <span style={{ color: "#FF135A" }}> 1-866-315-8822</span>
                                    </a>
                                </p>

                            </div>

                        </Col>
                        <Col lg={4} md={4} sm={12} className='line'>
                            <div data-aos="fade-zoom-in"
                                data-aos-easing="ease-in-back"
                                data-aos-delay="300"
                                data-aos-offset="0"
                                data-aos-once="true">
                                <p className='get-in-touch-p' >Busy ? You can also write us mail anytime in details</p>
                                <Link to="/contact">
                                    <p className='get-in-touch-p2'>Send us email</p>
                                </Link>

                            </div>

                        </Col>
                    </Row>
                </Container>
                <div className='mt-5'></div>
            </Container>
        </>
    );
};

export default GetInTouch;