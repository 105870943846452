import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from 'react-router-dom';

const OfferQuote = () => {

    var settings = {
        dots: false,
        infinite: true,
        speed: 1000,          // Transition speed (1 second)
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,  // Delay between slide transitions (5 seconds)
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <Container fluid className='offer-quote' >
                <Container>
                    <Slider {...settings}>
                        <div>
                            <Row>
                                <Col xs={12} lg={12} md={12} className="text-center">
                                    <p className='common-p' style={{ color: "#FFF" }}>Want to know more?  get a<Link to="/request-payroll-quote"><strong style={{ color: "#F1FF9E" }}> Free Quote</strong></Link></p>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col xs={12} lg={12} md={12} className="text-center">
                                    <p className='common-p' style={{ color: "#FFF"}}>Want to know more?  get a<Link to="/request-payroll-quote"><strong style={{ color: "#F1FF9E" }}> Free Quote</strong></Link></p>
                                </Col>
                            </Row>
                        </div>
                    </Slider>
                </Container>
            </Container>

        </>
    );
};

export default OfferQuote;