// import "./styles.css";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup, Tooltip } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { Icon, divIcon, point } from "leaflet";


// create custom icon
const customIcon = new Icon({
    // iconUrl: "https://cdn-icons-png.flaticon.com/512/447/447031.png",
    iconUrl: require("../Map/marker.png"),
    iconSize: [38, 38] // size of the icon
});

// custom cluster icon
const createClusterCustomIcon = function (cluster) {
    return new divIcon({
        html: `<span class="cluster-icon">${cluster.getChildCount()}</span>`,
        className: "custom-marker-cluster",
        iconSize: point(33, 33, true)
    });
};

// markers
const markers = [
    {
        geocode: [43.651780, -79.381110],
        city: "Toronto – Head Office",
        country: "Canada",
        address: "401 Bay Street, Suite 1600, Toronto, ON M5H 2Y4",
        company: "VPM"
    },
    {
        geocode: [43.649368, -79.381927],
        city: "Toronto",
        country: "Canada",
        address: "100 King Street West, Suite 5700, Toronto, ON M5X 1C7",
        company: "VPM"
    },
    {
        geocode: [43.676830, -79.494390],
        city: "Toronto",
        country: "Canada",
        address: "881A Jane Street, Suite 207, Toronto, ON M6M 4C4",
        company: "VPM"
    },
    {
        geocode: [51.048180, -114.080630],
        city: "Calgary",
        country: "Canada",
        address: "300, 840-6 Avenue SW, Calgary, AB T2P 3E5",
        company: "VPM"
    }
];

const Map = () => {

    return (
        <div>
            <MapContainer center={[43.651780, -79.381110]} zoom={3}>
                {/* OPEN STREEN MAPS TILES */}
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"

                />

                <MarkerClusterGroup
                    chunkedLoading
                    iconCreateFunction={createClusterCustomIcon}
                >
                    {/* Mapping through the markers */}
                    {markers.map((marker) => (
                        <Marker position={marker.geocode} icon={customIcon}>
                            <Tooltip>
                                <b>
                                    {marker.company}<br />
                                    {marker.city}<br />
                                    {marker.address}<br />
                                    {marker.country}<br />

                                </b>
                            </Tooltip>
                        </Marker>
                    ))}
                </MarkerClusterGroup>
            </MapContainer>
        </div>
    );
};

export default Map;