import React,{useEffect} from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Client from '../Client/Client';
import img1 from '../../asset/images/about/Vector3.svg';


const AllClient = (props) => {
    const { backgroundColor, color } = props;

    return (
        <>
            <Container fluid style={{ backgroundColor: backgroundColor }}>
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12} className='mt-5'>
                            <p className='common-p5 text-center pt-5 mb-5' style={{ color: color }} data-aos="fade-right" data-aos-duration="800"
                                data-aos-once="true">
                                {/* <img
                                    src={img1}
                                    alt="Why choose us"
                                    style={{width:"5%"}}
                                />  */}
                                Serving Canada’s leading businesses<span style={{ color: "#FF135A" }}> over 25 years</span>
                            </p>
                        </Col>
                        <Col>
                            <Client />
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
};

export default AllClient;