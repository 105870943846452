import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import star from '../../asset/images/home/star.png';
const DetailsBenifit = () => {
    return (
        <>
            <Container fluid style={{ backgroundColor: "#252E44" }}>
                <Container>
                    <Row>
                        <h2 className='section-gap mb-3'></h2>
                        <Col lg={4} md={4} sm={12} xs={12}>
                            <Card className='accountant-details' style={{ border: "4px dashed #779EFF", borderRadius: "8px 8px 56px 8px" }}>
                                {/* <Card.Img className='join-img' src={star} alt="banner" title='banner' variant="top" /> */}
                                <Card.Body>
                                    <Card.Title className='common-title3' style={{ fontSize: "1.375rem", color: "#252E44" }}>Choose Expertise and Reliability with VPM</Card.Title>
                                    <Card.Text className='common-p' style={{ color: "#6B6B6B" }}>
                                        VPM stands out as a preferred payroll partner for accountants, offering unmatched expertise and reliability. We understand payroll intricacies, from processing to remittances and T4 filings, ensuring a seamless experience for you and your clients.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={4} sm={12} xs={12}>
                            <Card className='accountant-details' style={{ border: "4px dashed #FF135A", borderRadius: "8px 8px 8px 56px" }}>
                                {/* <Card.Img className='join-img' src={star} alt="banner" title='banner' variant="top" /> */}
                                <Card.Body>
                                    <Card.Title className='common-title3' style={{ fontSize: "1.375rem", color: "#252E44" }}>Effortless Client Integration & Management</Card.Title>
                                    <Card.Text className='common-p' style={{ color: "#6B6B6B" }}>
                                        Our platform is designed for ease of use and seamless integration. Introducing your clients to VPM’s services is straightforward, with minimal disruption and maximum efficiency. We make it simple for you to manage client accounts and payroll functions effectively.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={4} sm={12} xs={12}>
                            <Card className='accountant-details' style={{ border: "4px dashed #779EFF", borderRadius: "8px 8px 56px 8px" }}>
                                {/* <Card.Img className='join-img' src={star} alt="banner" title='banner' variant="top" /> */}
                                <Card.Body>
                                    <Card.Title className='common-title3' style={{ fontSize: "1.375rem", color: "#252E44" }}>Complete Payroll Services for Every Client Need</Card.Title>
                                    <Card.Text className='common-p' style={{ color: "#6B6B6B" }}>
                                        From small businesses to large corporations, VPM’s payroll services cover every need. Our platform is equipped to handle diverse payroll requirements, ensuring your clients receive expertly tailored solutions.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='mb-5'>
                        <Col lg={2} md={4} sm={12} xs={12}></Col>
                        <Col lg={4} md={4} sm={12} xs={12}>
                            <Card className='accountant-details' style={{ border: "4px dashed #FF135A", borderRadius: "8px 8px 56px 8px" }}>
                                {/* <Card.Img className='join-img' src={star} alt="banner" title='banner' variant="top" /> */}
                                <Card.Body>
                                    <Card.Title className='common-title3' style={{ fontSize: "1.375rem", color: "#252E44" }}>Reliability and Trust</Card.Title>
                                    <Card.Text className='common-p' style={{ color: "#6B6B6B" }}>
                                        Your clients' trust is your most valuable asset. Partner with VPM to ensure their payroll is in safe hands. Our dependable and secure service builds confidence and enhances your reputation as a trusted advisor.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={4} sm={12} xs={12}>
                            <Card className='accountant-details' style={{ border: "4px dashed #779EFF", borderRadius: "8px 8px 8px 56px" }}>
                                {/* <Card.Img className='join-img' src={star} alt="banner" title='banner' variant="top" /> */}
                                <Card.Body>
                                    <Card.Title className='common-title3' style={{ fontSize: "1.375rem", color: "#252E44" }}>Receive a commission for each successful referral</Card.Title>
                                    <Card.Text className='common-p' style={{ color: "#6B6B6B" }}>
                                        In recognition of your valuable referrals, we offers referral commission program for accountants. It's our way of saying thank you for trusting us with your clients' payroll needs.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={2} md={4} sm={12} xs={12}></Col>
                        <Col lg={8} xs={12}>
                            
                            <p className='common-title3 text-center' style={{width:"100%",padding:"1rem",borderRadius:"4px", color:"#fff", backgroundColor:"#FF135A" }}>Elevate your clients’ payroll experience today</p>
                          
                        </Col>
                        {/* <Col lg={1} md={4} sm={12} xs={12}></Col> */}
                        <div className='section-gap'></div>
                    </Row>
                    
                </Container>
            </Container>

        </>
    );
};

export default DetailsBenifit;