import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MdChevronRight } from "react-icons/md";
const ClientSuccessStoriesBanner = () => {
    return (
        <>
                <Container fluid={true} className="clientSuccessStoriesBanner  p-0">
                    <div className='ContactTopBannerOverly'>
                        <Container className="text-center">
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <h1 className='common-title section-gap' style={{color:"#fff"}}>
                                        CLIENT SUCCESS STORIES
                                    </h1>
                                    <h3 className='common-p10'>
                                        See how our clients got peace of mind with VPM
                                    </h3>
                                    <Link to="/request-payroll-quote"><Button className='quote-btn-banner mt-2'><span>Free Quote <MdChevronRight /></span></Button></Link>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </>
    );
};

export default ClientSuccessStoriesBanner;

