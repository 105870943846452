import React, { useState, useEffect, Fragment } from 'react';
import { Navbar, Nav, Row, Col, Container, Button, NavDropdown } from 'react-bootstrap';
import '../../asset/css/custom.css';
import '../../asset/css/responsive.css';
import '../../asset/css/bootstrap.min.css';
import vpmlogo from '../../asset/images/home/Untitled-2.png';
import { NavLink, Link } from "react-router-dom";
import { FaGreaterThan } from 'react-icons/fa';
import { FaArrowRightLong } from 'react-icons/fa6';
import { FaTimes, FaBars } from 'react-icons/fa';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.min.css';
import blog from '../../asset/images/navbar/blog.webp';
import { FcDataConfiguration } from "react-icons/fc";
import { FcOrganization } from "react-icons/fc";
import { FcCalendar } from "react-icons/fc";


import calculator from '../../asset/images/navbar/cal.webp';
import { Link as ScrollLink } from 'react-scroll';

const TopNavigation = (props) => {
    const [pageTitle, setPageTitle] = useState(props.title);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);


    const handleDropdownToggle = () => {
        setIsDropdownOpen(prevIsDropdownOpen => !prevIsDropdownOpen);
    };

    // Scroll to top on page load
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);


    const HomeClick = () => {
        if (document.getElementById("RQForm") != null || document.getElementById("RQForm") != undefined) {
            document.getElementById("RQForm").style.display = "block";
            document.getElementById("SuccessMSgDiv").style.display = "none";
        }
    }

    return (
        <Fragment>
            <title>{pageTitle}</title>
            <Navbar expand="lg" className="custom-navbar" >
                <Container className='p-0'>
                    <Navbar.Brand href="#">
                        <Link to='/'> <img src={vpmlogo} className='vpm-logo ml-2' loading="lazy" alt="V-TAC Payroll Management" title='V-TAC Payroll Management' onClick={() => {
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                        }} /></Link>
                    </Navbar.Brand>
                    <Link to="/request-payroll-quote"> <Navbar.Brand ><button className='quoteBtnForSmallScreen'>Request Quote</button></Navbar.Brand></Link >
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleDropdownToggle}>
                        {isDropdownOpen ? (
                            <FaTimes /> // Show close icon if dropdown is open
                        ) : (
                            <FaBars /> // Show menu icon if dropdown is closed
                        )}
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto " navbarScroll>
                            <Nav.Link as="div">

                                <ScrollLink
                                    activeClass="active"
                                    to="allOneApp"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}

                                ><NavLink to="/" className="nav-link scroll-link" style={{ fontSize: "14px" }}>Why VPM</NavLink></ScrollLink>
                            </Nav.Link>
                            <NavDropdown style={{ fontSize: "14px" }} title="Solutions" id="collasible-nav-dropdown" renderMenuOnMount={true} >
                                {/* <hr className='navHr'></hr> */}


                                <Row>
                                    <Col lg={6} md={6} xs={12}>

                                        <div>
                                            <p className="common-p5 ml-4 mt-4" style={{ color: "#DB4055" }}>Our Services</p>
                                        </div>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item className='custom-dropdown-item'></NavDropdown.Item>
                                        <NavDropdown.Item
                                            as={NavLink} to="/">
                                            <span>
                                                <div className="image-and-text custom-solution-item">
                                                    {/* <img src={payroll} alt="Services Icon" className="services-icon" /> */}
                                                    <FcOrganization className="services-icon" />
                                                    <div>
                                                        <p className="common-p2 m-0" style={{ color: "#252E44" }}>Industries we serve </p>
                                                        <p className="common-p2" style={{ fontSize: "13px", color: "#7E7E7E" }}>Select your desire industry for services &nbsp;<FaArrowRightLong size={20} style={{ color: "red" }} /></p>
                                                    </div>
                                                </div>
                                            </span>
                                        </ NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item
                                            as={NavLink} to="/business-payroll-services" onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}>
                                            <span>
                                                <div className="image-and-text ">
                                                    {/* <img src={payroll} alt="Services Icon" className="services-icon" /> */}
                                                    <FcCalendar className="services-icon"/>
                                                    <div>
                                                        <p className="common-p2 m-0" style={{ color: "#252E44" }}>Payroll Services</p>
                                                        <p className="common-p2" style={{ fontSize: "13px", color: "#7E7E7E" }}>Explore the benefits of our services</p>
                                                    </div>
                                                </div>
                                            </span>
                                        </ NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item
                                            as={NavLink} to="/year-end-payroll" onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}>
                                            <span>
                                                <div className="image-and-text ">
                                                    {/* <img src={payroll} alt="Services Icon" className="services-icon" /> */}
                                                    <FcDataConfiguration className="services-icon" />
                                                    <div>
                                                        <p className="common-p2 m-0" style={{ color: "#252E44" }}>T4 Services</p>
                                                        <p className="common-p2" style={{ fontSize: "13px", color: "#7E7E7E" }}>Explore the benefits of our services</p>
                                                    </div>
                                                </div>
                                            </span>
                                        </ NavDropdown.Item>
                                    </Col>

                                    <Col lg={6} md={6} xs={12}>
                                        <div>
                                            <p className="common-p5 ml-4 mt-4" style={{ color: "#252E44" }}>Industries we serve</p>
                                        </div>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item as={NavLink} to="/payroll-service-dentists" onClick={() => {
                                            window.scrollTo({ top: 0, behavior: 'smooth' });
                                        }}>
                                            <div className='custom-solution-item2'>
                                                <p className="common-p2 m-0" style={{ color: "#252E44" }}>Dental Offices</p>
                                                <p className="m-0" style={{ fontSize: "13px", color: "#7E7E7E" }}>Easy Dental Solution for Canadian Dentists</p>
                                            </div>

                                        </NavDropdown.Item>

                                        <NavDropdown.Item as={NavLink} to="/hospitality-payroll-software" onClick={() => {
                                            window.scrollTo({ top: 0, behavior: 'smooth' });
                                        }}>
                                            <div className='custom-solution-item2'>
                                                <p className="common-p2 m-0" style={{ color: "#252E44" }}>Hospitality Industry</p>
                                                <p className="m-0" style={{ fontSize: ".875rem", color: "#7E7E7E" }}>Efficient Hospitality Payroll with Compliance</p>
                                            </div>
                                        </NavDropdown.Item>

                                        <NavDropdown.Item as={NavLink} to="/payroll-service-employment-agency" onClick={() => {
                                            window.scrollTo({ top: 0, behavior: 'smooth' });
                                        }}>
                                            <div className='custom-solution-item2'>
                                                <p className="common-p2 m-0" style={{ color: "#252E44" }}>Employment Agencies</p>
                                                <p className="m-0" style={{ fontSize: ".875rem", color: "#7E7E7E" }}>ROEs & Intuitive Payroll for Agencies</p>
                                            </div>

                                        </NavDropdown.Item>

                                        <NavDropdown.Item as={NavLink} to="/payroll-for-law-firms" onClick={() => {
                                            window.scrollTo({ top: 0, behavior: 'smooth' });
                                        }}>
                                            <div className='custom-solution-item2'>
                                                <p className="common-p2 m-0" style={{ color: "#252E44" }}>Law Firms</p>
                                                <p className="m-0" style={{ fontSize: ".875rem", color: "#7E7E7E" }}>Canadian lawyers' growth-focused payroll solution</p>
                                            </div>

                                        </NavDropdown.Item>

                                        <NavDropdown.Item as={NavLink} to="/payroll-company-construction" onClick={() => {
                                            window.scrollTo({ top: 0, behavior: 'smooth' });
                                        }}>
                                            <div className='custom-solution-item2'>
                                                <p className="common-p2 m-0" style={{ color: "#252E44" }}>Construction/Contractors</p>
                                                <p className="m-0" style={{ fontSize: ".875rem", color: "#7E7E7E" }}>Construction-Focused Payroll for Companies</p>
                                            </div>

                                        </NavDropdown.Item>
                                    </Col>
                                </Row>
                                <NavDropdown.Divider />
                            </NavDropdown>

                            <Nav.Link>
                                <NavLink className="nav-link" to="/accountants" onClick={() => {
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                }}>Accountants</NavLink>
                            </Nav.Link>
                            <NavDropdown style={{ fontSize: "14px" }} title="Resources" id="collasible-nav-dropdown" renderMenuOnMount={true}>
                                <NavDropdown.Divider />
                                <NavDropdown.Item as={NavLink} to='/payroll-calculator' onClick={() => {
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                }}>
                                    <Row>
                                        <Col lg={6} md={6}>
                                            <NavDropdown.Item className='custom-dropdown-item'></NavDropdown.Item>
                                            <div className="">
                                                <img src={calculator} alt="Services Icon" className="services-icon2 mb-4" />
                                                <div>
                                                    <p className="common-p2 m-0" style={{ color: "#252E44" }}>Payroll Calculator </p>
                                                    <p className="common-p2" style={{ fontSize: ".875rem", color: "#7E7E7E" }}>Unlock the potential of a payroll calculator for precise<br />  calculations, compliance, and industry insights.</p>
                                                    <p className='common-p4' style={{ color: "#EA233C" }}><strong>Calculate now</strong> <FaArrowRightLong size={20} /></p>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col lg={6} md={6} className="border-left">
                                            {/* <NavDropdown.Item as={NavLink} to="/faq" onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}>
                                                <span>
                                                    <div className="image-and-text">
                                                        <img src={sub1} alt="Services Icon" className="services-icon" />
                                                        <div>
                                                            <p className="common-p2 m-0" style={{ color: "#252E44" }}>Canadian Business Payroll Guide.</p>
                                                            <p className="common-p2 m-0" style={{ fontSize: ".875rem", color: "#7E7E7E" }}>Reliable payroll: Never late with salaries</p>
                                                            <p className='common-p4' style={{ color: "#EA233C" }}><strong>Download now</strong> <FaGreaterThan size={13} /></p>
                                                        </div>
                                                    </div>
                                                </span>
                                            </NavDropdown.Item> */}

                                            {/* <NavDropdown.Divider /> */}
                                            {/* <NavDropdown.Item activeClass="active" as={NavLink} to="/industry-news" onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}>
                                                <span>
                                                    <div className="image-and-text">
                                                        <img src={sub2} alt="Services Icon" className="services-icon" />
                                                        <div>
                                                            <p className="common-p2 m-0" style={{ color: "#252E44" }}>Canadian Business Payroll Guide.</p>
                                                            <p className="common-p2 m-0" style={{ fontSize: ".875rem", color: "#7E7E7E" }}>Reliable payroll: Never late with salaries</p>
                                                            <p className='common-p4' style={{ color: "#EA233C" }}><strong>Download now</strong> <FaGreaterThan size={13} /></p>
                                                        </div>
                                                    </div>
                                                </span>
                                            </NavDropdown.Item> */}

                                            <NavDropdown.Divider />
                                            <NavDropdown.Item activeClass="active" as={NavLink} to="/industry-news" onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}>
                                                <span>
                                                    <div className="image-and-text">
                                                        <img src={blog} alt="Services Icon" className="services-icon" />
                                                        <div>
                                                            <p className="common-p2 m-0" style={{ color: "#252E44" }}>Industry news & blogs</p>
                                                            <p className="common-p2 m-0" style={{ fontSize: ".875rem", color: "#7E7E7E" }}>Stay tuned for the latest payroll news!</p>
                                                            <p className='common-p4' style={{ color: "#EA233C" }}><strong>Read Now</strong> <FaGreaterThan size={13} /></p>
                                                        </div>
                                                    </div>
                                                </span>
                                            </NavDropdown.Item>
                                        </Col>
                                    </Row>
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                {/* <hr className='navHr'></hr> */}
                            </NavDropdown>
                            <Nav.Link>
                                <NavLink style={{ fontSize: "14px" }} className="nav-link" to="/about-us" onClick={() => {
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                }}>About Us</NavLink>
                            </Nav.Link>

                        </Nav>
                        <Nav className='ms-auto'>
                            <Link to="/year-end-payroll"><Button className="t4-nav-button mr-2" onClick={() => {
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}><span><strong>T4 Service</strong></span></Button>{' '}</Link>
                            <a href="https://www.vtacpayroll.cloud/" target='_blank'><Button className="login-btn mr-2" onClick={() => {
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}><span>Login</span></Button>{' '}</a>
                            <Link to="/request-payroll-quote"><Button className="nav-button" onClick={() => {
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}><span><strong>Free Quote</strong></span></Button>{' '}</Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </Fragment>
    );
}

export default TopNavigation;
