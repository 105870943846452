import React, { Fragment } from 'react';
import TopNavigation from '../../components/TopNavigation/TopNavigation';
import TradePart from '../../components/TradePart/TradePart';
import Footer from '../../components/Footer/Footer';
import UnderstandingPayroll from '../../components/Blogs/UnderstandingPayroll';
import { Container } from 'react-bootstrap';
import GetInTouch from '../../components/GetInTouch/GetInTouch';
import Notification from '../../components/TopNotification/TopNotification';

const Blog1Page = () => {

    return (
        <Fragment>
            <Container fluid="true" className='accContainer'>
                <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
                <TopNavigation title="Understanding Payroll Companies in Canada | V-TAC Payroll Management" />
                <UnderstandingPayroll />
                <GetInTouch />
                <TradePart />
                <Footer />
            </Container>

        </Fragment>
    );
};

export default Blog1Page;