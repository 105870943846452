import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ClientSuccessStories = () => {
    return (
        <>
            <Container className='section-gap'>
                <Row>
                    <Col lg={4} md={6} sm={12}>
                        <Container className="successstoryImg p-0 mb-5">
                            <div className="successstoryImgOverlay">
                                <div className='text-center'>
                                    <h2 className='successStoryTitle'>EMPLOYMENT AGENCY</h2>
                                    <h3 className='successStorysubTitle'>Nexus Staffing</h3>
                                    <p className='successStoryPara'>How Nexus Staffing found growth with VPM</p>
                                    <Link to="/nexus-staffing"><Button variant="primary" className='Storybutton'><span>READ MORE</span></Button>{' '}</Link>
                                </div>
                            </div>
                        </Container>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <Container className="successstoryImg2 p-0 mb-5">
                            <div className="successstoryImgOverlay">
                                <div className='text-center'>
                                    <h2 className='successStoryTitle'>DENTAL OFFICE</h2>
                                    <h3 className='successStorysubTitle'>Mount Royal Dental</h3>
                                    <p className='successStoryPara'>See how Mount Royal saves time and money with VPM</p>
                                    <Link to="/mount-royal-dental"><Button variant="primary" className='Storybutton'><span>READ MORE</span></Button>{' '}</Link>
                                </div>
                            </div>
                        </Container>
                    </Col>
                    <Col lg={4} md={6} xs={12}>
                        <Container className="successstoryImg3 p-0 mb-5">
                            <div className="successstoryImgOverlay">
                                <div className='text-center'>
                                    <h2 className='successStoryTitle'>INSURANCE AGENT</h2>
                                    <h3 className='successStorysubTitle'>Asif Khan Insurance</h3>
                                    <p className='successStoryPara'>An insurance agent gets the time to focus on client relationships</p>
                                    <Link to="/insurance-agent"><Button variant="primary" className='Storybutton'><span>READ MORE</span></Button>{' '}</Link>
                                </div>
                            </div>
                        </Container>
                    </Col>
                </Row>
                <Row className='mt-5'>
                    <Col lg={4} md={6} xs={12}>
                        <Container className="successstoryImg4 p-0 mb-5">
                            <div className="successstoryImgOverlay">
                                <div className='text-center'>
                                    <h2 className='successStoryTitle'>DENTAL OFFICE</h2>
                                    <h3 className='successStorysubTitle'>Dr. Schindler & Associates</h3>
                                    <p className='successStoryPara'>VPM helps Dr. Schindler spend more time with family</p>
                                    <Link to="/dr-schindler-associates"><Button variant="primary" className='Storybutton'><span>READ MORE</span></Button>{' '}</Link>
                                </div>
                            </div>
                        </Container>
                    </Col>
                    <Col lg={4} md={6} xs={12}>
                        <Container className="successstoryImg5 p-0 mb-5">
                            <div className="successstoryImgOverlay">
                                <div className='text-center'>
                                    <h2 className='successStoryTitle'>PRINTING FIRM</h2>
                                    <h3 className='successStorysubTitle'>Global Imaging</h3>
                                    <p className='successStoryPara'>Global Imaging stays up-to-date with legislation with VPM</p>
                                    <Link to="/global-imaging"><Button variant="primary" className='Storybutton'><span>READ MORE</span></Button>{' '}</Link>
                                </div>
                            </div>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ClientSuccessStories;
