import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Slider from "react-slick";
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'; // Import the icons from react-icons
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import schindler from '../../asset/images/quote/dr-schindler.jpg';
import global from '../../asset/images/client/global.jpg';
import asif from '../../asset/images/client/asif.png';
import mountRoyel from '../../asset/images/client/mount-royal-dental.jpg';
import nexus from '../../asset/images/home/nexus1.png';
import { Link } from 'react-router-dom';

const ClientFeedBack = () => {
    // Define arrow components before assigning them to prevArrow and nextArrow
    // const CustomPrevArrow = (props) => {
    //     const { className, onClick } = props;
    //     return (
    //         <div className={`${className} custom-arrow prev-arrow`} onClick={onClick}>
    //             <FaAngleLeft style={{ color: 'black', fontSize: '24px' }} />
    //         </div>
    //     );
    // };

    // const CustomNextArrow = (props) => {
    //     const { className, onClick } = props;
    //     return (
    //         <div className={`${className} custom-arrow next-arrow`} onClick={onClick}>
    //             <FaAngleRight style={{ color: 'black', fontSize: '24px' }} />
    //         </div>
    //     );
    // };
    var settings = {
        dots: true,
        fade: true,
        arrows: true,
        infinite: true,
        speed: 1000,          // Transition speed (1 second)
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        // prevArrow: <CustomPrevArrow />,
        // nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <Container className='section-gap'>
                <div className='container align-items-center' style={{ width: "90%" }}>
                    <h2 className='common-title4  m-0 ' style={{ color: "#779EFF" }} data-aos="fade-up" data-aos-duration="800" data-aos-once="true">Client Success Stories</h2>
                    <Link to="/client-success-stories">
                        <Slider {...settings}>
                            <div>
                                <Container className='feedback-container'>
                                    <Row className='row-centered'>
                                        <Col sm={12} md={12} lg={4} className="d-flex align-items-center justify-content-center">
                                            <img className="feedback-image" src={schindler} fluid loading="lazy" />
                                        </Col>
                                        <Col sm={12} md={12} lg={7} className="d-flex align-items-center justify-content-center">
                                            <div>
                                                <h2 className='common-title4'>"VPM frees me from a stressful but  necessary part of my work."</h2>
                                                <p className='common-p7'>- Dr. Schindler, Dr. Schindler & Associates</p>
                                                <p className='common-p7 float-end mr-5' style={{ color: "#13FF71" }}>Read More</p>
                                            </div>

                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <div>
                                <Container className='feedback-container'>
                                    <Row className='row-centered'>
                                        <Col sm={12} md={12} lg={4} className="d-flex align-items-center justify-content-center">
                                            <img className="feedback-image" src={global} fluid loading="lazy" />
                                        </Col>
                                        <Col sm={12} md={12} lg={7} className="d-flex align-items-center justify-content-center">
                                            <div>
                                                <h2 className='common-title4'>"VPM is very reliable and does an outstanding job. Payroll is always done on time." </h2>
                                                <p className='common-p7'>- Gerald Maister, Global Imaging</p>
                                                <p className='common-p7 float-end mr-5' style={{ color: "#13FF71" }}>Read More</p>
                                            </div>

                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <div>
                                <Container className='feedback-container'>
                                    <Row className='row-centered'>
                                        <Col sm={12} md={12} lg={4} className="d-flex align-items-center justify-content-center">
                                            <img className="feedback-image" src={asif} fluid loading="lazy" />
                                        </Col>
                                        <Col sm={12} md={12} lg={7} className="d-flex align-items-center justify-content-center">
                                            <div>
                                                <h2 className='common-title4'>
                                                    "VPM has been responsive and effective... in an incredibly short period of time they made every effort to make sure we got our corrected payroll out.”
                                                </h2>
                                                <p className='common-p7'>- Asif Khan, Desjardins Insurance Agent</p>
                                                <p className='common-p7 float-end mr-5' style={{ color: "#13FF71" }}>Read More</p>
                                            </div>

                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <div>
                                <Container className='feedback-container'>
                                    <Row className='row-centered'>
                                        <Col sm={12} md={12} lg={4} className="d-flex align-items-center justify-content-center">
                                            <img className="feedback-image" src={mountRoyel} fluid loading="lazy" />
                                        </Col>
                                        <Col sm={12} md={12} lg={7} className="d-flex align-items-center justify-content-center">
                                            <div>
                                                <h2 className='common-title4'>
                                                    “I would recommend VPM, and I’ve sent a lot of clients over already. VPM saves companies like ours money, time and headaches...”
                                                </h2>
                                                <p className='common-p7'>- Dr. Sam Gupta, Owner, Mount Royal Dental</p>
                                                <p className='common-p7 float-end mr-5' style={{ color: "#13FF71" }}>Read More</p>
                                            </div>

                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <div>
                                <Container className='feedback-container'>
                                    <Row className='row-centered'>
                                        <Col sm={12} md={12} lg={4} className="d-flex align-items-center justify-content-center">
                                            <img className="feedback-image" src={nexus} fluid loading="lazy" />
                                        </Col>
                                        <Col sm={12} md={12} lg={7} className="d-flex align-items-center justify-content-center">
                                            <div>
                                                <h2 className='common-title4'>“As we grew, we grew quickly. Administration was taking too much of our time and not where I wanted to spend my efforts... ”</h2>
                                                <p className='common-p7'>- Mark Makowich, President & Owner.Nexus Staffing</p>
                                                <p className='common-p7 float-end mr-5' style={{ color: "#13FF71" }}>Read More</p>
                                            </div>

                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Slider>
                    </Link>
                </div>
            </Container>
        </>
    );
};

export default ClientFeedBack;