import React, { Fragment } from 'react';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import GetInTouch from '../components/GetInTouch/GetInTouch';
import SolutionBanner from '../components/Banner/SolutionBanner';
import bannerImage from '../../src/asset/images/blog/blog-banner.png';
import Notification from '../components/TopNotification/TopNotification';
import CommonFaq from '../components/FAQ/CommonFaq';

const FAQPage = () => {
    const imageData = {
        imageUrl: bannerImage,
        caption: 'faq',
        title: 'Frequently asked questions',
        paragraph: 'Stay tuned for the latest payroll news Stay tuned for the latest payroll news!'
    };

    return (
        <Fragment>

            <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
            <TopNavigation title="FAQ | V-TAC Payroll Management" />
            <SolutionBanner data={imageData} />
            <CommonFaq />
            <div className='mt-5'></div>
            <GetInTouch />
            <TradePart />
            <Footer />

        </Fragment>
    );
}


export default FAQPage;