import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import imgage from '../../asset/images/about/canadian-trust.png';
import { Link } from 'react-router-dom';
import { MdChevronRight } from "react-icons/md";

const CanadianTrust = () => {
    return (
        <>
            <Container fluid style={{ backgroundColor: "#EDF5FF" }}>
                <Container>
                    <Row>
                        <Col lg={5} md={6} sm={12}>
                            <img className="payroll-Image section-gap" alt="vpm-payroll-reports" src={imgage} />
                        </Col>
                        <Col lg={7} md={6} sm={12}>
                            <div className='section-gap'>
                                <p className='common-title2'>
                                    <span style={{ fontSize: "4rem" }}>1000’</span>s of Canadian businesses
                                    trust VPM to transform their payroll processes
                                </p>
                                <p className='common-p9 mt-4'>
                                    With over 25 years of specialized experience, we offer a white-glove service that's tailored to your unique business needs. We understand the stress and strain payroll inaccuracies can cause and are dedicated to lifting this weight off your shoulders.
                                </p>
                                <Link to="/book-demo"><Button className='calculator-button2 mt-3 mb-5' style={{ backgroundColor: "#FF135A", color: "#FFF" }}><span>Book a free consultation</span> <MdChevronRight /></Button>{' '}</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
};

export default CanadianTrust;