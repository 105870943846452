import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const AnualSummary = ({ marginTop }) => {
    const childStyle = {
        marginTop: marginTop,
    };
    return (
        <>
            <Container fluid className="summary-section" style={childStyle}>
                <Container className='text-center'>
                    <Row>
                        <Col lg={3} md={6} xs={6} >
                            <h2 className="countNumber">
                                <CountUp start={0} end={25} duration={2}>
                                    {({ countUpRef, start }) => (

                                        <VisibilitySensor onChange={start}>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                                +
                            </h2>
                            <h5 className="countTitle">
                                years of experience
                            </h5>
                        </Col>

                        <Col lg={3} md={6} xs={6}>

                            <h2 className="countNumber">
                                <CountUp start={0} end={500000} duration={2} separator=",">
                                    {({ countUpRef, start }) => (

                                        <VisibilitySensor onChange={start}>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>

                                    )}
                                </CountUp>
                                +
                            </h2>
                            <h5 className="countTitle" >
                                payments administered annually
                            </h5>
                            {/* <div style={{ width: "30%" }} className="container"><hr className="hrsummary" /></div> */}
                        </Col>

                        <Col lg={3} md={6} xs={6} >
                            <h2 className="countNumber">
                                <CountUp start={0} end={30000} duration={2} separator=",">
                                    {({ countUpRef, start }) => (

                                        <VisibilitySensor onChange={start}>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>

                                    )}
                                </CountUp>
                                +
                            </h2>
                            <h5 className="countTitle">
                                employees paid annually
                            </h5>
                            {/* <div style={{ width: "30%" }} className="container"><hr className="hrsummary" /></div> */}

                        </Col>

                        <Col lg={3} md={6} xs={6} >
                            <h2 className="countNumber">

                                $
                                <CountUp start={0} end={800} duration={2}>
                                    {({ countUpRef, start }) => (

                                        <VisibilitySensor onChange={start}>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>

                                    )}
                                </CountUp>
                                +
                            </h2>
                            <h5 className="countTitle">
                                million handled in client funds
                            </h5>
                        </Col>
                    </Row>
                </Container>

            </Container>
        </>
    );
};

export default AnualSummary;