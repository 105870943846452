import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import subwaybook from '../../asset/images/industry/subway_book.png'
import dental3 from '../../asset/images/industry/dental-3.png'

const PrioritizePatientCare = () => {
    return (
        <>
            <Container fluid style={{ backgroundColor: "#283146" }}>
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <h2 className='common-title text-center section-gap p-3' style={{ color: "#fff" }}>
                                We prioritize you, so you can prioritize patient care
                            </h2>
                            <p className='common-p4' style={{ color: "#C7C7C7", lineHeight: "266.667%",textAlign:"justify" }}>VPM brings a streamlined, stress-free payroll experience to your dental practice. We simplify payroll processing with our quick, user-friendly systems, ensuring every aspect of your payroll - from diverse employee roles, including hygienists and administrative staff, to contractor payments - is handled with utmost accuracy. Our solution is not just about processing payments; it’s about creating a seamless payroll experience tailored to the rhythm of your dental practice.</p>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col lg={4} md={6} sm={6} xs={6}>
                            <h3 className='common-p2' style={{ color: "#13FF71" }}>
                                <img src={subwaybook} alt='prioritize-patient care' /> Quick and Simple Payroll Processing
                            </h3>
                            <p className='common-p mb-5' style={{ color: "#CACACA" }}>Efficiently manage payroll for all staff types, with varied pay rates, with minimal effort.</p>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={6}>
                            <h3 className='common-p2' style={{ color: "#13FF71" }}>
                            <img src={subwaybook} alt='prioritize-patient care' /> Support When You Need It</h3>
                            <p className='common-p' style={{ color: "#CACACA" }}>Easy access to our support team for any payroll queries.</p>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={6}>
                            <h3 className='common-p2' style={{ color: "#13FF71" }}>
                            <img src={subwaybook} alt='prioritize-patient care' /> Employee Self-Service Portal</h3>
                            <p className='common-p mb-5' style={{ color: "#CACACA" }}>Your team can easily access their pay stubs and T4s, ensuring transparency and ease of access.</p>
                        </Col>
                   
                        <Col lg={4} md={6} sm={6} xs={6}>
                            <h3 className='common-p2' style={{ color: "#13FF71" }}>
                            <img src={subwaybook} alt='prioritize-patient care' /> Flexible Pay Rates</h3>
                            <p className='common-p' style={{ color: "#CACACA" }}>Seamlessly manage different pay rates within your practice, ensuring each role from hygienist to admin staff is compensated accurately. </p>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={6}>
                            <h3 className='common-p2' style={{ color: "#13FF71" }}>
                            <img src={subwaybook} alt='prioritize-patient care' /> Efficient Irregular Worker Payment</h3>
                            <p className='common-p' style={{ color: "#CACACA" }}>Pay your on-call staff and contractors, including associate dentists, accurately and on time.</p>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={6} className=''>
                            <h3 className='common-p2' style={{ color: "#13FF71" }}>
                            <img src={subwaybook} alt='prioritize-patient care' /> Contractor Payment Management</h3>
                            <p className='common-p' style={{ color: "#CACACA" }}>Simplify and streamline payments, especially for contracted dentists, ensuring compliance and accuracy.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={8} md={6} sm={6} xs={6}></Col>
                        <Col lg={4} md={6} sm={6} xs={6}>
                            <img src={dental3} />
                        </Col>
                    </Row>
                </Container>
              
            </Container>

        </>
    );
};

export default PrioritizePatientCare;