import React, { useEffect } from 'react';
import { Fragment } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ConditionSection = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);


    return (
        <Fragment>
            <Container className='SectionBetween '>
                <div>
                    <p className='condition-subtitle' style={{ fontSize: "1rem" }}>
                        Benefits when switch to VPM
                    </p>
                </div>
                <Row>
                    <Col lg={2} md={6} sm={12} >
                        <div className='benefit-card mb-5'>
                            <p className='condition-subtitle'>One month free of payroll cost</p>
                            {/* <p className='ConditionDesc'>You have to buy at least one year </p> */}
                        </div>
                    </Col>
                    <Col lg={2} md={6} sm={12} >
                        <div className='benefit-card mb-5'>
                            <p className='condition-subtitle'>Free ROEs for 1st year</p>
                            {/* <p className='ConditionDesc'>You have to buy at least one year </p> */}
                        </div>
                    </Col>
                    <Col lg={2} md={6} sm={12} >
                        <div className='benefit-card mb-5'>
                            <p className='condition-subtitle'>Free T4 for 1st year</p>

                        </div>
                    </Col>

                    <Col lg={2} md={6} sm={12} >
                        <div className='benefit-card mb-5'>
                            <p className='condition-subtitle'>Free set up in 48 hours</p>

                        </div>
                    </Col>
                    <Col lg={2} md={6} sm={12} >
                        <div className='benefit-card mb-5'>
                            <p className='condition-subtitle'>Free cost audit by payroll experts </p>

                        </div>
                    </Col>
                    <Col lg={2} md={6} sm={12} >
                        <div className='benefit-card mb-5'>
                            <p className='condition-subtitle'>Get a Free Tablet*</p>

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} md={6} sm={12}>
                        <div className='condition-card'>
                            <p className='ConditionDesc' >
                                <b>Eligibility requirements to apply for the promotion include:</b></p>
                            <ol className='ConditionDesc pl-3'>
                                <li>Must be a Canadian business with a minimum of 5 employees per payroll.</li>
                                <li>Agrees to work with VPM for a minimum 1 year.</li>
                                <li>Must request a quote online and switch/start to/with VPM for payroll.</li>
                                <li>Have to start before the campaign deadline; which is <span style={{ color: "#1CA9AB" }}><b> 31st March, 2024.</b></span>
                                </li>
                            </ol>
                            <p className='ConditionDesc'>After 3 months of continuous service, a tablet will be sent to the business address provided. The brand of tablet is dependent upon supply available, businesses can expect to receive a brand-new Lenovo, Samsung or Acer tablet.</p>
                        </div>

                    </Col>
                    <Col lg={6} md={6} sm={12}>
                        <div className='condition text-center'>
                            <h2 className='common-title3 mb-5' style={{ color: "#fff" }}>Claim this offer today</h2>
                            <div>
                                <Link to="/request-payroll-quote">
                                    <Button className="free-quote-btn">
                                        <span>Free Quote</span>
                                    </Button>
                                </Link>
                                &nbsp;
                                &nbsp;
                                <Link to="/book-demo">
                                    <Button className='book-demo-btn' style={{ backgroundColor: "#1CA9AB" }}>
                                        <span>Book Demo</span>
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default ConditionSection;
