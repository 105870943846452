import React, { Fragment, lazy, Suspense } from 'react';

// import AnualSummary from '../components/AnualSummary/AnualSummary';
import { Element } from 'react-scroll';
import LazyLoader from "../components/LazyLoader/LazyLoader";
const TopBanner = lazy(() => import('../components/TopBanner/TopBanner'));
const OfferImg = lazy(() => import('../components/Campain/OfferImg'));
const AnualSummary = lazy(() => import('../components/AnualSummary/AnualSummary'));
const AllClient = lazy(() => import('../components/AllClient/AllClient'));
const Dashboard = lazy(() => import('../components/Dashboard/Dashboard'));
const TopNavigation = lazy(() => import('../components/TopNavigation/TopNavigation'));
const Notification = lazy(() => import('../components/TopNotification/TopNotification'));
const FloatImg = lazy(() => import('../components/Dashboard/FloatImg'));
const SolutionUsingVPM = lazy(() => import('../components/Home/SolutionUsingVPM'));
const ContractCommitment = lazy(() => import('../components/Home/ContractCommitment'));
const OfferQuote = lazy(() => import('../components/OfferQuote/OfferQuote'));
const VpmTailored = lazy(() => import('../components/Home/VpmTailored'));
const SliderComp = lazy(() => import('../components/Projects/Slider'));
const VpmClientVideo = lazy(() => import('../components/VpmClientVideo/VpmClientVideo'));
const GetInTouch = lazy(() => import('../components/GetInTouch/GetInTouch'));
const AccessFromAnyDevice = lazy(() => import('../components/AccessFromAnyDevice/AccessFromAnyDevice'));
const SocialMedia = lazy(() => import('../components/SocialMedia/SocialMedia'));
const HomeQuoteCard = lazy(() => import('../components/HomeQuoteCard/HomeQuoteCard'));
const TradePart = lazy(() => import('../components/TradePart/TradePart'));
const Footer = lazy(() => import('../components/Footer/Footer'));

const HomePage = () => {
    return (

        <Fragment>
            <Suspense fallback={<LazyLoader />}>
                <Notification message="Try out our " duration={800000} subscription="Free payroll for one month & a free tablet!" />
            </Suspense>
            <Suspense fallback={<LazyLoader />}>
                <TopNavigation title="Home | V-TAC Payroll Management " />
            </Suspense>
            <Suspense fallback={<LazyLoader />}>
                <TopBanner />
            </Suspense>
            <Suspense fallback={<LazyLoader />} >
                <OfferImg />
            </Suspense>
            <Suspense fallback={<LazyLoader />}>
                <AnualSummary marginTop="" />
            </Suspense>
            <Suspense fallback={<LazyLoader />}>
                <AllClient />
            </Suspense>
            <Suspense fallback={<LazyLoader />}>
                <Dashboard />
            </Suspense>

            <Suspense fallback={<LazyLoader />}>
                <FloatImg />
            </Suspense>
            <Suspense fallback={<LazyLoader />}>
                <SolutionUsingVPM />
            </Suspense>
            <Suspense fallback={<LazyLoader />}>
                <Element name="allOneApp">
                    <VpmTailored />
                </Element>
            </Suspense>

            <Suspense fallback={<LazyLoader />}>
                <ContractCommitment />
            </Suspense>

            <Suspense fallback={<LazyLoader />}>
                <OfferQuote />
            </Suspense>
            <Suspense fallback={<LazyLoader />}>
                <SliderComp />
            </Suspense>
            <Suspense fallback={<LazyLoader />}>
                <Element name="successStory">
                    <VpmClientVideo />
                </Element>
            </Suspense>
            <Suspense fallback={<LazyLoader />}>
                <GetInTouch />
            </Suspense>
            <Suspense>
                <AccessFromAnyDevice />
            </Suspense>
            <Suspense fallback={<LazyLoader />}>
                <SocialMedia />
            </Suspense>
            <Suspense fallback={<LazyLoader />}>
                <HomeQuoteCard />
            </Suspense>
            <Suspense fallback={<LazyLoader />}>
                <TradePart />
            </Suspense>
            <Suspense fallback={<LazyLoader />}>
                <Footer />
            </Suspense>


        </Fragment >
    );
};

export default HomePage;
