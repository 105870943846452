import { useState, useEffect } from 'react';
import { Container, Button, Row, Col, InputGroup, Form } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import { FaArrowRightLong } from "react-icons/fa6";
import axios from 'axios';
import ca from '../../asset/images/accountant/ca.png';

const T4Form = () => {
    const [validated, setValidated] = useState(false);
    const [recapValue, setRecapValue] = useState('');
    const [isVerified, setIsVerified] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        companyName: '',
        totalt4slips: '',
        t4year: "2023",
        slipType: "T4",

    });

    const recapchaValue = (value) => {
        // console.log("Captcha value:", value);
        setRecapValue(value);
        setIsVerified(true);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    // clear form data
    const clearForm = () => {
        setFormData({
            name: '',
            email: '',
            phone: '',
            companyName: '',
            totalt4slips: '',
            t4year: '',
            slipType: ""

        })
    }

    // Scroll to top on page load
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const t4FormSubmit = async (event) => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return;
        }

        try {
            const response = await axios.post('https://api.vtacpayroll.ca/t4Request', formData);
            // const response = await axios.post('http://localhost:5000/t4Request', formData);

            if (response.status === 200) {
                clearForm();
                window.location.href = "https://www.vtacpayroll.ca/t4-request-success";
                // window.location.href = "http://localhost:3000/t4-request-success";
                console.log('Form data submitted successfully!');
            }
        } catch (error) {
            console.error('Error submitting form data:', error.message);
        }
    };
    return (
        <>
            <Container fluid style={{ backgroundColor: "#FFEFEF" }}>
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className="partnarCard section-gap" id="RQForm" style={{ backgroundColor: "#252E44" }}>
                                <h2 className="common-title text-center mt-5" style={{ color: "#fff" }}>Request your company’s year-end<br></br> tax form quotation</h2>
                                <p className='common-p7 text-center' style={{ color: "#fff", fontSize: "1.125rem" }}>If you need year-end tax form processing service for your company like T4 or T4A,<br></br> simply fill up the form and one of our consultants will get back to you within 48 hours.</p>
                                <div className="form-container">
                                    <Form noValidate validated={validated} onSubmit={t4FormSubmit}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="validationCustom01">
                                                <Form.Label className='common-p'>Contact Person Name<span style={{ color: "red" }}>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Your Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Provide Your Name
                                                </Form.Control.Feedback>

                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="validationCustomUsername">
                                                <Form.Label className='common-p'>Your Email<span style={{ color: "red" }}>*</span></Form.Label>
                                                <InputGroup hasValidation>
                                                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Your Email Address"
                                                        aria-describedby="inputGroupPrepend"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Provide Your Email Address
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>

                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="validationCustomUsername">
                                                <Form.Label className='common-p'>Phone Number<span style={{ color: "red" }}>*</span></Form.Label>
                                                <InputGroup hasValidation>
                                                    <InputGroup.Text id="inputGroupPrepend"><img src={ca} alt='canada phone' /></InputGroup.Text>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder=" +1"
                                                        aria-describedby="inputGroupPrepend"
                                                        name="phone"
                                                        value={formData.phone}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Provide Your Phone Number
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="validationCustom01">
                                                <Form.Label className='common-p'>Company Name<span style={{ color: "red" }}>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Your Company Name"
                                                    name="companyName"
                                                    value={formData.companyName}
                                                    onChange={handleChange}
                                                    required

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Provide Your Company Name
                                                </Form.Control.Feedback>

                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="validationCustom01">
                                                <Form.Label className='common-p'>Slip Type<span style={{ color: "red" }}>*</span></Form.Label>
                                                <select className='quoteDropdown' name='slipType' value={formData.slipType} onChange={handleChange} required>
                                                    {/* <option value={" "}>select</option> */}
                                                    <option value={"T4"}>T4</option>
                                                    <option value={"T4A"}>T4A</option>
                                                    <option value={"T5018"}>T5018</option>
                                                    <option value={"RL1"}>RL1</option>
                                                </select>
                                                <Form.Control.Feedback type="invalid">
                                                    Please Provide Slip Type
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="validationCustom01">
                                                <Form.Label className='common-p'>How Many Slips<span style={{ color: "red" }}>*</span></Form.Label>
                                                <Form.Control
                                                    required
                                                    type="number"
                                                    placeholder="How Many Slips"
                                                    name="totalt4slips"
                                                    value={formData.totalt4slips}
                                                    onChange={handleChange}

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Provide Total T4/T4A*
                                                </Form.Control.Feedback>

                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="validationCustom01">
                                                <Form.Label className='common-p'>T4 year<span style={{ color: "red" }}>*</span></Form.Label>
                                                <select className='quoteDropdown' name='t4year' value={formData.t4year} onChange={handleChange} required>
                                                    <option value={"2023"}>2023</option>
                                                    <option value={"2022"}>2022</option>
                                                    <option value={"2021"}>2021</option>
                                                    <option value={"2020"}>2020</option>
                                                </select>
                                                <Form.Control.Feedback type="invalid">
                                                    Please Provide T4 Year
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>

                                        <div className='g-recaptcha-container mt-4'>
                                            <ReCAPTCHA
                                                // vtacpayroll.ca
                                                sitekey="6LdW8FcjAAAAABvBHj1pzxWTOu3vqp4Hd5UIr9jn"
                                                // vtacpayrol.com
                                                //sitekey="6LewqFUjAAAAADXJfTyYT40ysEYNHl6eX3fZbf9L"
                                                // test
                                                // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                                onChange={recapchaValue}
                                                className="g-recaptcha"
                                            />
                                        </div>

                                        <Button type="submit" className='t4-btn mt-3 mb-5' disabled={!isVerified}>Send Request <FaArrowRightLong /></Button>
                                    </Form>
                                </div>
                            </div>
                            {/* <div>
                                <img src={doll} style={{ width: "100%", height: "auto" }} className='ml-2' />
                            </div> */}
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
};

export default T4Form;