import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';

const T4Faq = () => {
    return (
        <div>
            <Container fluid style={{ backgroundColor: "#FFEFEF" }}>
                <Container>
                    <Row >
                        <Col>
                            <h2 className='common-title2 text-center section-gap mb-5 '>FAQ</h2>
                            <Accordion defaultActiveKey="0" flush className='mb-5'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className='common-p5'><strong>When can I expect a response from VPM once I fill up the T4/T4A service request form?</strong></Accordion.Header>
                                    <Accordion.Body className='common-p'>
                                        Within 48 hours.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header className='common-p5'><strong>What is the process of T4/T4A service?</strong></Accordion.Header>
                                    <Accordion.Body className='common-p'>
                                        Our consultants will share the required details for getting your T4/T4 service done.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header className='common-p5'>
                                        <strong>Will VPM give a copy of T4/T4As to all of my employees individually?</strong>
                                    </Accordion.Header>
                                    <Accordion.Body className='common-p'>
                                        VPM will give business owners all T4/T4A as well as summaries. If needed/requested, VPM can send email copies/attachments to all of your employees as well.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header className='common-p5'>
                                        <strong>Will VPM submit T4 copies to CRA on my behalf?</strong>
                                    </Accordion.Header>
                                    <Accordion.Body className='common-p'>
                                        Yes! VPM will submit T4/T4As to CRA on your behalf.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header className='common-p5'>
                                        <strong>What info I will need to provide VPM to get my T4/T4A job done?</strong>
                                    </Accordion.Header>
                                    <Accordion.Body className='common-p'>
                                        VPM consultants will provide your details/formats of data that you will need to provide.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header className='common-p5'>
                                        <strong>How much time VPM will need to get the T4/T4A processing and submission done?</strong>
                                    </Accordion.Header>
                                    <Accordion.Body className='common-p'>
                                        Time may depend on available information/data. Given VMP had all required data, it may take, ideally between 48 to 72 business hours to get things done. However, a dedicated consultant will give an accurate idea about time.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header className='common-p5'>
                                        <strong>If I need to edit/modify any T4, will VPM assist me? </strong>
                                    </Accordion.Header>
                                    <Accordion.Body className='common-p'>
                                        Yes VPM will assist you for any modification. However, some modification may not be guaranteed by VPM if the modification request is made after VPM submits the T4 to CRA. VPM consultants will give you sincere and best possible solutions in any situation.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </Container >
        </div>
    );
};

export default T4Faq;