import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class InsuranceAgentBanner extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="nexasStaffingBanner  p-0">
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <h1 className='common-title section-gap text-center' style={{ color: "#fff" }}>
                                VPM GIVES AN INSURANCE AGENT THE<br></br> TIME TO FOCUS ON CLIENT<br></br> RELATIONSHIPS
                            </h1>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default InsuranceAgentBanner;