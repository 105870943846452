import React from 'react';
import Notification from '../components/TopNotification/TopNotification';
import banner from '../../src/asset/images/quote/quote-banner2.png';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import Banner from '../components/Banner/Banner';
import BookDemo from '../components/BookDemo/BookDemo';
import OfferQuote from '../components/OfferQuote/OfferQuote';
import Footer from '../components/Footer/Footer';
import TradePart from '../components/TradePart/TradePart';
import VpmClientVideo from '../components/VpmClientVideo/VpmClientVideo';
import ClientFeedBack from '../components/ClientFeedBack/ClientFeedBack';
import AllClient from '../components/AllClient/AllClient';
import Campain from '../components/Campain/Campain';

const BookDemoPage = () => {
    const imageData = {
        imageUrl: banner,
        caption: 'Book Demo',
        title: 'Payroll Revolution: Building Relationships',
        paragraph: 'Request your quote today and count on our commitment to delivering your free quote within 24 hours, providing you with timely, essential information for your business needs.'
    };
    return (
        <>
           <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
            <TopNavigation title="Book Demo | V-TAC Payroll Management " />
            <Banner data={imageData} />
            {/* <Campain /> */}
            <BookDemo />
            <ClientFeedBack />
            {/* <div className='mt-.5'></div>
            <OfferQuote /> */}
            {/* <VpmClientVideo /> */}
            <AllClient />
            <TradePart />
            <Footer />

        </>
    );
};

export default BookDemoPage;