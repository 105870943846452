import React, { Component, Fragment } from 'react';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import CareerBanner from '../components/Banner/CareerBanner';
import AnualSummary from '../components/AnualSummary/AnualSummary';
import Notification from '../components/TopNotification/TopNotification';

class CareersPage extends Component {
    render() {
        return (
            <Fragment>
                <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
                <TopNavigation title="Careers | V-TAC Payroll Management" />
                <CareerBanner />
                <AnualSummary marginTop="4rem" />
                <TradePart />
                <Footer />
            </Fragment>
        );
    }
}

export default CareersPage;