import React, { Fragment } from 'react';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import PrivacyPolicyBanner from '../components/Banner/PrivacyPolicyBanner';
import GetInTouch from '../components/GetInTouch/GetInTouch';
import FAQforPrivacy from '../components/FAQforPrivacy/FAQforPrivacy';
import Notification from '../components/TopNotification/TopNotification';

const PrivacyPolicyPage = () => {

    return (
        <Fragment>
            <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
            <TopNavigation title="Privacy policy | V-TAC Payroll Management" />
            <PrivacyPolicyBanner />
            <FAQforPrivacy />
            <div className='mt-5'></div>
            <GetInTouch />
            <TradePart />
            <Footer />
        </Fragment>
    );
}


export default PrivacyPolicyPage;