import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import Accordion from 'react-bootstrap/Accordion';
import Logo from '../../asset/images/home/simple-icons_icon.svg';

import AOS from 'aos';
import 'aos/dist/aos.css';

class HomeQuoteCard extends Component {
    constructor() {
        super();
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            company: "",
            industry: "",
            numberOfEmployees: "",
            errors: {},
            isVarified: false,
        }

    }


    // handleChange = event => {
    //     this.setState({ [event.target.name]: event.target.value });
    //     //this.setState({isVarified:true});

    // }
    handleChange = (event) => {
        const { name, value } = event.target;
        const errors = { ...this.state.errors };

        if (name === 'numberOfEmployees') {
            //   const intValue = parseInt(value, 10); // Parse the input value as an integer
            errors[name] = value === '' || Number(value) === 0 ? 'Please provide valid number of employees' : '';
        } else {
            errors[name] = value.trim() === '' ? `Please provide ${name}` : '';
        }

        this.setState({
            [name]: value,
            errors: errors,
        });
    };


    // form validation
    validateForm = () => {
        const { firstName, lastName, email, company, industry, numberOfEmployees } = this.state;
        const errors = {
            firstName: firstName.trim() === '' ? 'Please provide firstName' : '',
            lastName: lastName.trim() === '' ? 'Please provide lastName' : '',
            email: email.trim() === '' ? 'Please provide email' : '',
            company: company.trim() === '' ? 'Please provide company' : '',
            // industry: industry.trim() === '' ? 'Please provide industry' : '',
            numberOfEmployees: numberOfEmployees.trim() === '' ? 'Please provide employees number' : '',
        };

        this.setState({ errors });
        return Object.values(errors).every((error) => error === '');
    };

    clearForm = () => {
        this.setState({
            firstName: "",
            lastName: "",
            email: "",
            company: "",
            industry: "",
            numberOfEmployees: ""

        })
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        AOS.init();
        document.getElementById("RQForm").style.display = "block";
        document.getElementById("SuccessMSgDiv").style.display = "none";
    }

    bookDemoFormSubmit = (event) => {
        event.preventDefault();
        //console.log(this.state)

        const isValid = this.validateForm();
        if (isValid == true) {

            axios.post("https://api.vtacpayroll.ca/creatBookDemo", this.state).then(Response => {
            // axios.post("http://localhost:5000/creatBookDemo", this.state).then(Response => {
                this.clearForm();
                window.location.href = "https://www.vtacpayroll.ca/free-book-demo-request-success";
                // window.location.href = "http://localhost:3000/free-book-demo-request-success";
                // document.getElementById("RQForm").style.display = "none";
                // document.getElementById("SuccessMSgDiv").style.display = "block";

            }).catch(error => {
                console.log(error);
            })
        }
    }

 


    render() {

        const { firstName, lastName, email, company, numberOfEmployees, industry, errors } = this.state;
        return (
            <Fragment>
                <Container className='quote-container'>
                    <Container>
                        <Row>
                            <Col sm={12} md={12} lg={8}>
                                {/* <div data-aos="fade-down"
                                    data-aos-easing="linear"
                                    data-aos-duration="1000"
                                    data-aos-once="true">
                                    <h1 className='quote-title'>
                                        Free quote
                                    </h1>

                                </div> */}
                                <div className="homeQuoteCard mt-5 " id="RQForm" data-aos="fade-left"
                                    data-aos-anchor="#example-anchor"
                                    data-aos-offset="500"
                                    data-aos-duration="2000">
                                    <h1 className='quote-title text-center'>
                                        Book Demo
                                    </h1>
                                    <div className='quote-heading-div'>
                                        <h2 className='quote-heading'>Get your customized payroll quote</h2>
                                        <p > Experience how VPM can streamline your payroll process effectively and affordably.
                                            Fill in a few details and receive a no-obligation quote that aligns with your specific requirements.</p>
                                    </div>
                                    <Container style={{ backgroundColor: "#252E44" }}>
                                        <h3 className="quoteCardTitle pt-5 mb-5">Book Demo Request Form</h3>

                                        <Form onSubmit={this.bookDemoFormSubmit}>

                                            <Row className="mb-3">

                                                <Form.Group as={Col} controlId="formGridEmail">
                                                    <Form.Label>First name<span style={{color:"red"}}>*</span></Form.Label>
                                                    <Form.Control type="text" title='First Name' name='firstName' value={firstName} onChange={this.handleChange} />
                                                    {errors.firstName && <div style={{ color: 'red' }}>{errors.firstName}</div>}
                                                    {/* <span style={{ color: 'red' }}>{errors.firstName}</span> */}
                                                    {/* <span style={{ color: 'red' }}>{errors.firstNameEmpty}</span> */}
                                                </Form.Group>

                                                <Form.Group as={Col} >
                                                    <Form.Label>Last name<span style={{color:"red"}}>*</span></Form.Label>
                                                    <Form.Control type="text" title='last Name' name='lastName' value={lastName} onChange={this.handleChange} />
                                                    {errors.lastName && <div style={{ color: 'red' }}>{errors.lastName}</div>}
                                                   
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-3">
                                                <Form.Group as={Col}>
                                                    <Form.Label>Email<span style={{color:"red"}}>*</span></Form.Label>
                                                    <Form.Control type="email" title='Email Address' name='email' value={email} onChange={this.handleChange} />
                                                    {errors.email && <div style={{ color: 'red' }}>{errors.email}</div>}
                                                    
                                                </Form.Group>

                                                <Form.Group as={Col} >
                                                    <span style={{ color: 'red' }}>{errors.postalCodeEmpty}</span>
                                                    <Form.Label>Company Name<span style={{color:"red"}}>*</span></Form.Label>
                                                    <Form.Control type="text" title='Company' name='company' value={company} onChange={this.handleChange} />
                                                    {errors.company && <div style={{ color: 'red' }}>{errors.company}</div>}
                                                  
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-3">
                                                <Form.Group as={Col} >
                                                    <Form.Label>Industry</Form.Label>
                                                    <Form.Control type="text" title='industry' name='industry' value={industry} onChange={this.handleChange} />
                                                    {/* {errors.industry && <div style={{ color: 'red' }}>{errors.industry}</div>} */}
                                                    
                                                </Form.Group>

                                                <Form.Group as={Col} >
                                                    <Form.Label>Number of Employees<span style={{color:"red"}}>*</span></Form.Label>
                                                    <Form.Control type="number" min={0} name='numberOfEmployees' value={numberOfEmployees} onChange={this.handleChange} />
                                                    {errors.numberOfEmployees && <div style={{ color: 'red' }}>{errors.numberOfEmployees}</div>}
                                                    
                                                </Form.Group>
                                            </Row>

                                            <Button name='submitBtn' className="RequestQuoteBtn  mt-5 mb-5" variant="primary" type="submit" >
                                                <span>Book Demo</span>
                                            </Button>

                                        </Form>
                                    </Container>
                                </div>
                                <div id='SuccessMSgDiv' className='successCard'>
                                    <h1 className='SuccessMsg'>Thank you for submitting your request for a quote. We will get back to you shortly.</h1>
                                    <p className='SuccessMsgDesc'>if you would like to speak with someone right away, feel free to call us  on our toll number at:</p>
                                    <p className='SuccessMsgDesc mb-5' style={{ color: "#E6001F", cursor: "pointer" }}><strong>1-866-315-8822</strong></p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Fragment>
        );
    }
}

export default HomeQuoteCard;