import React, { Component, Fragment } from 'react';
import MountClientFeedback1 from '../components/MountRoyalDental/MountClientFeedback1';
import MountClientFeedback2 from '../components/MountRoyalDental/MountClientFeedback2';
import MountRoyalDentalBanner from '../components/MountRoyalDental/MountRoyalDentalBanner';
import MountRoyalDentalSec1 from '../components/MountRoyalDental/MountRoyalDentalSec1';
import MountRoyalDentalsec2 from '../components/MountRoyalDental/MountRoyalDentalsec2';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import Notification from '../components/TopNotification/TopNotification';

class MountRoyalDentalPage extends Component {
    render() {
        return (
            <Fragment>
                <Notification message="Try out our " duration={800000} subscription="Free payroll for one month & a free tablet!" />
                <TopNavigation title="Mount Royal Dental | V-TAC Payroll Management" />
                <MountRoyalDentalBanner />
                <MountRoyalDentalSec1 />
                <MountClientFeedback1 />
                <MountRoyalDentalsec2 />
                <MountClientFeedback2 />
                <TradePart />
                <Footer />
            </Fragment>
        );
    }
}

export default MountRoyalDentalPage;