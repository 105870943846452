import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const AboutUsBanner = (props) => {
    return (
        <>
            <Container fluid className='about-container'>
                <Container>
                    <Row>
                        <Col lg={12} md={12} xs={12} className='mt-5'>
                            <div className='about-bnr-card1 '>
                                <h1>
                                    We Streamline your payroll processes & handle all the complexities, so you can skip all the headaches and focus on your business
                                </h1>
                            </div>
                        </Col>
                    </Row>

                </Container>
                <div class="container text-center" style={{ marginTop: "15rem" }}>
                    <div class="row row-cols-2 row-cols-lg-5 g-2 g-lg-3">
                        <div class="col">
                            <div className='about-bnr-card p-3'>
                                <p>
                                    Administering
                                    over 500,000
                                    payments
                                    annually
                                </p>
                            </div>
                        </div>
                        <div class="col">
                            <div className='about-bnr-card'>
                                <p>
                                    Paying over
                                    30,000
                                    employees
                                    annually
                                </p>
                            </div>
                        </div>
                        <div class="col">
                            <div className='about-bnr-card'>
                                <p>
                                    Presence Across Canada
                                </p>
                            </div>
                        </div>
                        <div class="col">
                            <div className='about-bnr-card'>
                                <p>
                                    Handling
                                    $800 million
                                    in client funds
                                </p>
                            </div>
                        </div>
                        <div class="col">
                            <div className='about-bnr-card'>
                                <p>
                                    Expert Human Support Guaranteed
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default AboutUsBanner;