import React from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { FaShieldAlt } from 'react-icons/fa';
import { FcMultipleDevices } from 'react-icons/fc';
import { SiEasyeda } from 'react-icons/si';
import { Link } from 'react-router-dom';
import promotion from '../../asset/images/quote/TABLET.webp';

const ConditionBanner = () => {
    const iconClassName = 'mr-2';
    const textStyle = {
        // whiteSpace: 'nowrap',
        color: "#FF135A",
        fontSize: ".9rem",
        fontFamily: "Onest",
        fontWeight: ' 400',
        lineHeight: '1.5rem',
        marginTop: '10px',
        // marginRight: '2rem'
    };
    return (
        <>
            <Container fluid style={{ backgroundColor: "#FFEFEF" }}>
                <Container className=''>
                    <Row className=''>
                        <Col lg={7} md={6} xs={12}>

                            <h1 className='common-title2 mt-5' style={{ color: "#0F8587", fontSize: "1.9rem" }}>
                            Free payroll for one month & <span style={{color:'#FF135A'}}>a free tablet!</span>
                            </h1>
                            <p className='common-p4 mb-4'>
                                Switch to VPM today and get one month of free payroll service without any condition or contract! There are more to make sure you get the best of its class value of money payroll service package in Canada. Free 1st year T4, free 1st year RoEs and also a free Tablet* as a token of appreciation for your business!
                            </p>

                            <Row>
                                <Col lg={4} md={4} sm={4} xs={6} className="mb-5">
                                    <SiEasyeda size={20} className={iconClassName} style={{ color: "#9D87F6" }} />
                                    <span className='common-p' style={{ color: "#9D87F6" }}>Easy to access</span>
                                </Col>

                                <Col lg={4} md={4} sm={4} xs={6} className="mb-3">
                                    <FaShieldAlt size={20} className={iconClassName} style={{ color: "#05C27F" }} />
                                    <span className='common-p' style={{ color: "#05C27F" }}>Highly Secured</span>
                                </Col>

                                <Col lg={4} md={4} sm={4} xs={6} className="mb-3">
                                    <FcMultipleDevices size={20} className={iconClassName} />
                                    <span className='common-p' style={{ color: "#f33f75" }} >Any Device Access</span>
                                </Col>

                            </Row>
                        </Col>
                        <Col lg={5} md={6} xs={12}>
                            <img src={promotion} className='sub-banner-img float-end mt-3 mb-2 p-2 ' alt="free-tablet"  fluid
                            />
                        </Col>
                    </Row>

                </Container>
            </Container>
        </>
    );
};

export default ConditionBanner;