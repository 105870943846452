import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import t41 from '../../asset/images/T4/004.webp';
import t42 from '../../asset/images/T4/0013.webp';
import t43 from '../../asset/images/T4/0014.webp';

const T4Details = () => {
    return (
        <>
            <Container fluid style={{ backgroundColor: "#fff" }}>
                <Container >
                    <Row>
                        <Col lg={6} md={6} sm={12} className='section-gap'>
                            <img className="payroll-Image" style={{width:"110%"}} alt="t4-details" src={t41} />
                        </Col>
                        <Col lg={6} md={6} sm={12} className='section-gap'>
                            <div>
                                <p className='common-title'>Unique value added service</p>
                                <p className='payroll-text'>VPM’s year end tax form processing  services including T4 and T4A are not tagged with any regular payroll service. This is a unique and separate value added service.</p>

                            </div>
                        </Col>
                    </Row>
                </Container >
            </Container >
            <Container fluid style={{ backgroundColor: "#FFEFEF" }}>
                <Container>
                    <Row>

                        <Col lg={6} md={6} sm={12}>
                            <div>
                                <p className='common-title section-gap' >No agreement needed</p>
                                <p className='payroll-text'>You do not have to sign up for regular payroll service to avail the year end T4 service. Flexible, no contract service!</p>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <img className="payroll-Image" style={{marginTop:"-4rem"}} alt="t4-details" src={t42} />
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid style={{ backgroundColor: "#fff" }}>
                <Container >
                    <Row>
                        <Col lg={6} md={6} sm={12} className='section-gap'>
                            <img className="payroll-Image text-center " style={{height:"110%"}} alt="t4-details" src={t43} />
                        </Col>
                        <Col lg={6} md={6} sm={12} className='section-gap'>
                            <div>
                                <p className='common-title'>Simple and straightforward process</p>
                                <p className='payroll-text'>To avail year end T4/T4A service, you will send a few details like number of employees your need to be covered and VPM will send you a quote and delivery timeline to process and deliver. This is it! .</p>
                            </div>
                        </Col>
                    </Row>
                </Container >
            </Container >
        </>
    );
};

export default T4Details;