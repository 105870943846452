import React from 'react';
import TopNavigation from '../../components/TopNavigation/TopNavigation';
import TradePart from '../../components/TradePart/TradePart';
import Footer from '../../components/Footer/Footer';
import { Container } from 'react-bootstrap';
import GetInTouch from '../../components/GetInTouch/GetInTouch';
import Notification from '../../components/TopNotification/TopNotification';
import PayrollSolutionsStreamlineYourBusiness from '../../components/Blogs/4_PayrollSolutionsStreamlineYourBusiness';


const PayrollSolutionsStreamlineYourBusinessPage = () => {
    return (
        <>
            <Container fluid="true" className='accContainer'>
                <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
                <TopNavigation title="Payroll Solutions: Streamline Your Business Operations With Technology | V-TAC Payroll Management" />
              
                <PayrollSolutionsStreamlineYourBusiness />
                <GetInTouch />
                <TradePart />
                <Footer />
            </Container>

        </>
    );
};

export default PayrollSolutionsStreamlineYourBusinessPage;