import React, { Fragment } from 'react';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import VpmBlog from '../components/VpmBlog/VpmBlog';
import { Container } from 'react-bootstrap';
import GetInTouch from '../components/GetInTouch/GetInTouch';
import IndustryBanner from '../components/Banner/IndustryBanner';
import bannerImage from '../../src/asset/images/blog/blog-banner.png';
import Notification from '../components/TopNotification/TopNotification';

const IndustryNewsPage = () => {
    const imageData = {
        imageUrl: bannerImage,
        caption: 'Industry News',
        title: 'Stay ahead with the latest in payroll and HR.',
        paragraph: 'Explore current trends, updates, and insights shaping the current world.'
    };

    return (
        <Fragment>
            <Container fluid="true" className='accContainer'>
                <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
                <TopNavigation title="Industry News | V-TAC Payroll Management" />
                <IndustryBanner data={imageData} />
                <VpmBlog />
                <GetInTouch />
                <TradePart />
                <Footer />
            </Container>

        </Fragment>
    );
}

export default IndustryNewsPage;