import React from 'react';
import NexasClientFeedback1 from '../components/NexasStaffing/NexasClientFeedback1';
import NexasClientFeedback2 from '../components/NexasStaffing/NexasClientFeedback2';
import NexasStaffingBanner from '../components/NexasStaffing/NexasStaffingBanner';
import NexasStaffingSec1 from '../components/NexasStaffing/NexasStaffingSec1';
import NexasStaffingSec2 from '../components/NexasStaffing/NexasStaffingSec2';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import Notification from '../components/TopNotification/TopNotification';

const NexasStaffingPage = () => {
    return (
        <>
            <Notification message="Try out our " duration={800000} subscription="Free payroll for one month & a free tablet!" />
            <TopNavigation title="Nexus Staffing | V-TAC Payroll Management" />
            <NexasStaffingBanner />
            <NexasStaffingSec1 />
            <NexasClientFeedback1 />
            <NexasStaffingSec2 />
            <NexasClientFeedback2 />
            <TradePart />
            <Footer />
        </>
    );
};

export default NexasStaffingPage;
