import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {FaYoutube} from "react-icons/fa";
import { BiLogoLinkedin, BiLogoFacebook } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";


const SocialMedia = () => {
    return (
        <>
            <Container fluid style={{backgroundColor:"#779EFF"}}>
                <Container>
                    <Row className='text-center'>
                        <Col lg={12} md={12} xs={12}>
                            <p className='social-p text-center mt-5' style={{}}>you can visit our social media to know our <br></br>latest products and services update</p>
                            <a href="https://www.facebook.com/vtacpayroll/" target='_blank' className='' > <BiLogoFacebook className="fa fa-facebook" /></a>
                            <a href="https://twitter.com/vtacpayroll" target='_blank' ><FaXTwitter className='fa fa-twitter' /></a>
                            <a href="https://www.linkedin.com/company/v-tac-payroll-management/" target='_blank' className=""><BiLogoLinkedin className='fa fa-linkedin' /></a>
                            <a href="https://www.youtube.com/@v-tacpayrollmanagement3092/videos" target='_blank' className='' ><FaYoutube className='fa fa-youtube' /></a>
                        </Col>
                        <div className='mb-5'></div>
                    </Row>
                </Container>
            </Container>

        </>
    );
};

export default SocialMedia;