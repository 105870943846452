import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
const NexasClientFeedback1 = () => {
    return (
        <>
            <Container fluid={true} className="nexasClientSayCard section-gap p-0">
                <Row>
                    <Col sm={12} md={12} lg={12}>
                        <div className='container'>
                            <p className='nexasCardDesc section-gap'>
                                “As we grew, we grew quickly. Administration was taking too much of our time and not where I wanted to spend my efforts. I wanted to use that time to find new clients we could offer our services to and recruit strong candidates for those positions.”
                            </p>
                            <p className='nexasCardName mt-4 mb-4'>Mark Makowich, President & Owner</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default NexasClientFeedback1;
