import React from 'react';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import TradePart from '../components/TradePart/TradePart';
import Footer from '../components/Footer/Footer';
import IndustryExpertise from '../components/Banner/IndustryExpertise';
import AnualSummary from '../components/AnualSummary/AnualSummary';
import banner from '../../src/asset/images/industry/dental1.png';
import OfferQuote from '../components/OfferQuote/OfferQuote';
import Notification from '../components/TopNotification/TopNotification';
import CommonFaq from '../components/FAQ/CommonFaq';
import Payrollsolutions from '../components/DentalOffice/Payrollsolutions';
import ContractCommitment from '../components/Home/ContractCommitment';
import GetStartedVPM from '../components/GetStartedVPM/GetStartedVPM';
import VpmClientVideo from '../components/VpmClientVideo/VpmClientVideo';
import PrioritizePatientCare from '../components/DentalOffice/PrioritizePatientCare';



const DentalOfficesPage = () => {
    const imageData = {
        imageUrl: banner,
        caption: 'No contracts. No commitments',
        title: 'Get effortless and efficient payroll processing that gives you more time for patient care.',
        paragraph: 'Quick, accurate, and flexible payroll solutions for busy dental professionals and their practice.'

    };

    return (
        <>
            <Notification message="Try out our " duration={800000} subscription="Free one month of payroll & get a tablet !" />
            <TopNavigation title="Dental Offices | V-TAC Payroll Management" />
            <IndustryExpertise data={imageData} />
            <AnualSummary marginTop="-3rem" />
            <Payrollsolutions />
            <div className='section-gap'></div>
            <OfferQuote />
            <PrioritizePatientCare />
            <ContractCommitment />
            <GetStartedVPM />
            <VpmClientVideo />
            <CommonFaq />
            <div className='section-gap'></div>
            <TradePart />
            <Footer />
        </>
    );
};

export default DentalOfficesPage;