import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
const NexasClientFeedback2 = () => {
    return (
        <>
            <Container fluid={true} className="nexasClientSayCard section-gap p-0">
                <Row>
                    <Col sm={12} md={12} lg={12}>
                        <div className='container'>
                            <h3 className='nexasCardTitle section-gap'>PAVING THE WAY TO <span className='spancolor'>GROWTH</span></h3>
                            <p className='nexasCardDesc mt-3'>
                                With the flexibility that VPM provides, Nexus Staffing can customize their payroll based on their infrastructure, while leaving money in their pockets that would otherwise go to expensive payroll providers. Equipped with the right tools, Mark can now take his business to the next level.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default NexasClientFeedback2;