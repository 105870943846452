import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import img1 from '../../asset/images/about/002.webp';
import img2 from '../../asset/images/about/003.webp';
import img3 from '../../asset/images/about/004.webp';
import img4 from '../../asset/images/about/005.webp';
import img5 from '../../asset/images/about/006.webp';
import img6 from '../../asset/images/about/007.webp';

const DetailsPopup = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <p onClick={handleShow} className='learn-more'>Learn More</p>
            <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Header closeButton style={{ backgroundColor: "#fff" }}>
                    <Modal.Title >{props.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='about-description' style={{ color: "#032B43" }}>{props.text}</p>
                </Modal.Body>
            </Modal>
        </>
    );
}

const ReputableProfessionals = () => {

    return (
        <>
            <Container fluid style={{ backgroundColor: "#032B43" }}>
                <Container>
                    <h3 className='common-title text-center pt-5 mb-5' style={{ color: "#EEB7BA" }}>
                        Reputable Professionals dedicated to helping you achive your financial goals.</h3>
                    <Row className='section-gap'>
                        <Col lg={7} md={6} sm={12} className='mt-5'>

                            <p className='about-sub-title'>One-stop payroll service contacts</p>
                            <p className='about-description'>
                                Clients are matched with professional, experienced, and friendly payroll consultants. Our Payroll Consultants are accessible by phone or e-mail and strive to build excellent client rapport. We strive to deliver quality services on...
                                <div>
                                    <DetailsPopup text="Clients are matched with professional, experienced, and friendly payroll consultants. Our Payroll Consultants are accessible by phone or e-mail and strive to build excellent client rapport. We strive to deliver quality services on which you can continue to depend on. Our consultants continually update their knowledge of new government regulations and legislation in order to maintain your confidence in our payroll services. We seek to foster longstanding partnerships and work together with our clients to ensure their needs are met. Our success is reflected in the success of our clients."
                                        heading="One-stop payroll service contacts"
                                    />
                                </div>
                            </p>
                        </Col>
                        <Col lg={5} md={6} sm={12} className='mt-5'>
                            <div>
                                <img
                                    src={img1}
                                    alt="Reputable professional service"
                                    className=''
                                />
                            </div>
                        </Col>
                        <Col lg={5} md={6} sm={12} className='mt-5'>
                            <div>
                                <img
                                    src={img2}
                                    alt="Reputable professional service"
                                    className=''
                                />
                            </div>
                        </Col>
                        <Col lg={7} md={6} sm={12} className='mt-5'>
                            <p className='about-sub-title'>Technical & operations wing</p>
                            <p className='about-description'>VPM’s technology is simple and efficient. New clients are provided with a specialist so that there is a smooth transition. We ensure our processing system secures your valuable data and provides a smooth transfer of all payroll...
                                <div>
                                    <DetailsPopup
                                        heading="Technical & operations wing"
                                        text="VPM’s technology is simple and efficient. New clients are provided with a specialist so that there is a smooth transition. We ensure our processing system secures your valuable data and provides a smooth transfer of all payroll funds -all while providing a user-friendly experience. Our technical team is available to ensure any inquiry can be answered, and issues can be solved within the shortest possible time."
                                    />
                                </div>
                            </p>
                        </Col>


                        <Col lg={7} md={6} sm={12} className='mt-5'>
                            <p className='about-sub-title'>Your trusted partner in excellence</p>
                            <p className='about-description'>VPM has carved a niche in developing bespoke payroll solutions tailored to each client's unique needs. Our solid reputation in the community is built on delivering value-driven, efficient, and cost-effective payroll services...
                                <div>
                                    <DetailsPopup
                                        heading="Your trusted partner in excellence"
                                        text="VPM has carved a niche in developing bespoke payroll solutions tailored to each client's unique needs. Our solid reputation in the community is built on delivering value-driven, efficient, and cost-effective payroll services. 
                                        What sets us apart is our blend of expertise, flexibility, and innovation, positioning us as the go-to choice for businesses seeking unrivaled payroll management."
                                    />
                                </div>
                            </p>
                        </Col>
                        <Col lg={5} md={6} sm={12} className='mt-5'>
                            <div>
                                <img
                                    src={img3}
                                    alt="Reputable professional service"
                                    className=''
                                />
                            </div>
                        </Col>
                        <Col lg={5} md={6} sm={12} className='mt-5'>

                            <div>
                                <img
                                    src={img4}
                                    alt="Reputable professional service"
                                    className=''
                                />
                            </div>
                        </Col>
                        <Col lg={7} md={6} sm={12} className='mt-5'>
                            <p className='about-sub-title'>At VPM, your success is our priority</p>
                            <p className='about-description'>
                                The VPM team is unwaveringly dedicated to providing payroll solutions that are as unique as your business.  Our commitment to excellence is backed by over two decades of in-depth industry knowledge, ensuring meticulous and...
                                <div>
                                    <DetailsPopup
                                        heading="At VPM, your success is our priority"
                                        text="The VPM team is unwaveringly dedicated to providing payroll solutions that are as unique as your business.  Our commitment to excellence is backed by over two decades of in-depth industry knowledge, ensuring meticulous and precise payroll management. We take pride in our ability to handle the complexities of payroll so that you can focus your energies on what matters most - growing your business and achieving your goals."
                                    />
                                </div>
                            </p>
                        </Col>

                        <Col lg={7} md={6} sm={12} className='mt-5'>
                            <p className='about-sub-title'>Efficiency meets Compliance</p>
                            <p className='about-description'>
                                Imagine payroll processes that are not only compliant and error-free but also efficient and cost-effective. Our quick setup, user-friendly systems, and rapid response times mean you can bid farewell to payroll headaches.
                            </p>
                            <div>
                                <DetailsPopup
                                    heading="Efficiency meets Compliance"
                                    text=" Imagine payroll processes that are not only compliant and error-free but also efficient and cost-effective. Our quick setup, user-friendly systems, and rapid response times mean you can bid farewell to payroll headaches."
                                />
                            </div>
                        </Col>
                        <Col lg={5} md={6} sm={12} className='mt-5'>
                            <div>
                                <img
                                    src={img5}
                                    alt="Reputable professional service"
                                    className=''
                                />
                            </div>
                        </Col>
                        <Col lg={5} md={6} sm={12} className='mt-5'>
                            <div>
                                <img
                                    src={img6}
                                    alt="Reputable professional service"
                                    className=''
                                />
                            </div>
                        </Col>
                        <Col lg={7} md={6} sm={12} className='mt-5 mb-5'>
                            <p className='about-sub-title'>Reclaim time. Gain peace of mind</p>
                            <p className='about-description'>With V-Tac/VPM, you regain invaluable time and peace of mind, allowing you to focus on what you do best – driving your business forward. Let us handle the intricacies of payroll, so you can dedicate your energy to your business's...</p>
                            <div>
                                <DetailsPopup
                                    heading="Reclaim time. Gain peace of mind"
                                    text="With V-Tac/VPM, you regain invaluable time and peace of mind, allowing you to focus on what you do best – driving your business forward. Let us handle the intricacies of payroll, so you can dedicate your energy to your business's growth and your team's well-being."
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

        </>
    );
};

export default ReputableProfessionals;