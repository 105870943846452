import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';


class MountClientFeedback2 extends Component {
    render() {
        return (
            <Fragment>
            <Container fluid={true} className="nexasClientSayCard section-gap p-0">
                <Row>
                    <Col sm={12} md={12} lg={12}>
                        <div className='container'>
                            <p className='nexasCardDesc section-gap'>
                            “I would recommend VPM, and I’ve sent a lot of clients over already. VPM saves companies like ours money, time and headaches. I sometimes see payroll services like an electricity company, in a sense that we pay the bills and we don’t want to worry about it.”
                            </p>
                            <p className='nexasCardName mt-4 mb-5'>Dr. Sam Gupta, Owner</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Fragment>
        );
    }
}

export default MountClientFeedback2;