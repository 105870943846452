import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';

const CommonFaq = () => {
    return (
        <>
            <Container fluid style={{ backgroundColor: "#F8F8F8" }} >
                <Container >
                    <Row >
                        <Col>
                            <h2 className='common-title2 text-center section-gap mb-5 '>Commonly asked questions</h2>
                            <Accordion defaultActiveKey="0" flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className='common-p5'><strong>Can VPM track and report payroll by department?</strong></Accordion.Header>
                                    <Accordion.Body className='common-p'>
                                        Yes. As part of the on-boarding process, we will ensure our systems are set up to reflect how your organization is structured in order to meet your departmental reporting needs.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header className='common-p5'><strong>How does VPM pay a client’s taxes?</strong></Accordion.Header>
                                    <Accordion.Body className='common-p'>
                                        Taxes are submitted to the government electronically, with source deductions (CPP, EI, and Tax – Federal & Provincial including EHT in Ontario) submitted on your behalf.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header className='common-p5'>
                                        <strong>Does VPM stay up-to-date with new payroll rules and legislations?</strong>
                                    </Accordion.Header>
                                    <Accordion.Body className='common-p'>
                                        Yes. We follow all federal and provincial government changes affecting payroll. We add additional value to some client businesses by privately advising when a client might be offside on any legal obligation with their employees as well.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header className='common-p5'>
                                        <strong>Can you direct deposit payroll into our employees accounts?</strong>
                                    </Accordion.Header>
                                    <Accordion.Body className='common-p'>
                                        Yes, we can do that.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header className='common-p5'>
                                        <strong>Do you offer payroll services to individuals or families?</strong>
                                    </Accordion.Header>
                                    <Accordion.Body className='common-p'>
                                        Yes. For example, we can manage payroll for a family that has hired a nanny and does not want to manage their payroll and related reporting requirements.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header className='common-p5'>
                                        <strong>What reports will I get as a new client?</strong>
                                    </Accordion.Header>
                                    <Accordion.Body className='common-p'>
                                        We offer a robust suite of payroll reports for you and your business, such as employee paystubs and direct deposit statements and summaries, timesheet income listing for verification, the current payroll register, GL reporting for any accounting system reporting requirements, and more. Additionally, of course, all government and CRA required reports are sent to you in copy as well for your records.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header className='common-p5'>
                                        <strong>Are you able to process payroll in Quebec?</strong>
                                    </Accordion.Header>
                                    <Accordion.Body className='common-p'>
                                        Yes. Please contact us for more details.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header className='common-p5'>
                                        <strong>Can you provide services on remittance of statutory deductions (Income Tax, CPP, EI, WSIB)?</strong>
                                    </Accordion.Header>
                                    <Accordion.Body className='common-p'>
                                        Yes. We perform all regulatory reporting and CRA remittances for Income Tax, CPP, EI, WSIB, Health Tax, Garnishments, Union Dues, and Statistics Canada submissions.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8">
                                    <Accordion.Header className='common-p5'>
                                        <strong>Can I get a copy of sample payroll reports?</strong>
                                    </Accordion.Header>
                                    <Accordion.Body className='common-p'>
                                        Yes, please contact us and we’ll send you a copy.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9" className='mb-5'>
                                    <Accordion.Header className='common-p5'>
                                        <strong>Can your system help manage HR?</strong>
                                    </Accordion.Header>
                                    <Accordion.Body className='common-p'>
                                        It really depends on the business, but for most cases no. Human resources management is a very large function, and while we track all employee information needed for payroll, this information may or may not satisfy typical HR management business needs.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </Container >
        </>
    );
};

export default CommonFaq;
