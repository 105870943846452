import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { MdChevronRight } from "react-icons/md";
import Rectangle from '../../asset/images/about/About US 002.webp';
import startquotation from '../../asset/images/about/Vector6.png';
import endtquotation from '../../asset/images/about/Vector5.png';
import ball from '../../asset/images/about/vector4.png';
import { Link } from 'react-router-dom';

const CaTrustedPayroll = () => {
    return (
        <>
            <Container fluid className='mt-5'>
                <Container className='p-5' style={{ zIndex: "100" }}>
                    <Row style={{ zIndex: "100" }}>
                        <Col lg={12} md={12} sm={12}>
                            <div>
                                <h2 className='common-title text-center mt-5' style={{ color: "#032B43" }}><span style={{ fontSize: "3.5rem", color: "#D1232A" }}>1000’</span>s of Canadian businesses trust VPM
                                    to transform their payroll processes
                                </h2>
                                <p className='common-p4 text-center mt-4' style={{ color: "#484848" }}>With over 25 years of specialized experience, we offer a white-glove service that's tailored to your unique business needs. We understand the stress and strain payroll inaccuracies can cause and are dedicated to lifting this weight off your shoulders.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row >
                        <Col lg={6} md={6} sm={12} className='mt-5'>
                            <div>
                                <div className='trust-card' >
                                    <div>
                                        <h4 className='common-title3' style={{ color: "#fff" }}>When it comes to payroll, you can’t leave it to chance.</h4>
                                        <p className='common-p' style={{ color: "#fff" }}>
                                            Navigating through ever-changing tax regulations and ensuring accurate, compliant payroll processing is an overwhelming task. Payroll mistakes can lead to financial penalties and damage employee trust, potentially leading to dissatisfaction and high turnover. Such errors can undermine your team's foundation and stifle your business's potential, trapping you in a cycle of administrative chaos and uncertainty.
                                        </p>

                                    </div>
                                </div>
                            </div>

                        </Col>

                        <Col lg={6} md={6} sm={12} className='mt-5'>
                            <div className='trust-card2'>
                                <div>
                                    <h4 className='common-title3' style={{ color: "#fff" }}>Are you stuck in the payroll complexity trap?</h4>
                                    <p className='common-p' style={{ color: "#fff" }}>
                                        In the dynamic business world, payroll management can become a tedious and error-prone chore, taking away your focus and attention from the core functions of your business. The constant worry and mental toll of ensuring every detail is correct can be exhausting, leaving you drained and distracted.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={2} md={4} sm={12}>

                        </Col>
                        <Col lg={8} md={8} sm={12}>
                            <div className='d-flex section-gap'>
                                <div>
                                    <img
                                        src={startquotation}
                                        alt="canadian business"
                                        className='img-fluid'
                                                                           />
                                </div>
                                <div>
                                    <p className='mt-4 ms-2'>Join the ranks of satisfied clients who have turned their payroll process from a pain point into a strategic asset.</p>
                                </div>
                                <div>
                                    <img
                                        src={endtquotation}
                                        alt="canadian business"
                                        className='img-fluid ms-2'
                                    />
                                </div>
                            </div>
                            <div className='mt-5 mb-5 jusrtify-content-center align-item-center text-center'>
                             
                                <Link to="/request-payroll-quote"><Button className='onsultation-button'>Book a free consultation <MdChevronRight size={20} /></Button>{' '}</Link>
                               
                            </div>
                        </Col>
                        <Col lg={2} md={4} sm={12}>

                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
};

export default CaTrustedPayroll;