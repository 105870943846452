import React, { useState } from 'react';
import { Container, Row, Col, Button, Modal, Form, FloatingLabel } from 'react-bootstrap';
import axios from 'axios';
import {Link} from "react-router-dom"
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import img1 from '../../asset/images/about/013.webp';
import img2 from '../../asset/images/about/009.webp';
import img3 from '../../asset/images/about/012.webp';
import img4 from '../../asset/images/about/014.webp';
import img5 from '../../asset/images/about/010.webp';
import img6 from '../../asset/images/about/008.webp';
import img7 from '../../asset/images/about/011.webp';
import img8 from '../../asset/images/about/015.webp';


const DetailsPopup = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <p onClick={handleShow} className='learn-more' style={{ color: "#000" }}>Learn More</p>
            <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Header closeButton style={{ backgroundColor: "#fff" }}>
                    <Modal.Title >{props.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='about-description' style={{ color: "#032B43" }}>{props.text}</p>
                </Modal.Body>
            </Modal>
        </>
    );
}

const MakeingSwitch = () => {
    return (
        <>
            <Container className='mt-5 mb-5'>
                <Row>'
                    <Col lg={12} md={12} sm={12}>
                        <div className='text-center d-flex justify-content-center text-item-center'>
                            <div>
                                <p className='makeing-switch-title m-5'>Making The switch</p>
                                <p className='makeing-switch-subtitle m-5'>No setup fees , no Hassle</p>
                                <Link to="/request-payroll-quote"><Button className='makeing-button mb-5'>Get Free quote </Button></Link>
                            </div>
                            <div className='about-img'>
                                <img
                                    src={img8}
                                    alt="Why choose us"
                                />
                            </div>
                        </div>
                    </Col>

                </Row>
            </Container>
        </>
    )
}

const WhyCooseUs = () => {
    const [email, setEmail] = useState('');

    const subscriptionHandleSubmit = async (e) => {
        e.preventDefault();

        // Check if email is empty
        if (!email) {
            toast.error('Email is required');
            return;
        }

        try {
            const response = await axios.post('https://api.vtacpayroll.ca/subscribe', { email });
            // const response = await axios.post('http://localhost:5000/subscribe', { email });
            if (response.status === 200) {
                toast.success('Subscribed successfully!');
                setEmail('');
            }
        } catch (error) {
            if (error.response.status === 400) {
                toast.error('Email is already subscribed');
            } else {
                toast.error('Failed to subscribe');
            }
        }
    }

    return (
        <>
            <Container>
                <h3 className='common-title text-center section-gap mb-5' style={{ color: "#032B43" }}>Why Client choose us</h3>
                <Row>
                    <Col lg={3} md={6} sm={12}>
                        <div className='why-choose-card'>
                            <p className='common-title3' style={{ color: "#032B43" }}>Quick setup</p>
                            <img
                                src={img1}
                                alt="Why choose us"
                                style={{ height: "auto", width: "40%" }}
                            />
                            <p className='about-description' style={{ color: "#032B43" }}>
                                Account setup will be completed within as little time as 48 hours. Once we have...
                            </p>
                            <div>
                                <DetailsPopup
                                    heading="Quick setup"
                                    text="Account setup will be completed within as little time as 48 hours. Once we have all the required information submitted to us, we can begin the setup process.
                                    This includes the business information, employee information, account information, year-to-date (YTD) data entry and so on.
                                    We’re not picky on format either, you can simply send us the information in whichever format is easiest to provide us. Once the setup is complete, we can begin with the first of many payroll runs!"
                                />
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                        <div className='why-choose-card'>
                            <p className='common-title3'
                                style={{ color: "#032B43" }}>Get help within 30 minutes</p>
                            <img
                                src={img2}
                                alt="Why choose us"
                                style={{ height: "auto", width: "40%" }}
                            />
                            <p className='about-description' style={{ color: "#032B43" }}>
                                Client support is a fundamental part of any service offering...
                            </p>
                            <div>
                                <DetailsPopup
                                    heading="Get help within 30 minutes"
                                    text="Client support is a fundamental part of any service offering. VPM will assist you throughout the entire process.
                                    There will be a dedicated payroll consultant assigned to your account, who will know your account inside and out. They will be the main point of contact and can be reached by phone or e-mail for support.
                                    There won’t be any frustrations trying to get a hold of someone."
                                />
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                        <div className='why-choose-card'>
                            <p className='common-title3' style={{ color: "#032B43" }}>We save you time, less hands-on</p>
                            <img
                                src={img3}
                                alt="Why choose us"
                                style={{ height: "auto", width: "40%" }}
                            />
                            <p className='about-description' style={{ color: "#032B43" }}>
                                The point of outsourcing should be to end up with less work...
                            </p>
                            <div>
                                <DetailsPopup
                                    heading="We save you time, less hands-on"
                                    text="The point of outsourcing should be to end up with less work, which leads to more time for you to spend growing your business.
                                    If we need to go our separate ways, regardless of the length of time, there will be no additional costs incurred in relation to termination of services."
                                />
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                        <div className='why-choose-card'>
                            <p className='common-title3' style={{ color: "#032B43" }}>No contracts needed</p>
                            <img
                                src={img4}
                                alt="Why choose us"
                                style={{ height: "auto", width: "40%" }}
                            />
                            <p className='about-description' style={{ color: "#032B43" }}>
                                There are no contracts in place. This goes hand in hand with our no hidden fees...
                            </p>
                            <div>
                                <DetailsPopup
                                    heading="No contracts needed"
                                    text="There are no contracts in place. This goes hand in hand with our no hidden fees policy. VPM has a nearly perfect client retention rate but we understand there are many situations that may occur.
                                    Meet with one of our skilled representatives to discuss exactly how VPM can meet your needs."
                                />
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                        <div className='why-choose-card'>
                            <p className='common-title3' style={{ color: "#032B43" }}>Personalized white glove service with dedicated support</p>
                            <img
                                src={img5}
                                alt="Why choose us"
                                style={{ height: "auto", width: "40%" }}
                            />
                            <p className='about-description' style={{ color: "#032B43" }}>
                                The VPM team is dedicated to providing personalized, efficient payroll solutions...
                            </p>
                            <div>
                                <DetailsPopup
                                    heading="Personalized white glove service with dedicated support"
                                    text="The VPM team is dedicated to providing personalized, efficient payroll solutions that meet the unique needs of each client. 
                                    Committed to excellence, our team leverages over two decades of expertise to ensure your payroll is handled with precision and care, allowing you to focus on what you do best - growing your business"
                                />
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                        <div className='why-choose-card'>
                            <p className='common-title3' style={{ color: "#032B43" }}>Cost-Effective Solutions</p>
                            <img
                                src={img6}
                                alt="Why choose us"
                                style={{ height: "auto", width: "40%" }}
                            />
                            <p className='about-description' style={{ color: "#032B43" }}>
                                We blend competitive pricing with uncompromised quality. Our services are designed...
                            </p>
                            <div>
                                <DetailsPopup
                                    heading="Cost-Effective Solutions"
                                    text="We blend competitive pricing with uncompromised quality. Our services are designed to deliver superior value, ensuring your investment in payroll management is both economically smart and highly effective."
                                />
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                        <div className='why-choose-card'>
                            <p className='common-title3' style={{ color: "#032B43" }}>Specialized Industry Expertise</p>
                            <img
                                src={img7}
                                alt="Why choose us"
                                style={{ height: "auto", width: "40%" }}
                            />
                            <p className='about-description' style={{ color: "#032B43" }}>
                                Leverage our 23 years of experience across various sectors. Our deep...

                            </p>
                            <div>
                                <DetailsPopup
                                    heading="Specialized Industry Expertise"
                                    text="Leverage our 23 years of experience across various sectors. Our deep understanding of different industry payroll demands means you benefit from specialized, knowledgeable service."
                                />
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                        <div className='why-choose-card'>
                            <p className='common-title3' style={{ color: "#032B43" }}>Subscribe Our news letter</p>
                            <p className='about-description' style={{ color: "#032B43" }}>We value your privacy and will never send irrelevant information</p>
                            <div>
                                <Form onSubmit={subscriptionHandleSubmit}>
                                    <FloatingLabel controlId="floatingInput" label="Email address" className="mb-3">
                                        <Form.Control
                                            type="email"
                                            placeholder="name@example.com"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}

                                        />
                                    </FloatingLabel>

                                    <Button type="submit" className='subscribe-btn'>Subscribe</Button>
                                </Form>
                                <ToastContainer
                                    autoClose={3000}
                                    position="bottom-right"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <MakeingSwitch />
        </>
    );
};

export default WhyCooseUs;