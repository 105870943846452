import React from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import bannerImg from '../../asset/images/T4/001.webp';

import { Link } from 'react-router-dom';
import { MdChevronRight } from "react-icons/md";

const PayrollExpert = () => {
    return (
        <>
            <Container fluid style={{ backgroundColor: "#252E44" }}>
                <Container >
                    <Row >
                        <Col lg={7} md={6} xs={12} className='section-gap' >

                            <h1 className='common-title mt-5' style={{color:"#fff"}}>
                            Want payroll done by an expert?
                            </h1>
                            <p className='common-p4 mb-4'style={{color:"#fff"}}>
                            Whether you are doing your payroll in house or getting done by a payroll service provider, you can try VPM’s customizable payroll solutions. VPM offers flexible and no contract payroll service. You can request a free quote today...
                            </p>
                            <Link to="/request-payroll-quote"><Button className='quote-btn-banner mt-4 mb-4'><span>Free Quote</span> <MdChevronRight /></Button>{' '}</Link>
                        </Col>
                        <Col lg={5} md={6} xs={12}>
                            <img src={bannerImg} className='t4-img  float-end ' alt="payroll-expert" fluid
                            />
                        </Col>
                        {/* <div style={{ marginBottom: "6rem" }}></div> */}
                    </Row>
                </Container>
            </Container>
        </>
    );
};

export default PayrollExpert;